import { createDate, isSameOrAfterToday } from '@lingoda/dates';
import { useUiEvent } from '@lingoda/ui';
import { actionClick } from '@lingoda/analytics';
import { getBookClassEvent, useBooking } from '@lingoda/booking';
import { Layout } from './Layout';
import { type PortalContainers } from './types';
import { ClassDetailsTilePaperStyled } from './components/ClassDetailsTilePaperStyled';
import type { GeneratedClassData } from '../../types';

interface GeneratedClassDetailsTileProps {
    generatedClass: GeneratedClassData;
    onBooked?: (classId: string) => void;
    portalContainers?: PortalContainers;
}

export const GeneratedClassDetailsTile = ({
    generatedClass,
    onBooked,
    portalContainers,
}: GeneratedClassDetailsTileProps) => {
    const addEvent = useUiEvent();
    const [bookDisabled, bookClass] = useBooking();

    const { startDate: startDateRaw, type, lesson, isForNewClassroom } = generatedClass;
    const startDate = createDate(startDateRaw);
    const isClassBookable = !bookDisabled && isSameOrAfterToday(startDate);

    const handleBookClick = () => {
        void addEvent(actionClick('Book class'), getBookClassEvent());

        const generatedClassToBook = {
            startDate,
            type,
            lessonId: lesson.id,
            isForNewClassroom,
            classSource: 'generated',
        } as const;
        bookClass(generatedClassToBook)
            .then(({ classId }) => onBooked?.(classId))
            .catch(() => undefined);
    };

    return (
        <ClassDetailsTilePaperStyled variant="outlined" data-cy="Class details tile">
            <Layout
                classItem={generatedClass}
                isClassBookable={isClassBookable}
                onBookClick={handleBookClick}
                portalContainers={portalContainers}
            />
        </ClassDetailsTilePaperStyled>
    );
};
