import { type PropsWithChildren, type ReactNode } from 'react';
import { Box, ListItem, ListItemIcon, type ListItemProps, ListItemText } from '@lingoda/ui';
import { colorsPalette, styled } from '@lingoda/ui/theme';

export interface SelfLearningItemProps {
    alignItems?: 'start' | 'center';
    icon: ReactNode;
    isDisabled?: boolean;
    secondaryAction?: ListItemProps['secondaryAction'];
    title?: string;
    variant?: 'bordered' | 'listitem';
    backgroundColor?: string;
    onClick?: () => void;
}

type ItemWrapperOwnProps = Pick<SelfLearningItemProps, 'variant'>;

const ItemWrapper = styled(Box, {
    shouldForwardProp: (name: string) => !['variant'].includes(name),
})<ItemWrapperOwnProps>(({ theme, variant, onClick }) => ({
    display: 'flex',
    minHeight: theme.spacing(10.5),
    ...(variant === 'bordered' && {
        border: `1px solid ${theme.palette.border.paper}`,
        borderRadius: theme.shape.borderRadiusPaper,
    }),
    ...(variant === 'listitem' && {
        borderBottom: `1px solid ${theme.palette.border.paper}`,
        '&:last-child': { borderBottom: 'none' },
    }),
    ...(onClick && {
        cursor: 'pointer',
        color: colorsPalette.primaryCustom.dark,
        '&:hover': {
            backgroundColor: colorsPalette.blackAlpha[4],
        },
        '&:active': {
            backgroundColor: colorsPalette.blackAlpha[8],
        },
    }),
}));

export const SelfLearningItem = ({
    children,
    isDisabled = false,
    icon,
    secondaryAction,
    title,
    variant = 'bordered',
    alignItems = 'center',
    backgroundColor,
    onClick,
}: PropsWithChildren<SelfLearningItemProps>) => (
    <ItemWrapper
        variant={variant}
        sx={{ backgroundColor }}
        onClick={!isDisabled ? onClick : undefined}
    >
        <ListItem
            component="div"
            sx={{
                py: 2,
                px: 3.5,
                alignItems,
            }}
            disabled={isDisabled}
            data-cy={title}
        >
            <ListItemIcon
                sx={{
                    color: colorsPalette.blackSolid[100],
                }}
            >
                {icon}
            </ListItemIcon>

            <ListItemText
                primary={title}
                secondary={children}
                primaryTypographyProps={{ variant: 'h4' }}
                secondaryTypographyProps={{ mt: 0.5, variant: 'body1', component: 'div' }}
                sx={{ m: 0, '& button': { textDecoration: 'none', textAlign: 'inherit' } }}
            />
            {secondaryAction && (
                <Box display="inline-flex" ml={2}>
                    {secondaryAction}
                </Box>
            )}
        </ListItem>
    </ItemWrapper>
);
