import { lazy } from 'react';
import { type RouteConfigExtended, logoutPath } from '@lingoda/router';
import {
    RouteAccess,
    completeAccountSetPasswordPath,
    completeAccountWelcomePath,
    resetPasswordPath,
    setPasswordForNewAccountPath,
    socialRegisterConfirmPath,
} from '@lingoda/router';

const ResetPasswordPage = lazy(() => import('./pages/ResetPassword'));
const SetPasswordForNewAccountPage = lazy(() => import('./pages/SetPassword'));
const SocialRegisterConfirmPage = lazy(() => import('./pages/SocialRegisterConfirm'));
const CompleteAccountWelcomePage = lazy(
    () => import('./pages/CompleteAccountSetup/CompleteAccountWelcomePage'),
);
const CompleteAccountSetPasswordPage = lazy(
    () => import('./pages/CompleteAccountSetup/CompleteAccountSetPasswordPage'),
);
const LogoutPage = lazy(() => import('./pages/LogoutPage'));

export const studentAuthRoutes: RouteConfigExtended[] = [
    {
        exact: true,
        component: LogoutPage,
        label: 'Logout',
        path: logoutPath.raw,
        config: {
            navigationElevation: 1,
            access: RouteAccess.All,
            hideNavigation: true,
            hideFooter: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: ResetPasswordPage,
        path: resetPasswordPath.raw,
        label: 'ResetPassword',
        config: {
            navigationElevation: 1,
            access: RouteAccess.Public,
            hideNavigation: true,
            hideFooter: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: SetPasswordForNewAccountPage,
        path: setPasswordForNewAccountPath.raw,
        label: 'SetPasswordForNewAccount',
        config: {
            navigationElevation: 1,
            access: RouteAccess.Public,
            hideNavigation: true,
            hideFooter: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: SocialRegisterConfirmPage,
        path: socialRegisterConfirmPath.raw,
        label: 'SocialRegisterConfirmPage',
        config: {
            navigationElevation: 1,
            access: RouteAccess.Public,
            hideNavigation: true,
            hideFooter: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: CompleteAccountWelcomePage,
        path: completeAccountWelcomePath.raw,
        label: 'CompleteAccountWelcomePage',
        config: {
            navigationElevation: 1,
            disableLogoLink: true,
            hideNavigation: true,
            hideFooter: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: CompleteAccountSetPasswordPage,
        path: completeAccountSetPasswordPath.raw,
        label: 'CompleteAccountSetPasswordPage',
        config: {
            navigationElevation: 1,
            disableLogoLink: true,
            hideNavigation: true,
            hideFooter: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
];
