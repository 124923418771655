import { useEffect } from 'react';
import { FormattedMessage, trans } from '@lingoda/i18n';
import { UserDisplayedWidgetClassEnum } from '@lingoda/graphql';
import { Alert, Chip, Link, Stack, Typography, colorsPalette } from '@lingoda/ui';
import { getNewClassroomStudentPromoInfoUrl } from '../helpers';
import { useMarkWidgetAsDisplayed } from '../hooks';

interface Props {
    markAsDisplayedOnMount?: boolean;
}

export const ClassesInClassroomAlert = ({
    children,
    markAsDisplayedOnMount,
}: React.PropsWithChildren<Props>) => {
    const { markAsDisplayed } = useMarkWidgetAsDisplayed();

    useEffect(() => {
        if (markAsDisplayedOnMount) {
            markAsDisplayed(UserDisplayedWidgetClassEnum.StudentFutureClassInClassroomBanner);
        }
    }, [markAsDisplayed, markAsDisplayedOnMount]);

    return (
        <Alert variant="outlined" severity="promotion" size="compact" icon={false} fullWidth>
            <Stack spacing={1} alignItems="flex-start">
                <Stack direction="row" alignItems="center" columnGap={0.5}>
                    <Typography variant="subtitle1" htmlColor={colorsPalette.blackSolid[100]}>
                        {trans('join-classes-in-browser', {}, 'student-common')}
                    </Typography>
                    <Chip label="BETA" color="promotion" />
                </Stack>
                <Typography variant="body2" htmlColor={colorsPalette.blackSolid[100]}>
                    <FormattedMessage
                        message={trans('classes-in-lingoda-classroom-alert', {}, 'student-common')}
                        values={{
                            a: (chunks) => (
                                <Link
                                    href={getNewClassroomStudentPromoInfoUrl()}
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={{ color: colorsPalette.purpleSolid[100] }}
                                >
                                    {chunks}
                                </Link>
                            ),
                        }}
                    />
                </Typography>
                {children}
            </Stack>
        </Alert>
    );
};
