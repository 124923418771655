import { useCallback } from 'react';
import { useDispatch } from '@lingoda/hooks';
import { clearAuthCookies, logout as logoutAction } from '@lingoda/auth';
import { useGQLClient } from '@lingoda/graphql';
import { cleanUpPostFFreeTrialDialogCloseDate } from '@lingoda/f-free-trial-utils';

export const useLogout = () => {
    const dispatch = useDispatch();
    const gqlClient = useGQLClient();

    return useCallback(async () => {
        cleanUpOnLogout();
        await gqlClient.clearStore();
        dispatch(logoutAction());
        await clearAuthCookies();
    }, [dispatch, gqlClient]);
};

export const cleanUpOnLogout = () => {
    cleanUpPostFFreeTrialDialogCloseDate();
};
