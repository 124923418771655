import { Paper, Stack, Typography, colorsPalette, styled } from '@lingoda/ui';
import { trans, transChoice } from '@lingoda/i18n';
import { ChallengeProgressBar } from '../ChallengeProgressBar';
import { type Prize } from '../../types';
import { getMaxPrizeValue } from '../../utils';

interface Props {
    challengeName: string;
    prizes: ReadonlyArray<Prize>;
    completedActivities: number;
}

export const JoinedChallengeBlock = ({ challengeName, prizes, completedActivities }: Props) => {
    const maxPrizeValue = getMaxPrizeValue(prizes);

    return (
        <ContainerPaper variant="outlined">
            <Stack
                direction="row"
                spacing={4}
                justifyContent="space-between"
                alignItems="center"
                mb={1}
            >
                <Typography variant="subtitle2">
                    {transChoice(
                        'complete-learning-activities',
                        maxPrizeValue,
                        { activities: maxPrizeValue },
                        'student-challenges',
                    )}
                </Typography>
                <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2" color="primary">
                        {completedActivities}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        color="primary"
                        fontWeight={400}
                        htmlColor={colorsPalette.blackAlpha[60]}
                        whiteSpace="nowrap"
                    >
                        / {maxPrizeValue}{' '}
                        {trans('learning-activities-abbreviation', {}, 'student-challenges')}
                    </Typography>
                </Stack>
            </Stack>
            <ChallengeProgressBar
                size={'medium'}
                challengeName={challengeName}
                prizes={prizes}
                completedActivities={completedActivities}
            />
        </ContainerPaper>
    );
};

const ContainerPaper = styled(Paper)(({ theme }) => ({
    background: 'transparent',
    padding: theme.spacing(2),
}));
