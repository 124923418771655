import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const ArrowRightIcon16 = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 16 16">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.75 8a.75.75 0 0 1 .75-.75h8.19L7.97 3.53a.75.75 0 0 1 1.06-1.06L14.56 8l-5.53 5.53a.75.75 0 0 1-1.06-1.06l3.72-3.72H3.5A.75.75 0 0 1 2.75 8Z"
        />
    </SvgIcon>
);

export const ArrowRightIcon24 = (props: SvgIconProps) => (
    <SvgIcon width="24" height="24" fill="none" {...props}>
        <path
            fill="#currentColor"
            fillRule="evenodd"
            d="M3.94 12c0 .414.335.75.75.75h12.189l-5.72 5.72a.75.75 0 1 0 1.06 1.06L19.75 12l-7.53-7.53a.75.75 0 0 0-1.061 1.06l5.72 5.72H4.689a.75.75 0 0 0-.75.75Z"
            clipRule="evenodd"
        />
    </SvgIcon>
);
