import cx from 'clsx';
import { memo } from 'react';
import { Tooltip } from '../../layout/Tooltip';
import { Box } from '../../layout/Box';
import { DAY_DISABLED, DAY_NO_SCHEDULED_CLASS, DAY_SCHEDULED_CLASS, DAY_SELECTED } from './styles';

interface Props {
    date: Date;
    isSelected: boolean;
    isScheduled: boolean;
    tooltip?: string;
    tooltipOnClick?: boolean;
    disabled?: boolean;
}

const DayCellComponent = ({
    date,
    isSelected,
    isScheduled,
    tooltip,
    tooltipOnClick,
    disabled,
}: Props) => {
    const dayClasses = cx({
        [DAY_SELECTED]: isSelected,
        [DAY_SCHEDULED_CLASS]: isScheduled,
        [DAY_NO_SCHEDULED_CLASS]: !isScheduled,
        [DAY_DISABLED]: disabled,
    });

    const tooltipOnClickProps = tooltipOnClick
        ? {
              open: tooltipOnClick,
              disableFocusListener: true,
              disableHoverListener: true,
              disableTouchListener: true,
          }
        : {};

    return (
        <Box className={dayClasses}>
            {tooltip ? (
                <Tooltip title={tooltip} placement="top" arrow {...tooltipOnClickProps}>
                    <>{date.getDate()}</>
                </Tooltip>
            ) : (
                date.getDate()
            )}
        </Box>
    );
};

export const DayCell = memo(DayCellComponent);
