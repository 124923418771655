import { useMemo } from 'react';
import { getQueryParams, queryParamsDecoder } from '@lingoda/urls';
import { type SectionName, transformSectionName } from '@lingoda/sections';
import { useLocation } from '@lingoda/router';

interface RawParams {
    section: string;
    curriculumId: number;
    module: number;
    timezone: string;
    timeFormat: string;
    weekStart: number;
}

interface DefaultParams extends Partial<Omit<RawParams, 'section' | 'timeFormat'>> {
    section?: SectionName;
    timeFormat: string;
}

export const useRegisterQueryParams = (defaults: DefaultParams) => {
    const location = useLocation();

    const data = useMemo(() => {
        // User data may be passed as top-level query params
        // or can be nested in 'state' query param (used in SSO flow with social providers)
        const { state: rawState, ...registerParams } = getQueryParams(location.search, {
            decoder: queryParamsDecoder,
        }) as Partial<RawParams & { state: Partial<RawParams> | string }>;

        // string state is a temporary solution until backend library (hwi/oauth-bundle) support associative arrays
        // @TODO remove after https://lingoda.atlassian.net/browse/LW-27267 is done
        // remove also in workspaces/packages/api/src/social.ts
        const state =
            typeof rawState === 'string' ? (JSON.parse(rawState) as Partial<RawParams>) : rawState;

        const { section, curriculumId, module, timezone, timeFormat, weekStart } =
            state || registerParams;

        return {
            section: section ? transformSectionName(section) : defaults.section,
            curriculumId: curriculumId ?? defaults.curriculumId,
            module: module ?? defaults.module,
            timezone: timezone ?? defaults.timezone,
            timeFormat: timeFormat ?? defaults.timeFormat,
            weekStart: weekStart ?? defaults.weekStart,
        };
    }, [
        location.search,
        defaults.section,
        defaults.curriculumId,
        defaults.module,
        defaults.timezone,
        defaults.timeFormat,
        defaults.weekStart,
    ]);

    return data;
};
