import uniqueId from 'lodash/uniqueId';
import { type PropsWithChildren, useMemo } from 'react';
import { trans } from '@lingoda/i18n';
import { Button } from '../../../buttons';
import { Box, type BoxProps } from '../../../layout/Box';
import { Stack } from '../../../layout/Stack';
import { Typography, type TypographyProps } from '../../../layout/Typography';
import { colorsPalette } from '../../../theme';
import { type Props as BaseDialogProps, Dialog } from '../Dialog';
import { DialogHeader } from '../DialogHeader';

export type ButtonVariant = 'contained' | 'text';

export interface Props extends BaseDialogProps {
    title?: string;
    footer?: string;
    buttonLabel?: string;
    buttonVariant?: ButtonVariant;
    buttonAlign?: BoxProps['justifyContent'];
    isButtonVisible?: boolean;
    isDisabled?: boolean;
    blurredBackground?: boolean;
    titleAlign?: TypographyProps['textAlign'];
    titleVariant?: TypographyProps['variant'];
}

const DialogInfo = ({
    children,
    title,
    footer,
    isOpen = false,
    buttonLabel = trans('btn-close', {}, 'public-common'),
    buttonVariant = 'contained',
    buttonAlign = 'flex-end',
    isButtonVisible = true,
    onClose,
    isDisabled,
    maxWidth,
    blurredBackground,
    titleAlign,
    titleVariant = 'h2',
}: Props) => {
    const a11y = useMemo(() => generateA11y(), []);

    return (
        <Dialog
            onClose={onClose}
            isOpen={isOpen}
            maxWidth={maxWidth}
            aria-labelledby={title ? a11y.titleId : undefined}
            blurredBackground={blurredBackground}
        >
            <Box padding={{ xs: 3, sm: 4 }} paddingBottom={footer && { sm: 3 }}>
                <Stack spacing={3}>
                    <DialogHeader onClose={onClose}>
                        {title && (
                            <Typography textAlign={titleAlign} variant={titleVariant}>
                                {title}
                            </Typography>
                        )}
                    </DialogHeader>

                    <Box>{children}</Box>

                    {isButtonVisible && (
                        <ButtonLayout align={buttonAlign}>
                            <Button
                                variant={buttonVariant}
                                color="primary"
                                fullWidth
                                disabled={isDisabled}
                                onClick={onClose}
                            >
                                {buttonLabel}
                            </Button>
                        </ButtonLayout>
                    )}
                </Stack>
            </Box>

            {footer && (
                <Box paddingY={3} paddingX={{ xs: 3, sm: 4 }} bgcolor={colorsPalette.blackAlpha[4]}>
                    <Typography variant="body2">{footer}</Typography>
                </Box>
            )}
        </Dialog>
    );
};

interface ButtonGridProps {
    align: BoxProps['justifyContent'];
}

const ButtonLayout = ({ align, children }: PropsWithChildren<ButtonGridProps>) => {
    return (
        <Box display="flex" justifyContent={align}>
            <Box width={{ xs: '100%', sm: align === 'stretch' ? '100%' : 'auto' }}>{children}</Box>
        </Box>
    );
};

const generateA11y = () => {
    return {
        titleId: uniqueId('dialog-title-'),
    };
};

export default DialogInfo;
