import store from 'store';
import { createRoot } from 'react-dom/client';
import { createElement } from 'react';
import { configureAnalytics, initGtm, initVitals, trackUrl } from '@lingoda/analytics';
import { init as authInit, fetchMe } from '@lingoda/auth';
import { initRequestWebProvider } from '@lingoda/http';
import { startClock } from '@lingoda/clock';
import { initErrorTracker } from '@lingoda/monitor';
import { currentRouteLabel, goTo, history, logoutPath } from '@lingoda/router';
import { initBraze } from '@lingoda/braze-sdk';
import { assert } from '@lingoda/utils';
import Root from './root';
import routes from './routes';

initErrorTracker(history);

authInit();
startClock();
initVitals();

initRequestWebProvider({
    onUnauthorized: () => goTo(logoutPath()),
});

window.addEventListener('load', () => {
    initGtm();
    initBraze();

    trackUrl(history.location.pathname, currentRouteLabel(routes));

    history.listen(({ pathname }) => {
        trackUrl(pathname, currentRouteLabel(routes));
    });
});

void configureAnalytics();

store.dispatch(fetchMe());

const container = document.getElementById('root');
assert(container, 'React mount element not found');
const root = createRoot(container);
root.render(createElement(Root, { history }));
