import { Img } from '@lingoda/ui';
import { lingobitesIllustration, lingobitesIllustrationGold } from '@lingoda/ui/illustrations';
import type { ImgProps } from '@lingoda/ui/layout/Img';

export interface LingobitesIconProps extends ImgProps {
    score?: number;
    disabled?: boolean;
}

export const LingobitesIcon = ({ score, disabled, ...props }: LingobitesIconProps) => (
    <Img
        src={score === 100 ? lingobitesIllustrationGold : lingobitesIllustration}
        sx={{ opacity: disabled ? 0.3 : 1, width: props.width, height: props.height }}
        {...props}
    />
);
