import { trans } from '@lingoda/i18n';
import { type ClassType } from '@lingoda/graphql';
import { ToggleButton, ToggleButtonGroup } from '@lingoda/ui';
import { capitalize } from '@lingoda/utils';
import { FilterGroup } from './FilterGroup';
import type { ToggleButtonGroupProps } from '@mui/material';
import type { FilterProps } from '../../types';

interface ClassFilterProps extends FilterProps, ToggleButtonGroupProps {
    options: ClassType[];
    classType: ClassType;
}

export const ClassTypeFilter = ({
    classType,
    options,
    fullWidth,
    onFilterUpdate,
}: ClassFilterProps) => {
    const handleChange: ToggleButtonGroupProps['onChange'] = (_, value: ClassType) => {
        onFilterUpdate('classType', value);
    };

    return (
        <FilterGroup id="classType-filter" label={trans('class-type', {}, 'student-store')}>
            <ToggleButtonGroup
                value={classType}
                onChange={handleChange}
                exclusive
                fullWidth={fullWidth}
            >
                {options.map((option) => (
                    <ToggleButton key={option} value={option}>
                        {capitalize(trans(/*@ignore*/ `class-type-${option}`, {}, 'student-store'))}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </FilterGroup>
    );
};
