import Bowser from 'bowser';

const userAgent = Bowser.parse(window.navigator.userAgent);

const isIpad =
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        navigator.platform,
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

interface PlatformInfo {
    browserName?: string;
    browserVersion?: string;
    engineName?: string;
    engineVersion?: string;
    osName?: string;
    osVersion?: string;
    platformType?: string;
    platformVendor?: string;
    platformModel?: string;
}

const getPlatformInfo = (): PlatformInfo => {
    return {
        browserName: userAgent.browser.name,
        browserVersion: userAgent.browser.version,
        engineName: userAgent.engine.name,
        engineVersion: userAgent.engine.version,
        osName: userAgent.os.name,
        osVersion: userAgent.os.version,
        platformType: getPlatformType(userAgent.platform.type),
        platformVendor: userAgent.platform.vendor,
        platformModel: userAgent.platform.model,
    };
};

const getPlatformType = (parsedPlatformType: string | undefined) => {
    if (parsedPlatformType === 'desktop' && isIpad) {
        return Bowser.PLATFORMS_MAP.tablet;
    }

    return parsedPlatformType;
};

export const platformInfo = getPlatformInfo();

// Platform:
export const isMobileDevice = platformInfo.platformType === 'mobile';
export const isDesktopDevice = platformInfo.platformType === 'desktop';

// Browser:
export const isSafari = platformInfo.browserName === 'Safari';
export const isChrome = platformInfo.browserName === 'Chrome';
export const isEdge = platformInfo.browserName === 'Microsoft Edge';
export const isFirefox = platformInfo.browserName === 'Firefox';

// Operation system:
export const isIOS = platformInfo.osName === 'iOS';
export const isAndroid = platformInfo.osName === 'Android';

// Performance
export const maxHardwareConcurrency = navigator.hardwareConcurrency;
