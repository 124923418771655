import { useToggleState } from '@lingoda/hooks';
import { Translate, trans } from '@lingoda/i18n';
import { RevocationDialog, TermsDialog } from '@lingoda/legal';
import { Link, Typography } from '@lingoda/ui';

interface Props {
    showRevocationLink?: boolean;
}

export const TermsLink = ({ showRevocationLink = true }: Props) => {
    const [isTermsOpen, handleOpenTerms, handleCloseTerms] = useToggleState(false);
    const [isRevocationOpen, handleOpenRevocation, handleCloseRevocation] = useToggleState(false);

    const termsLink = (
        <Link onClick={handleOpenTerms}>
            {trans('form-label-terms-and-conditions.terms', {}, 'public-common')}
        </Link>
    );

    const revocationLink = (
        <Link onClick={handleOpenRevocation}>
            {trans('form-label-terms-and-conditions.revocation', {}, 'public-common')}
        </Link>
    );

    return (
        <>
            <Typography variant="body2">
                {showRevocationLink ? (
                    <Translate
                        id="form-label-terms-and-conditions"
                        domain="public-common"
                        params={{
                            terms: termsLink,
                            revocation: revocationLink,
                        }}
                    />
                ) : (
                    <Translate
                        id="form-label-terms-and-conditions-no-revocation"
                        domain="public-common"
                        params={{ terms: termsLink }}
                    />
                )}
            </Typography>
            <TermsDialog isOpen={isTermsOpen} onClose={handleCloseTerms} />
            <RevocationDialog isOpen={isRevocationOpen} onClose={handleCloseRevocation} />
        </>
    );
};
