import { useEffect } from 'react';
import {
    GetStartedStep,
    completeAccountSetPasswordPath,
    completeAccountWelcomePath,
    freeTrialPurchasePath,
    getStartedPath,
    getStartedStepNamePath,
    goTo,
    matchPathMemoized,
    onboardingPath,
    packagePurchasePath,
    postCheckoutPath,
    studentM2MCheckoutPath,
    studentPaymentFailedPath,
    studentPaymentPendingPath,
    studentSprintCheckoutPath,
    useLocation,
    useRouteMatcher,
} from '@lingoda/router';
import { isBookBeforeTrialEnabled } from '@lingoda/feature-flags';
import { useSelector } from '@lingoda/hooks';
import { currentSubscriptionSelector } from '@lingoda/subscriptions';
import { studentOnboardedSelector } from '@lingoda/students';
import { userSelector } from '@lingoda/auth';
import { useIsFFreeTrial } from '@lingoda/f-free-trial';

/**
 * This hook is used to redirect users to the onboarding process if they are not yet onboarded.
 * For guest users, it redirects them to complete the account setup instead.
 */
const checkoutRoutes = [
    { path: packagePurchasePath(undefined as never), matchPrefix: true },
    { path: freeTrialPurchasePath(undefined as never), matchPrefix: true },
    { path: studentM2MCheckoutPath(undefined as never), matchPrefix: true },
    { path: studentPaymentPendingPath(undefined as never), matchPrefix: true },
    { path: studentPaymentFailedPath(undefined as never), matchPrefix: true },
    { path: studentSprintCheckoutPath(undefined as never), matchPrefix: true },
];

const completeAccountRoutes = [
    { path: completeAccountWelcomePath(undefined as never), matchPrefix: true },
    { path: completeAccountSetPasswordPath(undefined as never), matchPrefix: true },
];

export const useEnsureOnboardedAndSetUp = () => {
    const { pathname } = useLocation();
    const isFFreeTrial = useIsFFreeTrial();
    const subscription = useSelector(currentSubscriptionSelector);
    const isOnboarded = useSelector(studentOnboardedSelector);
    const user = useSelector(userSelector);
    const isGuestUser = user?.isGuest;

    const isOnboardingPath = useRouteMatcher([{ path: onboardingPath(), matchPrefix: true }]);
    const isGetStartedPath = useRouteMatcher([{ path: getStartedPath(), matchPrefix: true }]);
    const isCheckoutPath = matchPathMemoized(checkoutRoutes, pathname);
    const isCompleteAccountPath = matchPathMemoized(completeAccountRoutes, pathname);
    const isPostCheckoutPath = useRouteMatcher([{ path: postCheckoutPath(), matchPrefix: true }]);
    const isUserEligibleForPostCheckout = subscription?.isRecurring || isFFreeTrial;

    useEffect(() => {
        if (
            isOnboardingPath ||
            isPostCheckoutPath ||
            isGetStartedPath ||
            isCheckoutPath ||
            isCompleteAccountPath
        ) {
            return;
        }

        if (isGuestUser) {
            goTo(completeAccountWelcomePath());

            return;
        }

        if (!subscription || subscription.isPaymentNeeded || isOnboarded) {
            return;
        }

        if (isBookBeforeTrialEnabled()) {
            goTo(getStartedStepNamePath({ stepName: GetStartedStep.Congrats }));

            return;
        }

        if (isUserEligibleForPostCheckout) {
            goTo(postCheckoutPath());
        } else {
            goTo(onboardingPath());
        }
    }, [
        isGetStartedPath,
        isOnboarded,
        isOnboardingPath,
        isPostCheckoutPath,
        subscription,
        isGuestUser,
        isCompleteAccountPath,
        isUserEligibleForPostCheckout,
        isCheckoutPath,
    ]);
};
