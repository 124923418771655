import { type AcceptedLang, LANG_DEFAULT, getLang } from '@lingoda/env';

export const getNewClassroomStudentPromoInfoUrl = () => {
    let locale = getLang();
    const supportedPageLocales: AcceptedLang[] = ['en', 'fr', 'es'];
    if (!supportedPageLocales.includes(locale)) {
        locale = LANG_DEFAULT; // TODO: remove when page available in DE and RU https://lingoda.atlassian.net/browse/LW-29203
    }

    return `https://lingoda-students.elevio.help/${locale}/categories/119-lingoda-classroom`;
};
