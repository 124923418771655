import { useCallback } from 'react';
import { useTrackingActionEnhancer } from '@lingoda/analytics';
import { useRequestTracker } from '@lingoda/core';
import { DialogComponent, showDialog } from '@lingoda/dialogs';
import { useIsPaymentMethodRequired } from '@lingoda/f-free-trial';
import { useCacheCleaner } from '@lingoda/graphql';
import { useDispatch, useToggleState } from '@lingoda/hooks';
import { getMessages } from '@lingoda/http';
import { fetchLearningUnits } from '@lingoda/learning-units';
import { showToast, showToasts } from '@lingoda/toasts';
import { isFormError } from '@lingoda/utils';
import { type BookClassActionParams, bookClass as bookClassAction } from '../../actions';
import { type BookClassActionResult } from '../../side-effects';

interface UseBookingOptions {
    skipNotifications?: boolean;
}

type BookClassParams = BookClassActionParams & {
    moduleId?: number;
};

export const useBooking = (options: UseBookingOptions = {}) => {
    const { skipNotifications } = options || {};
    const trackRequest = useRequestTracker();
    const addTrackingContext = useTrackingActionEnhancer();
    const dispatch = useDispatch();
    const [booking, startBooking, stopBooking] = useToggleState(false);
    const [clearCache] = useCacheCleaner();
    const isPaymentMethodRequired = useIsPaymentMethodRequired();

    const bookClass = useCallback(
        (bookClassParams: BookClassParams) => {
            if (isPaymentMethodRequired) {
                dispatch(showDialog(DialogComponent.AddPaymentMethod, { bookClassParams }));

                return Promise.reject();
            }

            startBooking();

            return trackRequest(dispatch(addTrackingContext(bookClassAction(bookClassParams))))
                .then((bookClassResult) => {
                    if (bookClassParams.moduleId) {
                        dispatch(fetchLearningUnits([bookClassParams.moduleId]));
                        clearCache({
                            id: 'ROOT_QUERY',
                            fieldName: 'learningUnits',
                            triggerGC: true,
                        });
                    }

                    return bookClassResult as BookClassActionResult;
                })
                .catch((error) => {
                    if (!skipNotifications) {
                        if (isFormError(error)) {
                            showToasts(getMessages(error.messages));
                        } else if (error instanceof Error) {
                            showToast('error', error.message);
                        }
                    }

                    throw error;
                })
                .finally(stopBooking);
        },
        [
            addTrackingContext,
            dispatch,
            startBooking,
            stopBooking,
            trackRequest,
            clearCache,
            skipNotifications,
            isPaymentMethodRequired,
        ],
    );

    return [booking, bookClass] as const;
};
