import { type ChecklistItem } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';
import { Paper, Stack, Typography, colorsPalette, styled } from '@lingoda/ui';
import { checklistActionsTranslations } from '../translations';
import { ActionButton } from './ActionButton';

export const ChecklistStepAction = ({ checklist }: { checklist: readonly ChecklistItem[] }) => {
    const slug = checklist.find((item) => !item.isCompleted)?.slug ?? checklist[0].slug;
    const checklistStepTranslations = checklistActionsTranslations[slug];

    return (
        <PaperStyled>
            <Typography variant="body3">{trans('next-up', {}, 'student-onboarding')}</Typography>
            <StackStyled>
                <Stack>
                    <Typography variant="subtitle1">{checklistStepTranslations?.title}</Typography>
                    <Typography variant="body2">
                        {checklistStepTranslations?.description}
                    </Typography>
                </Stack>
                <ActionButton slug={slug} />
            </StackStyled>
        </PaperStyled>
    );
};

const PaperStyled = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    opacity: 1,
    backgroundColor: colorsPalette.primarySolid[4],
    border: '1px solid',
    borderColor: colorsPalette.blackSolid[8],
    borderRadius: theme.spacing(1),
}));

const StackStyled = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'end',
    marginTop: theme.spacing(1),
    gap: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'stretch',
    },
}));
