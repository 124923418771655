import type { Messages } from './types';

export interface ErrorResponse {
    messages: Messages;
}

export const getMessages = (messages: Messages | undefined) =>
    messages
        ? Object.keys(messages)
              .filter((id) => id !== '')
              .map((id) => ({
                  variant: messages[id][0].type,
                  message: messages[id][0].message,
              }))
        : [];

export const hasApiErrorMessages = <T extends object>(
    data: T,
): data is T & { messages: Messages } => Boolean((data as T & { messages?: Messages }).messages);

export const findMessageByText = (messages: Messages, text: string) => {
    return getMessages(messages).find((item) => item.message.includes(text));
};
