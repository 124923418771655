import { useCallback, useEffect } from 'react';
import { Form, type FormHelper, Formik } from '@lingoda/forms';
import { useUiEvent } from '@lingoda/ui';
import { ClassType } from '@lingoda/graphql';
import { actionClick } from '@lingoda/analytics';
import { add, addMonths, createDate, endOfDay, isBefore, startOfHour } from '@lingoda/dates';
import { useDispatch, useSelector } from '@lingoda/hooks';
import { goTo, studentClassDetailsPath } from '@lingoda/router';
import { cancelledExpiresAtSelector } from '@lingoda/auth';
import { studentTimeFormatPreferenceSelector } from '@lingoda/students';
import {
    getBookClassEvent,
    getLeadTime,
    minIndividualBookingDateSelector,
    useBooking,
} from '@lingoda/booking';
import { buildFormErrors, isFormError } from '@lingoda/utils';
import { useShowCalendarConnectionDialog } from '@lingoda/calendar-connection/hooks';
import { type FormValues, validate } from './formSchema';
import { IndividualClassBookingFormLayout } from './IndividualClassBookingFormLayout';

interface IndividualClassBookingFormProps {
    lessonId: number;
    moduleId: number;
}

export const IndividualClassBookingForm = ({
    lessonId,
    moduleId,
}: IndividualClassBookingFormProps) => {
    const addEvent = useUiEvent();
    const dispatch = useDispatch();
    const minDate = useSelector(minIndividualBookingDateSelector);
    const cancelledExpiresAt = useSelector(cancelledExpiresAtSelector);
    const { showCalendarConnectDialog, shouldShowCalendarConnectDialog } =
        useShowCalendarConnectionDialog();
    const in3MonthDate = addMonths(endOfDay(createDate()), 3);
    const maxDate =
        cancelledExpiresAt && isBefore(cancelledExpiresAt, in3MonthDate)
            ? cancelledExpiresAt
            : in3MonthDate;
    const timeFormat = useSelector(studentTimeFormatPreferenceSelector);
    const [bookDisabled, bookClass] = useBooking({ skipNotifications: true });
    const defaultMinDate = startOfHour(add({ days: 2, hours: 1 }, createDate()));

    const loading = !minDate;
    const noDatesAvailable = minDate && maxDate && isBefore(maxDate, minDate);
    const submitDisabled = bookDisabled || noDatesAvailable;

    const handleFormSubmit = useCallback(
        (values: FormValues, helpers: FormHelper<FormValues>) => {
            const requestedClassToBook = {
                ...values,
                type: ClassType.Individual,
                lessonId,
                moduleId,
                isRequestedByUser: true as const,
                classSource: 'requested',
            } as const;

            void addEvent(actionClick('Book class'), getBookClassEvent());
            bookClass(requestedClassToBook)
                .then(({ classId }) => {
                    if (shouldShowCalendarConnectDialog) {
                        showCalendarConnectDialog();
                    }
                    goTo(studentClassDetailsPath({ class_id: classId }), { replace: true });
                })
                .catch((error) => {
                    if (isFormError(error)) {
                        helpers.setErrors(buildFormErrors(error.messages));
                    }
                });
        },
        [
            addEvent,
            bookClass,
            lessonId,
            moduleId,
            shouldShowCalendarConnectDialog,
            showCalendarConnectDialog,
        ],
    );

    useEffect(() => {
        dispatch(getLeadTime());
    }, [dispatch]);

    return (
        <Formik
            initialValues={{ startDate: startOfHour(minDate || defaultMinDate) }}
            onSubmit={handleFormSubmit}
            validate={validate}
        >
            <Form noValidate>
                <IndividualClassBookingFormLayout
                    minDate={minDate || defaultMinDate}
                    maxDate={maxDate}
                    timeFormat={timeFormat}
                    disabled={bookDisabled}
                    submitDisabled={submitDisabled}
                    loading={loading}
                />
            </Form>
        </Formik>
    );
};
