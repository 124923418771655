import { createContext } from 'react';
import { LoadState, PlayState, type UseAudioReturn } from '../../hooks/useAudio';

export type AudioContext = UseAudioReturn;

const defaultValue: UseAudioReturn = {
    play: () => Promise.reject('No `AudioContext` found'),
    pause: () => null,
    stop: () => null,
    preload: () => null,
    checkIsPlaying: () => false,
    playState: PlayState.Idle,
    loadState: LoadState.Idle,
};

export const AudioContext = createContext<UseAudioReturn>(defaultValue);

export { LoadState, PlayState };

export const fakeAudioContext = (overrides: Partial<UseAudioReturn>) => {
    return { ...defaultValue, ...overrides };
};
