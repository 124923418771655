import { useMemo } from 'react';
import {
    ContentBlockTypography,
    findAllContentBlocks,
    findContentBlock,
} from '@lingoda/content-blocks';
import { Location } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';
import { Box, DialogConfirm, Stack } from '@lingoda/ui';
import { colorsPalette } from '@lingoda/ui/theme';
import { type PackageActionProps, type PurchasePackage } from '../types';
import { TrialDetails } from './TrialDetails';

interface MobileConfirmDialogProps extends PackageActionProps {
    isOpen?: boolean;
    pkg: PurchasePackage;
    isTrialAvailable?: boolean;
    onClose: () => void;
}

export const MobileConfirmDialog = ({
    isOpen,
    pkg,
    isTrialAvailable,
    onCheckout,
    onClose,
}: MobileConfirmDialogProps) => {
    const contentBlocks = useMemo(
        () => ({
            title: findContentBlock(pkg.contentBlocks, Location.SummaryTitle),
            content: findAllContentBlocks(pkg.contentBlocks, Location.SummaryContent),
            trialDetails: findContentBlock(pkg.contentBlocks, Location.SummaryTrialDetails),
        }),
        [pkg.contentBlocks],
    );

    const purchaseSpecificDialogProps = isTrialAvailable
        ? ({
              confirmLabel: trans('proceed-to-checkout-with-trial', {}, 'student-store'),
              dismissLabel: trans('proceed-to-checkout-skipping-trial', {}, 'student-store'),
              dismissVariant: 'outlined',
              onConfirm: () => onCheckout({ trial: true }),
              onDismiss: () => onCheckout({ trial: false }),
          } as const)
        : ({
              confirmLabel: trans('proceed-to-checkout', {}, 'student-store'),
              onConfirm: () => onCheckout({ trial: false }),
              onDismiss: onClose,
          } as const);

    return (
        <DialogConfirm
            isOpen={isOpen}
            confirmVariant="gradient"
            {...purchaseSpecificDialogProps}
            onClose={onClose}
            title={contentBlocks.title?.text}
        >
            <Stack spacing={3}>
                {contentBlocks.content && (
                    <Stack spacing={2}>
                        {contentBlocks.content.map((item, index) => (
                            <ContentBlockTypography
                                key={index}
                                {...item}
                                variant="body1"
                                textColor={colorsPalette.blackSolid[100]}
                                iconColor={colorsPalette.primarySolid[100]}
                            />
                        ))}
                    </Stack>
                )}

                {isTrialAvailable && contentBlocks.trialDetails && (
                    <Box textAlign="center">
                        <TrialDetails>{contentBlocks.trialDetails.text}</TrialDetails>
                    </Box>
                )}
            </Stack>
        </DialogConfirm>
    );
};
