import { createSelector } from 'reselect';
import { isNewStudentSelector, studentPreferencesSelector } from '@lingoda/students';

export const guidanceOverlayVisibilitySelector = createSelector(
    (state) => isNewStudentSelector(state),
    (state) => studentPreferencesSelector(state),
    (isNewStudent, preferences) =>
        !!isNewStudent &&
        !preferences?.dismissedWidgets?.myCourseGuidanceBanner &&
        // Add option to hide banner for e2e, instead of manually skip it in every test
        localStorage.getItem('my_course_guidance_banner') !== 'hidden',
);
