import { Translate, trans } from '@lingoda/i18n';
import { Link, Paper, Stack, Typography, colorsPalette, styled } from '@lingoda/ui';
import { ColouredCalendarIcon } from '@lingoda/ui/icons/lingodaIcons';
import { formatTitleDate } from '@lingoda/booking';

export interface NoClassesFoundProps {
    nextAvailableDate: Date | undefined;
    onNextAvailableDateClick?: () => void;
}

export const NoClassesFound = ({
    nextAvailableDate,
    onNextAvailableDateClick,
}: NoClassesFoundProps) => {
    return (
        <StyledPaper role="region" aria-labelledby="no-classes-found-title" variant="outlined">
            <Stack direction="row" spacing={3} alignItems="center">
                <Icon />
                <Stack direction="column" spacing={1}>
                    <Typography
                        variant="h4"
                        htmlColor={colorsPalette.blackAlpha[70]}
                        id="no-classes-found-title"
                    >
                        {nextAvailableDate ? (
                            <Translate
                                id="no-classes-found-selected-dates-title"
                                domain="student-common"
                            />
                        ) : (
                            <Translate id="no-classes-found-title" domain="student-common" />
                        )}
                    </Typography>
                    {nextAvailableDate && !!onNextAvailableDateClick && (
                        <Typography
                            variant="body2"
                            htmlColor={colorsPalette.blackAlpha[70]}
                            sx={{ wordBreak: 'break-word' }}
                        >
                            <Translate
                                id="no-classes-found-try-changing-filters"
                                domain="student-common"
                                params={{
                                    goToLink: (
                                        <Link
                                            key="goToLink"
                                            component="a"
                                            role="link" // Role should be set explicitly because there's no href. Read https://www.w3.org/TR/html-aria/#docconformance
                                            onClick={onNextAvailableDateClick}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            {trans(
                                                'no-classes-found-go-to-available-date',
                                                {},
                                                'student-common',
                                            )}
                                            &nbsp;
                                            {formatTitleDate(nextAvailableDate)}
                                        </Link>
                                    ),
                                }}
                            />
                        </Typography>
                    )}
                </Stack>
            </Stack>
        </StyledPaper>
    );
};

const StyledPaper = styled(Paper)(({ theme }) => ({
    background: 'transparent',
    padding: theme.spacing(3),
    [`${theme.breakpoints.down('sm')}`]: {
        padding: theme.spacing(2),
    },
}));

const Icon = styled(ColouredCalendarIcon)(({ theme }) => ({
    fontSize: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(6),
    },
}));
