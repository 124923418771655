import { useMemo } from 'react';
import { Button } from '@lingoda/ui';
import { ChecklistItemSlug } from '@lingoda/graphql';
import { resolvePath, studentBookingPath, studentClassDetailsPath } from '@lingoda/router';
import { trans } from '@lingoda/i18n';
import { useJoinClass } from '@lingoda/classes';
import { showApolloErrorToast } from '@lingoda/toasts';
import { useStudentAppointment } from '../../../../hooks/useStudentAppointment';
import { checklistActionsTranslations } from '../translations';

type ActionButtonProps = {
    slug: ChecklistItemSlug;
};

export const ActionButton = ({ slug }: ActionButtonProps) => {
    const [joinClass, { loading: isJoiningClass }] = useJoinClass({
        onError: showApolloErrorToast,
    });

    const { previousAppointment, nextAppointment, isInSession, isNextClassToday, timeRemaining } =
        useStudentAppointment();

    const nextClassId = nextAppointment?.class?.id;
    const prevClassId = previousAppointment?.class?.id;

    const text = useMemo(() => {
        if (slug === ChecklistItemSlug.AttendClass) {
            if (isNextClassToday && timeRemaining) {
                return trans('join-in', { timeRemaining }, 'student-today');
            }
            if (isInSession) {
                return trans('join-class-now', {}, 'student-today');
            }
        }

        return checklistActionsTranslations[slug]?.cta;
    }, [slug, isNextClassToday, timeRemaining, isInSession]);

    const buttonProps = useMemo(() => {
        switch (slug) {
            case ChecklistItemSlug.AttendClass:
                return {
                    onClick: nextClassId ? () => joinClass(nextClassId) : undefined,
                    disabled: !isInSession,
                    loading: isJoiningClass,
                };
            case ChecklistItemSlug.ReviewAfterClass:
            case ChecklistItemSlug.Practice:
                return {
                    href: prevClassId
                        ? resolvePath(studentClassDetailsPath({ class_id: prevClassId }))
                        : undefined,
                };
            case ChecklistItemSlug.BookFirstClass:
                return {
                    href: resolvePath(studentBookingPath()),
                };
            case ChecklistItemSlug.WarmupBeforeClass:
                return {
                    href: nextClassId
                        ? resolvePath(studentClassDetailsPath({ class_id: nextClassId }))
                        : undefined,
                };
            default:
                return {};
        }
    }, [slug, nextClassId, prevClassId, isInSession, isJoiningClass, joinClass]);

    return (
        <Button variant="contained" color="primary" sx={{ flexShrink: '0' }} {...buttonProps}>
            {text}
        </Button>
    );
};
