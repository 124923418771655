import { useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar, DateRange } from 'react-date-range';
import { createDate, getLocale, getWeekStartOn, isSameDay, startOf } from '@lingoda/dates';
import { trans } from '@lingoda/i18n';
import { DatePickerNavigator } from './Navigator';
import { DayCell } from './DayCell';
import type { CalendarProps, Range, RangeKeyDict } from 'react-date-range';

/*
    We need to extend CalendarProps as it does not include
    all of the properties it can receive and which we needed to use
 */
type DayContentRenderer = (date: Date) => JSX.Element;

interface ScrollOptions {
    enabled: boolean;
}

export interface ExtendedCalendarProps extends Omit<CalendarProps, 'date'> {
    direction: 'horizontal' | 'vertical';
    weekdayDisplayFormat?: string;
    monthDisplayFormat?: string;
    months?: number;
    shownDate?: Date;
    color?: string;
    preventSnapRefocus: boolean;
    showPreview: boolean;
    showSelectionPreview: boolean;
    dayContentRenderer: DayContentRenderer;
    scroll?: ScrollOptions;
    ranges?: Range[];
}

type RangeChangeFn = (range: RangeKeyDict) => void;
type DatesChangeFn = (dates: Date[]) => void;

type CalendarType = 'single' | 'range' | 'multiple';

interface CalendarContentProps {
    withScroll: boolean;
    onSelection: DatesChangeFn | RangeChangeFn;
    customProps: Partial<ExtendedCalendarProps>;
    scheduledDays?: Date[];
    scheduledDayTooltip?: string;
    type?: CalendarType;
    dates?: Date[];
    maxDatesSelected?: number;
    getIsCellDisabled?: (date: Date, { isSelected }: { isSelected?: boolean }) => boolean;
    defaultFocusDate?: Date;
}

export const CalendarContent = ({
    withScroll,
    onSelection,
    scheduledDays,
    scheduledDayTooltip,
    customProps,
    type = 'single',
    dates = [],
    maxDatesSelected = 20,
    getIsCellDisabled,
    defaultFocusDate,
}: CalendarContentProps) => {
    const minDate = customProps.minDate ? startOf('day', customProps.minDate) : undefined;
    const maxDate = customProps.maxDate ? startOf('day', customProps.maxDate) : undefined;
    const [extraSelectedDate, setExtraSelectedDate] = useState<Date>();
    const [focusDate, setFocusDate] = useState(() => {
        return defaultFocusDate ?? dates[0] ?? startOf('day', createDate());
    });

    const isRange = type === 'range';
    const isMultiple = type === 'multiple';
    const maxDatesSelectedReached = dates.length >= maxDatesSelected;

    const isDateSelected = useCallback(
        (date: Date) => {
            return dates.some((focusDate) => isSameDay(date, focusDate));
        },
        [dates],
    );

    const navigatorRenderer = useMemo(
        () =>
            function navigatorRenderer(currentDate: Date, changeShownDate: (value: Date) => void) {
                return (
                    <DatePickerNavigator
                        isRange={isRange && !withScroll}
                        showArrows={!withScroll}
                        currentDate={currentDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        changeShownDate={changeShownDate}
                    />
                );
            },
        [withScroll, isRange, maxDate, minDate],
    );

    const dayContentRenderer = useMemo(
        () =>
            function dayContentRenderer(cellDate: Date) {
                const isScheduled =
                    !!scheduledDays && scheduledDays.some((value) => isSameDay(value, cellDate));

                const isSelected = isDateSelected(cellDate);
                const isDisabled = getIsCellDisabled?.(cellDate, { isSelected });

                const showMaxDatesSelectedTooltip =
                    !!extraSelectedDate && isSameDay(extraSelectedDate, cellDate);
                // You can select a maximum of %datesNumber% dates at a time.
                const tooltip = showMaxDatesSelectedTooltip
                    ? trans(
                          'max-dates-selected',
                          { datesNumber: maxDatesSelected },
                          'student-common',
                      )
                    : isScheduled
                      ? scheduledDayTooltip
                      : undefined;

                return (
                    <DayCell
                        date={cellDate}
                        isSelected={isSelected}
                        isScheduled={isScheduled}
                        tooltip={tooltip}
                        tooltipOnClick={showMaxDatesSelectedTooltip}
                        disabled={isDisabled}
                    />
                );
            },
        [
            scheduledDays,
            isDateSelected,
            extraSelectedDate,
            maxDatesSelected,
            scheduledDayTooltip,
            getIsCellDisabled,
        ],
    );

    const baseProps: Partial<ExtendedCalendarProps> = useMemo(
        () => ({
            direction: withScroll ? 'vertical' : 'horizontal',
            locale: getLocale(),
            firstDayOfWeek: getWeekStartOn(),
            preventSnapRefocus: true,
            months: isRange ? 2 : 1,
            shownDate: isRange ? undefined : focusDate,
            showPreview: isRange,
            showSelectionPreview: isRange,
            weekdayDisplayFormat: 'EEEEE',
            monthDisplayFormat: 'MMMM yyyy',
            color: 'none',
            navigatorRenderer,
            dayContentRenderer,
            classNames: {
                calendarWrapper: 'lingoda-calendar',
            },
        }),
        [focusDate, dayContentRenderer, withScroll, isRange, navigatorRenderer],
    );

    const onChangeDateMultiple = useCallback(
        (date: Date) => {
            if (isDateSelected(date)) {
                const newDates = dates.filter((value) => !isSameDay(value, date));
                (onSelection as DatesChangeFn)(newDates);
                setExtraSelectedDate(undefined);
            } else if (maxDatesSelectedReached) {
                setExtraSelectedDate(date);
            } else {
                const newDates = [...dates, date];
                (onSelection as DatesChangeFn)(newDates);
            }

            setFocusDate(date);
        },
        [dates, isDateSelected, maxDatesSelectedReached, onSelection],
    );

    const onChangeDate = useCallback(
        (date: Date) => {
            if (!isMultiple) {
                (onSelection as DatesChangeFn)([date]);
            } else {
                onChangeDateMultiple(date);
            }
        },
        [isMultiple, onChangeDateMultiple, onSelection],
    );

    useEffect(() => {
        if (dates && dates.length < maxDatesSelected && !!extraSelectedDate) {
            setExtraSelectedDate(undefined);
        }
    }, [dates, extraSelectedDate, maxDatesSelected]);

    if (isRange) {
        return (
            <DateRange
                {...baseProps}
                {...customProps}
                onChange={onSelection as RangeChangeFn}
                scroll={{ enabled: withScroll }}
            />
        );
    }

    return (
        <Calendar
            {...baseProps}
            {...customProps}
            date={focusDate}
            onChange={onChangeDate}
            scroll={{ enabled: withScroll }}
        />
    );
};
