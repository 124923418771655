import { trans } from '@lingoda/i18n';
import { type PurchaseType as PlanType } from '@lingoda/graphql';
import { ToggleButton, ToggleButtonGroup } from '@lingoda/ui';
import { FilterGroup } from './FilterGroup';
import type { ToggleButtonGroupProps } from '@mui/material';
import type { FilterProps } from '../../types';

interface PlanTypeFilterProps extends FilterProps, ToggleButtonGroupProps {
    options: PlanType[];
    planType: PlanType;
}

export const PlanTypeFilter = ({
    options,
    planType,
    fullWidth,
    onFilterUpdate,
}: PlanTypeFilterProps) => {
    const handleChange: ToggleButtonGroupProps['onChange'] = (_, value: PlanType) => {
        onFilterUpdate('planType', value);
    };

    return (
        <FilterGroup id="planType-filter" label={trans('plan-type', {}, 'student-store')}>
            <ToggleButtonGroup
                value={planType}
                onChange={handleChange}
                exclusive
                fullWidth={fullWidth}
            >
                {options.map((option) => (
                    <ToggleButton key={option} value={option}>
                        {trans(/*@ignore*/ `plan-type-${option}`, {}, 'student-store')}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </FilterGroup>
    );
};
