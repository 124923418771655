import {
    useRolloverSubscriptionMutation,
    useSkipTrialSubscriptionMutation,
} from '@lingoda/graphql';
import { showApolloErrorToast } from '@lingoda/toasts';
import { type DialogConfirmProps } from '@lingoda/ui';
import { ConfirmRolloverDialog } from './ConfirmRolloverDialog';
import ConfirmRolloverTrialDialog from './ConfirmRolloverTrialDialog';

interface RolloverSubscriptionDialogProps extends ConfirmDialogProps {
    isInTrial?: boolean;
    isRecurring: boolean;
    price: string;
    onSuccess?: () => void;
}

type ConfirmDialogProps = Pick<DialogConfirmProps, 'isOpen' | 'onDismiss'>;

export const RolloverSubscriptionDialog = ({
    isOpen,
    onDismiss,
    onSuccess,
    isInTrial,
    isRecurring,
    price,
}: RolloverSubscriptionDialogProps) => {
    const [skipTrial, { loading: isSkipTrialLoading }] = useSkipTrialSubscriptionMutation({
        onError: showApolloErrorToast,
        onCompleted: () => onSuccess?.(),
    });

    const [rolloverSubscription, { loading: isRolloverSubscriptionLoading }] =
        useRolloverSubscriptionMutation({
            onError: showApolloErrorToast,
            onCompleted: onSuccess,
        });

    const isLoading = isSkipTrialLoading || isRolloverSubscriptionLoading;

    const commonProps = {
        onDismiss,
        isOpen,
        confirmStatus: isLoading ? 'loading' : 'enabled',
        onConfirm: isInTrial ? skipTrial : rolloverSubscription,
    } as const;

    return isInTrial ? (
        <ConfirmRolloverTrialDialog {...commonProps} />
    ) : (
        <ConfirmRolloverDialog
            {...commonProps}
            price={price}
            isRecurringSubscription={isRecurring}
        />
    );
};
