import { useMemo } from 'react';
import { Box, Button, Paper, Stack } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { Location } from '@lingoda/graphql';
import { colorsPalette, styled } from '@lingoda/ui/theme';
import {
    ContentBlockTypography,
    findAllContentBlocks,
    findContentBlock,
} from '@lingoda/content-blocks';
import { type PackageItemProps } from '../types';
import DotDivider from './DotDivider';
import { DetailsContentBlockTypography } from './DetailsContentBlockTypography';
import { SubtitleContentBlockTypography } from './SubtitleContentBlockTypography';
import { TopBadge } from './TopBadge';

const PaperWrapper = styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'disabled',
})<{
    disabled: boolean;
}>(({ theme, disabled }) => ({
    position: 'relative',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: disabled ? colorsPalette.blackAlpha[4] : 'transparent',
    borderWidth: '1px',
    borderColor: disabled ? colorsPalette.blackAlpha[8] : colorsPalette.blackAlpha[20],
    color: colorsPalette.blackAlpha[70],
}));

export const MobilePackageItem = ({ pkg, onPackageSelect }: PackageItemProps) => {
    const { id, isCurrent } = pkg;
    const isDisabled = pkg.isCurrent;

    const handleClickItem = () => {
        onPackageSelect(id);
    };

    const contentBlocks = useMemo(() => {
        return {
            title: findContentBlock(pkg.contentBlocks, Location.PackageTitle),
            subtitle: findContentBlock(pkg.contentBlocks, Location.PackagePaymentDetails),
            details: findAllContentBlocks(pkg.contentBlocks, Location.PackageDetail),
            detailsOld: findContentBlock(pkg.contentBlocks, Location.PackageDetailOld),
            topBadge: findContentBlock(pkg.contentBlocks, Location.TopBadge),
        };
    }, [pkg.contentBlocks]);

    return (
        <PaperWrapper variant="outlined" data-cy="store-package-item" disabled={isDisabled}>
            {contentBlocks.topBadge && (
                <TopBadge>
                    <ContentBlockTypography {...contentBlocks.topBadge} variant="subtitle2" />
                </TopBadge>
            )}

            <Stack spacing={5}>
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack spacing={1}>
                        {contentBlocks.title && (
                            <ContentBlockTypography
                                {...contentBlocks.title}
                                variant="h3"
                                textColor={isDisabled ? 'inherit' : colorsPalette.blackSolid[100]}
                            />
                        )}

                        {contentBlocks.subtitle && (
                            <SubtitleContentBlockTypography
                                {...contentBlocks.subtitle}
                                variant="body1"
                                textColor={isDisabled ? 'inherit' : colorsPalette.blackSolid[100]}
                            />
                        )}
                    </Stack>

                    <Stack alignItems="center">
                        {contentBlocks.details && (
                            <Stack direction="row" divider={<DotDivider mr={1} />} flexWrap="wrap">
                                {contentBlocks.details.map((item, index) => (
                                    <Box key={index} mr={1}>
                                        <DetailsContentBlockTypography
                                            {...item}
                                            variant="body1"
                                            textColor={
                                                isDisabled
                                                    ? 'inherit'
                                                    : colorsPalette.blackSolid[100]
                                            }
                                        />
                                    </Box>
                                ))}
                            </Stack>
                        )}

                        {contentBlocks.detailsOld && (
                            <DetailsContentBlockTypography
                                {...contentBlocks.detailsOld}
                                variant="body1"
                                textColor={isDisabled ? 'inherit' : colorsPalette.blackSolid[100]}
                            />
                        )}
                    </Stack>
                </Stack>

                <Button
                    color="primary"
                    variant="outlined"
                    fullWidth
                    disabled={isDisabled}
                    onClick={handleClickItem}
                >
                    {isCurrent
                        ? trans('current-plan-tag', {}, 'student-store')
                        : trans('select-button', {}, 'student-store')}
                </Button>
            </Stack>
        </PaperWrapper>
    );
};
