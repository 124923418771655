import { useMemo } from 'react';
import { Box, Chip, Paper, Stack } from '@lingoda/ui';
import { colorsPalette, styled } from '@lingoda/ui/theme';
import { Radio } from '@lingoda/forms';
import { trans } from '@lingoda/i18n';
import { Location } from '@lingoda/graphql';
import {
    ContentBlockTypography,
    findAllContentBlocks,
    findContentBlock,
} from '@lingoda/content-blocks';
import { type PackageItemProps } from '../types';
import DotDivider from './DotDivider';
import { DetailsContentBlockTypography } from './DetailsContentBlockTypography';
import { SubtitleContentBlockTypography } from './SubtitleContentBlockTypography';
import { TopBadge } from './TopBadge';

const PaperWrapper = styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'selected',
})<{ selected: boolean; disabled: boolean }>(({ theme, selected, disabled }) => ({
    position: 'relative',
    padding: theme.spacing(3),
    display: 'flex',
    backgroundColor: disabled ? colorsPalette.blackAlpha[4] : 'transparent',
    borderWidth: '1px',
    borderColor: disabled
        ? colorsPalette.blackAlpha[8]
        : selected
          ? colorsPalette.primarySolid[100]
          : colorsPalette.blackAlpha[20],
    color: colorsPalette.blackAlpha[70],
    whiteSpace: 'nowrap',
    cursor: disabled ? 'default' : 'pointer',
}));

const StyledRadio = styled(Radio)(() => ({
    color: colorsPalette.blackAlpha[20],
}));

export const DesktopPackageItem = ({ pkg, isSelected, onPackageSelect }: PackageItemProps) => {
    const { id, isCurrent } = pkg;
    const isDisabled = pkg.isCurrent;

    const handleClickItem = () => {
        if (!isCurrent) {
            onPackageSelect(id);
        }
    };

    const contentBlocks = useMemo(
        () => ({
            title: findContentBlock(pkg.contentBlocks, Location.PackageTitle),
            subtitle: findContentBlock(pkg.contentBlocks, Location.PackagePaymentDetails),
            details: findAllContentBlocks(pkg.contentBlocks, Location.PackageDetail),
            detailsOld: findContentBlock(pkg.contentBlocks, Location.PackageDetailOld),
            topBadge: findContentBlock(pkg.contentBlocks, Location.TopBadge),
        }),
        [pkg.contentBlocks],
    );

    return (
        <PaperWrapper
            variant="outlined"
            data-cy="store-package-item"
            selected={isSelected}
            disabled={isDisabled}
            onClick={handleClickItem}
        >
            {contentBlocks.topBadge && (
                <TopBadge data-cy="store-package-top-badge">
                    <ContentBlockTypography {...contentBlocks.topBadge} variant="subtitle2" />
                </TopBadge>
            )}
            <StyledRadio value={id} label="" disabled={isDisabled} checked={isSelected} />
            <Box flex={1}>
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack spacing={1}>
                        {contentBlocks.title && (
                            <ContentBlockTypography
                                {...contentBlocks.title}
                                variant="h3"
                                textColor={isDisabled ? 'inherit' : colorsPalette.blackSolid[100]}
                            />
                        )}

                        {isCurrent ? (
                            <Chip
                                color="info"
                                label={trans('current-plan-tag', {}, 'student-store')}
                            />
                        ) : (
                            contentBlocks.subtitle && (
                                <SubtitleContentBlockTypography
                                    {...contentBlocks.subtitle}
                                    variant="body1"
                                    textColor={
                                        isDisabled ? 'inherit' : colorsPalette.blackSolid[100]
                                    }
                                />
                            )
                        )}
                    </Stack>

                    <Stack alignItems="center">
                        {contentBlocks.details && (
                            <Stack direction="row" spacing={1} divider={<DotDivider />}>
                                {contentBlocks.details.map((item, index) => (
                                    <DetailsContentBlockTypography
                                        key={index}
                                        {...item}
                                        variant="body1"
                                        textColor={
                                            isDisabled ? 'inherit' : colorsPalette.blackSolid[100]
                                        }
                                    />
                                ))}
                            </Stack>
                        )}
                        {contentBlocks.detailsOld?.text && (
                            <DetailsContentBlockTypography
                                {...contentBlocks.detailsOld}
                                variant="body1"
                            />
                        )}
                    </Stack>
                </Stack>
            </Box>
        </PaperWrapper>
    );
};
