import { FormattedMessage, trans } from '@lingoda/i18n';
import { Alert, Link, styled } from '@lingoda/ui';
import WarningIcon from '@lingoda/ui/icons/WarningIcon';
import { NEW_CHROME_LINK, NEW_EDGE_LINK } from '../../utils';

interface Props {
    severity: 'error' | 'warning';
}

export const UseDifferentDeviceBanner = ({ severity }: Props) => {
    return (
        <AlertStyled
            severity={severity}
            title={trans('use-different-device.title', {}, 'classroom-common+intl-icu')}
            variant="outlined"
            icon={<WarningIcon />}
        >
            <FormattedMessage
                message={trans('use-different-device.body', {}, 'classroom-common+intl-icu')}
                values={{
                    link1: (chunks) => (
                        <LinkStyled
                            variant="body2"
                            key="link1"
                            color="inherit"
                            colorHover="inherit"
                            target="_blank"
                            to={NEW_CHROME_LINK}
                        >
                            {chunks}
                        </LinkStyled>
                    ),
                    link2: (chunks) => (
                        <LinkStyled
                            variant="body2"
                            key="link2"
                            color="inherit"
                            colorHover="inherit"
                            target="_blank"
                            to={NEW_EDGE_LINK}
                        >
                            {chunks}
                        </LinkStyled>
                    ),
                }}
            />
        </AlertStyled>
    );
};

const AlertStyled = styled(Alert)(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(2),
}));

const LinkStyled = styled(Link)({
    fontWeight: 400,
});
