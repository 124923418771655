import { FullScreenOverlay, TrackingContext, colorsPalette } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { type ClassType, useShouldDisplayClassroomPromoBannerStudentQuery } from '@lingoda/graphql';
import { BookingSearchFrameContainer, useBookingView } from '@lingoda/booking-ui';
import { BookingSearchResults } from '../BookingSearchResults';
import { BookingFilter as BookingFilterComponent } from './BookingFilter';
import { useBookingOverlayFilter } from './useBookingOverlayFilter';

interface Props {
    moduleId?: number;
    lessonId?: number;
    classType?: ClassType;
    open: boolean;
    onClose: () => void;
}

export const BookingOverlay = ({ moduleId, lessonId, classType, open, onClose }: Props) => {
    const { filter, handleChange, handleReset } = useBookingOverlayFilter(
        moduleId,
        lessonId,
        classType,
    );
    const [bookingView] = useBookingView();
    const { data: classroomPromo } = useShouldDisplayClassroomPromoBannerStudentQuery({
        fetchPolicy: 'cache-and-network',
    });

    return (
        <TrackingContext section="Booking overlay">
            <FullScreenOverlay
                open={open}
                onClose={onClose}
                title={trans('available-classes', {}, 'student-common')}
                titleCentered
                headerContent={
                    <BookingSearchFrameContainer data-cy="Booking filter">
                        <BookingFilterComponent
                            value={filter}
                            onChange={handleChange}
                            onReset={handleReset}
                        />
                    </BookingSearchFrameContainer>
                }
            >
                <BookingSearchResults
                    isEmbedded
                    bookingFilter={filter}
                    bookingView={bookingView}
                    bgColor={colorsPalette.whiteSolid[100]}
                    onBookingFilterChange={handleChange}
                    showClassroomPromoBanner={classroomPromo?.shouldDisplayClassroomPromoBanner}
                />
            </FullScreenOverlay>
        </TrackingContext>
    );
};
