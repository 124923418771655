import MuiSelect from '@mui/material/Select';
import { Box } from '../../layout/Box';
import { MenuItem } from '../../layout/MenuItem';
import { CheckmarkIcon24, ChevronIcon16 } from '../../icons/lingodaIcons';
import type { MenuProps as MuiMenuProps } from '../../layout/Menu';
import type { FC, ReactNode, SVGProps } from 'react';
import type { MenuItemProps } from '../../layout/MenuItem';
import type { SvgIconProps } from '../../layout/SvgIcon';
import type { SelectProps as MuiSelectProps } from '@mui/material/Select';

const ICON_SIZE = 21;
const SELECTED_OPTION_ICON_SIZE = 16;

const SelectMenuProps: Partial<MuiMenuProps> = {
    PaperProps: {
        style: {
            paddingTop: 8,
            paddingBottom: 8,
        },
    },
};

export interface SelectProps<T = unknown> extends MuiSelectProps {
    options: readonly T[] | undefined;
    getOptionValue: (option: T) => MenuItemProps['value'];
    getOptionLabel: (option: T) => ReactNode;
    getOptionIcon?: (option: T) => FC<SVGProps<SVGSVGElement>> | FC<SvgIconProps>;
}

// eslint-disable-next-line
export const Select = <T extends any>({
    options,
    value,
    getOptionValue,
    getOptionLabel,
    getOptionIcon,
    disabled,
    MenuProps,
    ...rest
}: SelectProps<T>) => {
    const isSelected = (value: unknown, option: T) => {
        if (Array.isArray(value)) {
            return value.includes(getOptionValue(option));
        }

        return value === getOptionValue(option);
    };

    const renderIcon = (option: T) => {
        if (!getOptionIcon) {
            return null;
        }

        const Icon = getOptionIcon(option);

        return <Icon opacity={disabled ? 0.2 : 1} />;
    };

    const renderOption = (option: T): ReactNode => (
        <Box display="flex" flex={1} flexDirection="row" alignItems="center">
            {getOptionIcon && (
                <Box
                    mr={1}
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    {renderIcon(option)}
                </Box>
            )}
            <Box flex={1}>{getOptionLabel(option)}</Box>
            <Box ml={1} width={28} height={28}>
                {isSelected(value, option) && <CheckmarkIcon24 color="primary" />}
            </Box>
        </Box>
    );

    const renderValue = (value: unknown) => {
        if (isEmpty(value)) {
            return undefined;
        }
        const selectedValues = Array.isArray(value) ? value : [value];
        const selectedOptions = options?.filter((option) =>
            selectedValues.includes(getOptionValue(option)),
        );

        if (!getOptionIcon) {
            return selectedOptions?.map(getOptionLabel)?.join(', ');
        }

        return (
            <Box display="flex" flexDirection="row" flexWrap="nowrap" overflow="hidden">
                {selectedOptions?.map((option, index) => {
                    const Icon = getOptionIcon(option);

                    return (
                        <>
                            {index > 0 && ','}
                            <Box
                                ml={index > 0 ? 1 : 0}
                                mr={1}
                                width={ICON_SIZE}
                                height={ICON_SIZE}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Icon
                                    opacity={disabled ? 0.2 : 1}
                                    style={{
                                        maxWidth: `${SELECTED_OPTION_ICON_SIZE}px`,
                                        maxHeight: `${SELECTED_OPTION_ICON_SIZE}px`,
                                    }}
                                />
                            </Box>
                            {getOptionLabel(option)}
                        </>
                    );
                })}
            </Box>
        );
    };

    return (
        <MuiSelect
            IconComponent={ChevronIcon16}
            value={value}
            renderValue={renderValue}
            disabled={disabled}
            MenuProps={MenuProps ? { ...SelectMenuProps, ...MenuProps } : SelectMenuProps}
            {...rest}
        >
            {options?.map((option) => (
                <MenuItem
                    key={`${getOptionValue(option)}`}
                    value={getOptionValue(option)}
                    selected={isSelected(value, option)}
                >
                    {renderOption(option)}
                </MenuItem>
            ))}
        </MuiSelect>
    );
};

const isEmpty = (value: unknown) =>
    value === '' ||
    value === undefined ||
    value === null ||
    (Array.isArray(value) && !value.length);
