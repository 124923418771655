import { getClassHasEnded } from '@lingoda/classes-helpers';
import { createDate } from '@lingoda/dates';
import type { ExistingClassData, GeneratedClassData } from '../types';

type ClassParams =
    | Pick<ExistingClassData, 'isExisting' | 'isForNewClassroom' | 'endDate'>
    | Pick<GeneratedClassData, 'isExisting' | 'endDate'>;

export const getIsClassroomAlertForClassVisible = (classItem: ClassParams) => {
    const hasEnded = getClassHasEnded(createDate(classItem.endDate), createDate());
    const isNewClassroomPromoBannerVisible =
        classItem.isExisting && classItem.isForNewClassroom && !hasEnded;

    return isNewClassroomPromoBannerVisible;
};
