import { gtmPush, updateGtmUserData } from '@lingoda/analytics';
import { userSectionSelector, userSelector } from '@lingoda/auth';
import { formatDateTime } from '@lingoda/dates';
import { getLang } from '@lingoda/env';
import { studentModuleSelector } from '@lingoda/modules';
import { studentCreditsSelector, studentSelector } from '@lingoda/students';
import { currentSubscriptionSelector } from '@lingoda/subscriptions';
import type { Action, Middleware } from 'redux';

const isActionUpdateGtmUserData = (action: Action<string>) =>
    typeof action === 'object' && [updateGtmUserData.toString()].includes(action.type);

const middleware: Middleware =
    (store) =>
    (next) =>
    <A extends Action>(action: A) => {
        if (isActionUpdateGtmUserData(action)) {
            const state = store.getState();

            const user = userSelector(state);
            const student = studentSelector(state);
            const module = studentModuleSelector(state);

            if (!user || !student || !module) {
                return;
            }

            const section = userSectionSelector(state);
            const subscription = currentSubscriptionSelector(state);

            const { group: groupCredits, individual: individualCredits } =
                studentCreditsSelector(state);

            gtmPush({
                event: 'user-data-updated',
                isStudent: true,
                isTeacher: false,
                section: section?.name.toString(),
                subscriptionStatus: subscription?.status,
                subscriptionProductId: subscription?.productId,
                subscriptionProductName: subscription?.title,
                subscriptionNextChargeAt:
                    subscription?.nextChargeAt && formatDateTime(subscription.nextChargeAt),
                studentIndividualCredits: individualCredits,
                studentGroupCredits: groupCredits,
                studentCurriculumId: student?.curriculumId,
                studentModuleName: module?.name,
                studentCompletedClasses: module?.completedClasses,
                studentTotalClasses: module?.totalClasses,
                userIsSprintRunner: user?.isSprintRunner,
                userIsCorporateClient: user?.isCorporateClient,
                userLocale: getLang(),
                userMarketingConsent: !!user?.marketingAgreement,
                userHasReferralCode: !!user?.referralCode,
                email: user?.email,
                userId: user?.id,
                userUID: user?.uid,
            });
        }

        return next(action);
    };

export default middleware;
