import { Box } from '../../layout/Box';
import { styled } from '../../theme';
import {
    type DatePickerDotColor,
    datePickerBase,
    datePickerDesktop,
    datePickerMobile,
} from './styles';

interface CalendarContainerProps {
    loading?: boolean;
    dotColor: DatePickerDotColor;
    scheduledDayBg?: string;
    selectedDayBg?: string;
    showHoverStyles?: boolean;
    isMobile: boolean;
}

export const CalendarContainer = styled(Box, {
    shouldForwardProp: (prop) =>
        ![
            'loading',
            'dotColor',
            'scheduledDayBg',
            'showHoverStyles',
            'isMobile',
            'selectedDayBg',
        ].includes(prop as string),
})<CalendarContainerProps>(({
    theme,
    loading,
    dotColor,
    scheduledDayBg,
    selectedDayBg,
    showHoverStyles = true,
    isMobile,
}) => {
    return {
        ...datePickerBase(theme, { dotColor, scheduledDayBg, selectedDayBg }, loading),
        ...(isMobile
            ? {
                  display: 'flex',
                  flexDirection: 'column',
                  ...datePickerMobile(theme),
              }
            : datePickerDesktop({ showHoverStyles })),
    };
});
