import { useCallback, useMemo } from 'react';
import { Currency, Store, usePackagesQuery } from '@lingoda/graphql';
import type { CurriculumId } from '@lingoda/sections';
import type { SubscriptionsStorePathQuery } from '@lingoda/router';
import { useQueryStringState } from '@lingoda/router';
import { useCastToUnionMemo } from '@lingoda/hooks';
import {
    extractPackageFilterValues,
    filtersOptionsDefault,
    filtersStateDefaults,
    getFilteredPackages,
    getSectionCurriculumFallback,
} from './filter';
import StoreContext from './StoreContext';
import type { FiltersState, PurchasePackage, UpdateFilterFn } from '../../types';
import type { PropsWithChildren } from 'react';
import type { StoreContextValue } from './types';

interface ProviderProps {
    sectionName: SectionName;
    defaultCurrency: Currency;
    defaultCurriculumId: NonNullable<CurriculumId>;
    isLearningLanguageChange?: boolean;
}

const StoreContextProvider = ({
    sectionName,
    defaultCurrency,
    defaultCurriculumId,
    children,
    isLearningLanguageChange = false,
}: PropsWithChildren<ProviderProps>) => {
    const [qsState, setQsState] = useQueryStringState<SubscriptionsStorePathQuery>();

    const updateFilter = useCallback<UpdateFilterFn>(
        (name, value) => setQsState((prev) => ({ ...prev, [name]: value })),
        [setQsState],
    );

    const currency = useCastToUnionMemo(qsState.currency, currencyOptions) || defaultCurrency;

    const { loading, data } = usePackagesQuery({
        variables: {
            sectionName,
            currency: currency,
            store: Store.Internal,
            ignoreSectionValidation: isLearningLanguageChange,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });
    const packages = data?.packages as PurchasePackage[] | undefined;
    const { filtersOptions, curriculumSlugsOptions } = useMemo(
        () =>
            packages
                ? extractPackageFilterValues(packages)
                : { filtersOptions: filtersOptionsDefault, curriculumSlugsOptions: [] },
        [packages],
    );

    const classType =
        useCastToUnionMemo(qsState.classType, filtersOptions.classTypes) ||
        filtersStateDefaults.classType;

    const planType =
        useCastToUnionMemo(qsState.planType, filtersOptions.planTypes) ||
        filtersStateDefaults.planType;
    const curriculumSlug =
        useCastToUnionMemo(qsState.curriculumSlug, curriculumSlugsOptions) ||
        getSectionCurriculumFallback(filtersOptions.sectionCurriculums, defaultCurriculumId);

    const filtersState: FiltersState = useMemo(
        () => ({
            currency,
            classType,
            planType,
            curriculumSlug,
        }),
        [currency, classType, planType, curriculumSlug],
    );

    const filteredPackages = useMemo(
        () => getFilteredPackages(packages || [], filtersState),
        [filtersState, packages],
    );

    const storeContext: StoreContextValue = {
        filtersOptions,
        filtersState,
        filteredPackages,
        loading,
        updateFilter,
    };

    return <StoreContext.Provider value={storeContext}>{children}</StoreContext.Provider>;
};

const currencyOptions = Object.values(Currency);

export default StoreContextProvider;
