import { useState } from 'react';
import { ExpandMore } from '@lingoda/ui/icons/lingodaIcons';
import {
    Accordion,
    AccordionDetails,
    type AccordionProps,
    AccordionSummary,
    type AccordionSummaryProps,
    Paper,
    Typography,
    styled,
} from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { useParams } from '@lingoda/router';
import { useBasicActivityChallengeDataSuspenseQuery } from '@lingoda/graphql';
import { createDate, isAfter } from '@lingoda/dates';

export const FaqAccordion = () => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const { challengeName } = useParams<{ challengeName: string }>();
    const {
        data: { activityChallenge: challenge },
    } = useBasicActivityChallengeDataSuspenseQuery({
        variables: { challengeName },
    });

    const handleChange = (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const prefix = 'faq-accordion-block';
    const getBlockId = (index: number) => `${prefix}-${index}`;

    return (
        <Paper elevation={1} sx={{ px: { xs: 2, sm: 3 } }}>
            {getTexts(challenge.startDate).map((block, index) => (
                <AccordionStyled
                    key={getBlockId(index)}
                    expanded={expanded === getBlockId(index)}
                    onChange={handleChange(getBlockId(index))}
                    disableGutters
                    elevation={0}
                    square
                >
                    <AccordionSummaryStyled
                        sx={index === 0 ? { borderTop: 'none' } : {}}
                        expandIcon={<ExpandMore />}
                    >
                        <Typography variant="subtitle2">{block.question}</Typography>
                    </AccordionSummaryStyled>
                    <AccordionDetailsStyled>
                        <Typography variant="body2">{block.answer}</Typography>
                    </AccordionDetailsStyled>
                </AccordionStyled>
            ))}
        </Paper>
    );
};

const getTexts = (startDate: string) => {
    const challengeStartDate = createDate(startDate);
    const learningActivitiesUpdateDate = createDate('2024-10-30');

    const learningActivitiesAnswer = isAfter(challengeStartDate, learningActivitiesUpdateDate)
        ? trans('faq-answer-what-counts-as-learning-activities-new', {}, 'student-challenges')
        : trans('faq-answer-what-counts-as-learning-activities', {}, 'student-challenges');

    return [
        {
            question: trans('faq-question-what-are-challenges', {}, 'student-challenges'),
            answer: trans('faq-answer-what-are-challenges', {}, 'student-challenges'),
        },
        {
            question: trans(
                'faq-question-what-counts-as-learning-activities',
                {},
                'student-challenges',
            ),
            answer: learningActivitiesAnswer,
        },
        {
            question: trans('faq-question-how-do-I-track-my-progress', {}, 'student-challenges'),
            answer: trans('faq-answer-how-do-I-track-my-progress', {}, 'student-challenges'),
        },
        {
            question: trans('faq-question-how-can-I-join-challenge', {}, 'student-challenges'),
            answer: trans('faq-answer-how-can-I-join-challenge', {}, 'student-challenges'),
        },
    ];
};

const AccordionStyled = styled((props: AccordionProps) => (
    <Accordion disableGutters elevation={0} square {...props} />
))({
    '&.Mui-expanded:before': {
        opacity: 1,
    },
    '&:before': {
        backgroundColor: 'rgba(5, 31, 78, 0.16)',
    },
});

const AccordionSummaryStyled = styled((props: AccordionSummaryProps) => (
    <AccordionSummary expandIcon={<div />} {...props} />
))(({ theme }) => ({
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        margin: 0,
    },
}));

const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0, 0, 2),
    [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing(3),
    },
}));
