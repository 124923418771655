import { Icon } from '@lingoda/graphql';
import { CheckRoundIcon } from '@lingoda/ui/icons/CheckRound';
import { SectionIcons } from '@lingoda/ui/icons/SectionIcons';
import { type SvgIconProps } from '@lingoda/ui';
import PriceTag from './PriceTag';
import Heart from './Heart';
import TrialPackage from './TrialPackage';
import StudyCalendar from './StudyCalendar';
import PayInRates from './PayInRates';

interface Props {
    icon: Icon;
    fontSize?: SvgIconProps['fontSize'];
}

const sectionIconMap = {
    [Icon.SectionDe]: 'german',
    [Icon.SectionEn]: 'english',
    [Icon.SectionEs]: 'spanish',
    [Icon.SectionFr]: 'french',
    [Icon.SectionIt]: 'italian',
};

const ContentBlockIcon = ({ icon, fontSize }: Props) => {
    let IconComponent;
    switch (icon) {
        case Icon.Heart:
            IconComponent = Heart;
            break;
        case Icon.Pricetag:
            IconComponent = PriceTag;
            break;
        case Icon.Trial:
            IconComponent = TrialPackage;
            break;
        case Icon.StudyCalendar:
            IconComponent = StudyCalendar;
            break;
        case Icon.PayInRates:
            IconComponent = PayInRates;
            break;
        case Icon.SectionEn:
        case Icon.SectionEs:
        case Icon.SectionDe:
        case Icon.SectionFr:
        case Icon.SectionIt:
            IconComponent = SectionIcons[sectionIconMap[icon]];
            break;
        default:
            IconComponent = CheckRoundIcon;
    }

    return <IconComponent fontSize={fontSize} display="inline-flex" />;
};

export default ContentBlockIcon;
