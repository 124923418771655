import { useEffect } from 'react';
import { useShowPracticeSatisfactionSurveyQuery } from '@lingoda/graphql';
import { showPopup } from '@lingoda/popups';

export const PracticeSatisfactionSurveyPopupTrigger = () => {
    // Updates to showPracticeSatisfactionSurvey happen through dismissedWidgets in updatePreferences
    // action in Redux. This means the value can change in BE without GraphQL cache being updated.
    // As the cache is unreliable, better to just ignore it and get the current value from BE
    const { data } = useShowPracticeSatisfactionSurveyQuery({ fetchPolicy: 'network-only' });

    const showPracticeSatisfactionSurvey = data?.student.showPracticeSatisfactionSurvey;

    useEffect(() => {
        if (showPracticeSatisfactionSurvey) {
            showPopup('practiceSatisfaction');
        }
    }, [showPracticeSatisfactionSurvey]);

    return null;
};
