import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useCallback, useEffect } from 'react';
import { useDispatch, useToggleState } from '@lingoda/hooks';
import type { CustomHelperProps } from '@lingoda/ui/Tour';
import Tour from '@lingoda/ui/Tour';
import { Box, Paper, useResolution } from '@lingoda/ui';
import { finishProductTour } from '@lingoda/students';
import { styled } from '@lingoda/ui/theme';
import { useShouldShowBookingTour } from '../../hooks';
import { MobileStep1 } from './MobileStep1';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Toolbar } from './Toolbar';

export const mobileTourConfig = [
    {
        selector: '[data-product-tour="step1"]',
        content: <MobileStep1 />,
    },
];

export const tourConfig = [
    {
        selector: '[data-product-tour="step1"]',
        content: <Step1 />,
    },
    {
        selector: '[data-product-tour="step2"]',
        content: <Step2 />,
    },
    {
        selector: '[data-product-tour="step3"]',
        content: <Step3 />,
    },
];

const Content = styled(Box)(({ theme }) => ({
    '& ul': {
        paddingLeft: theme.spacing(2.5),
    },
}));

export const TourCustomHelper = ({
    current,
    content,
    totalSteps,
    gotoStep,
    close,
}: CustomHelperProps) => {
    const goToNext = useCallback(() => {
        gotoStep(current + 1);
    }, [gotoStep, current]);

    return (
        <Paper variant="outlined">
            <Box p={3} maxWidth={330}>
                <Content position="relative">
                    {typeof content === 'function' ? null : content}
                    <Toolbar
                        current={current}
                        totalSteps={totalSteps}
                        onClose={close}
                        onNext={goToNext}
                    />
                </Content>
            </Box>
        </Paper>
    );
};

export const BookingTour = () => {
    const [tourOpen, openTour, closeTour] = useToggleState(false);

    const isPhablet = useResolution('phablet');
    const dispatch = useDispatch();
    const shouldShowBookingTour = useShouldShowBookingTour();

    useEffect(() => {
        if (shouldShowBookingTour) {
            openTour();
        }
    }, [shouldShowBookingTour, openTour]);

    const handleRequestClose = useCallback(() => {
        dispatch(finishProductTour());
        closeTour();
    }, [closeTour, dispatch]);

    return (
        <Tour
            onRequestClose={handleRequestClose}
            isOpen={tourOpen}
            onAfterOpen={disableBodyScroll}
            onBeforeClose={enableBodyScroll}
            closeWithMask={false}
            disableKeyboardNavigation={false}
            disableInteraction
            steps={isPhablet ? mobileTourConfig : tourConfig}
            CustomHelper={TourCustomHelper}
        />
    );
};
