import { SubscriptionType, useStudentCurrentSubscriptionTypeSuspenseQuery } from '@lingoda/graphql';

export const useIsLearningTeams = () => {
    const { data } = useStudentCurrentSubscriptionTypeSuspenseQuery({
        fetchPolicy: 'cache-and-network',
    });

    return (
        !!data && data.subscriptions?.currentSubscription?.type === SubscriptionType.LearningTeam
    );
};
