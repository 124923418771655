import { createDate } from '@lingoda/dates';
import { ClassType } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';
import { ClassInClassroomAlert } from '@lingoda/student-banner';
import { Box, Button, Grid, Portal, useResolution } from '@lingoda/ui';
import { getIsClassroomAlertForClassVisible } from '../../../utils';
import { ClassPlatform } from './ClassPlatform';
import { ClassStatusCustom } from './ClassStatusHeader';
import ClassTime from './ClassTime';
import { Participants } from './Participants';
import { TileBody } from './TileBody';
import Title from './Title';
import type { ExistingClassData, GeneratedClassData } from '../../../types';

export interface PreBookingClassDetailsProps {
    classItem: ExistingClassData | GeneratedClassData;
    onBookClick?: () => void;
    onCancelClick?: () => void;
    isClassBookable?: boolean;
    portalContainers?: { status?: Element | null; cta?: Element | null };
}

export const PreBookingClassDetails = ({
    onBookClick,
    isClassBookable,
    portalContainers,
    classItem,
}: PreBookingClassDetailsProps) => {
    const startDate = createDate(classItem.startDate);
    const endDate = createDate(classItem.endDate);
    const isGroupClass = classItem.type === ClassType.Group;
    const isInPortal = useResolution('phone-large');

    const button = (
        <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={onBookClick}
            disabled={!isClassBookable}
        >
            {trans('btn-book', {}, 'student-common')}
        </Button>
    );

    const isClassFullyBooked = classItem.isExisting && classItem.availableSeats === 0;

    const teacher = classItem.isExisting ? classItem.teacher : undefined;

    const isNewClassroomAlertVisible = getIsClassroomAlertForClassVisible(classItem);

    return (
        <>
            {isClassFullyBooked && (
                <Portal container={portalContainers?.status} disablePortal={!isInPortal}>
                    <ClassStatusCustom
                        title={trans('class-fully-booked', {}, 'student-classes')}
                        subtitle={trans('find-upcoming-classes', {}, 'student-classes')}
                    />
                </Portal>
            )}
            <TileBody>
                <Grid container>
                    <Title teacher={teacher} isGroupClass={isGroupClass} />
                    <ClassTime startDate={startDate} endDate={endDate} />
                    {classItem.isExisting && <Participants participants={classItem.students} />}
                    <ClassPlatform
                        title={trans(
                            'hosted-on-zoom',
                            {
                                provider: classItem.isForNewClassroom
                                    ? 'Lingoda Classroom'
                                    : 'Zoom',
                            },
                            'student-booking',
                        )}
                    />

                    {!isClassFullyBooked ? (
                        isInPortal && portalContainers?.cta ? (
                            <Portal container={portalContainers.cta}>{button}</Portal>
                        ) : (
                            <Grid item xs={12} marginTop={3}>
                                {button}
                            </Grid>
                        )
                    ) : (
                        <div></div>
                    )}

                    {isNewClassroomAlertVisible && (
                        <Box mt={3}>
                            <ClassInClassroomAlert />
                        </Box>
                    )}
                </Grid>
            </TileBody>
        </>
    );
};
