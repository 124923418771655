import { fetchMe } from '@lingoda/auth';
import { DialogComponent, showDialog } from '@lingoda/dialogs';
import { useRolloverSubscriptionMutation } from '@lingoda/graphql';
import { useDispatch, useToggleState } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';
import { SettingsPages, studentSettingsPath, useRouteMatcher } from '@lingoda/router';
import { ConfirmRolloverTrialDialog, fetchSubscriptions } from '@lingoda/subscriptions';
import { showApolloErrorToast } from '@lingoda/toasts';
import { Button, NotificationBar } from '@lingoda/ui';
import type { FC } from 'react';

interface Props {
    onClose: () => void;
}

export const RolloverTrialNotificationBar: FC<Props> = ({ onClose }) => {
    const [confirm, openConfirm, closeConfirm] = useToggleState(false);
    const dispatch = useDispatch();
    const isMembershipPath = useRouteMatcher(
        studentSettingsPath({ page: SettingsPages.Membership }),
    );

    const [rolloverSubscription, { loading }] = useRolloverSubscriptionMutation({
        onError: showApolloErrorToast,
        onCompleted: () => {
            closeConfirm();
            dispatch(showDialog(DialogComponent.RolloverSuccessDialog));
            dispatch(fetchSubscriptions());
            dispatch(fetchMe());
        },
    });

    return (
        <NotificationBar
            title={trans('skip-subscription-banner-text', {}, 'student-common')}
            onClose={onClose}
            actions={[
                <Button
                    color="primary"
                    variant="contained"
                    key="btn-skip-free-trial"
                    size="small"
                    fullWidth
                    onClick={openConfirm}
                >
                    {trans('btn-skip-free-trial', {}, 'student-common')}
                </Button>,
                !isMembershipPath && (
                    <Button
                        fullWidth
                        size="small"
                        variant="outlined"
                        color="secondary"
                        href={studentSettingsPath({ page: SettingsPages.Membership })}
                    >
                        {trans('btn-manage-subscription', {}, 'student-common')}
                    </Button>
                ),
            ]}
        >
            <ConfirmRolloverTrialDialog
                onDismiss={closeConfirm}
                confirmStatus={loading ? 'loading' : 'enabled'}
                isOpen={confirm}
                onConfirm={rolloverSubscription}
            />
        </NotificationBar>
    );
};
