import { memo } from 'react';
import { Box, Skeleton, Stack, Typography } from '@lingoda/ui';
import { ClassListItemSkeleton } from './ClassListItem/ClassListItemSkeleton';

interface Props {
    count: number;
}

const ClassListSkeletonComponent = ({ count }: Props) => (
    <Stack data-cy="Class placeholder">
        <Box pt={3} pb={1.5}>
            <Typography variant="body1">
                <Skeleton variant="text" width={230} />
            </Typography>
        </Box>
        <Stack spacing={0}>
            {[...Array(count).keys()].map((key) => (
                <ClassListItemSkeleton key={key} />
            ))}
        </Stack>
    </Stack>
);

export const ClassListSkeleton = memo(ClassListSkeletonComponent);
