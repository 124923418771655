import { colorsPalette, styled } from '@lingoda/ui/theme';
import { ContentBlockTypography } from '@lingoda/content-blocks';

interface Props {
    strongH1?: boolean;
}

export const DetailsContentBlockTypography = styled(ContentBlockTypography)<Props>(
    ({ theme, strongH1 = true }) => ({
        '& s': {
            color: colorsPalette.blackAlpha[30],
        },
        ...(strongH1
            ? {
                  '& strong': { ...theme.typography.h1 },
              }
            : {}),
    }),
);
