import { useMemo } from 'react';
import type { ChecklistItem } from '@lingoda/graphql';
import { Stack } from '@lingoda/ui';
import { checklistStepsTranslations } from '../translations';
import { ChecklistStepItem } from './ChecklistStepItem';

interface ChecklistStepsProps {
    checklist: readonly ChecklistItem[];
}

export const ChecklistSteps = ({ checklist }: ChecklistStepsProps) => {
    const checklistSteps = useMemo(() => {
        let isActiveFound = false;

        return checklist.map((item, index) => {
            const isActive = !isActiveFound && !item.isCompleted;
            if (isActive) {
                isActiveFound = true;
            }

            return {
                id: item.id,
                isCompleted: item.isCompleted,
                isActive,
                title: checklistStepsTranslations[item.slug]?.title || item.title,
                stepNumber: index + 1,
            };
        });
    }, [checklist]);

    return (
        <Stack spacing={1}>
            {checklistSteps.map((step) => (
                <ChecklistStepItem key={step.id} step={step} />
            ))}
        </Stack>
    );
};
