import { useCallback } from 'react';
import { actionFilter } from '@lingoda/analytics';
import { extendSlots, mapTimeSlotsToEvent } from '@lingoda/booking';
import type { TimeFormat } from '@lingoda/dates';
import { trans } from '@lingoda/i18n';
import {
    FormControl,
    InputLabel,
    MuiSelect,
    Stack,
    TIME_SLOT_VALUE_FORMAT,
    Typography,
    colorsPalette,
    formatTimeSlot,
    styled,
    useResolution,
    useUiEvent,
} from '@lingoda/ui';
import { ChevronIcon16 } from '@lingoda/ui/icons/ChevronIcon';
import { FilterLabel } from '../FilterLabel';
import { TimeSlotFilterMenu } from './TimeSlotFilterMenu';
import { ResetTimeButton } from './ResetTimeButton';
import type { TimeSlotsFilterValue } from './model';
import type { FilterProps } from '../model';

export type TimeSlotFilterProps = FilterProps<TimeSlotsFilterValue> & {
    timeFormat: TimeFormat;
    disabled?: boolean;
    loading?: boolean;
    label?: string;
    title?: string;
};

export const TimeSlotFilter = ({
    value,
    onChange,
    timeFormat,
    disabled,
    loading,
    label = trans('select-time', {}, 'student-common'),
    title = trans('select-time', {}, 'student-common'),
}: TimeSlotFilterProps) => {
    const isMobile = useResolution('phone');
    const addEvent = useUiEvent();
    const labelId = 'filter-time-slots';

    const renderedValue = useCallback(
        () => value && formatValue(value, timeFormat),
        [timeFormat, value],
    );

    const handleOnChange: TimeSlotFilterProps['onChange'] = useCallback(
        (value) => {
            const slots = value.timeSlots?.slots || [];
            const extendHours = value.timeSlots?.extendHours || 0;
            void addEvent(actionFilter('Time'), {
                metadata: mapTimeSlotsToEvent(slots, extendHours),
            });
            onChange(value);
        },
        [addEvent, onChange],
    );

    return (
        <Stack spacing={1} alignItems="flex-start">
            <FilterLabel id={labelId}>{trans('time-filter', {}, 'student-common')}</FilterLabel>
            <FormControl fullWidth>
                {value.slots.length ? null : (
                    <InputLabel
                        disableAnimation
                        shrink={false}
                        focused={false}
                        sx={{
                            lineHeight: '16px',
                            color: colorsPalette.blackAlpha[70],
                            overflow: 'visible',
                        }}
                    >
                        {label}
                    </InputLabel>
                )}
                <SelectStyled
                    IconComponent={ChevronIcon16}
                    multiple
                    value={value.slots}
                    renderValue={renderedValue}
                    disabled={disabled || loading}
                    labelId={labelId}
                    autoWidth
                    MenuProps={{
                        MenuListProps: {
                            variant: 'menu',
                            sx: { p: 3, maxWidth: 440 },
                        },
                    }}
                    sx={{ height: 48 }}
                >
                    <Stack>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={3}
                        >
                            <Typography variant="h3">{title}</Typography>
                            <ResetTimeButton onChange={onChange} />
                        </Stack>
                        <TimeSlotFilterMenu
                            value={value}
                            onChange={handleOnChange}
                            timeFormat={timeFormat}
                            disabled={disabled || loading}
                            slotsInRow={isMobile ? 3 : 6}
                        />
                    </Stack>
                </SelectStyled>
            </FormControl>
        </Stack>
    );
};

const SelectStyled = styled(MuiSelect)({
    '& fieldset': {
        borderColor: colorsPalette.blackAlpha[20],
    },
});

const formatValue = ({ slots, extendHours }: TimeSlotsFilterValue, timeFormat: TimeFormat) =>
    extendSlots(slots, extendHours)
        ?.map((hour) => formatTimeSlot(TIME_SLOT_VALUE_FORMAT[timeFormat], hour))
        .join(', ');
