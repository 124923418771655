import { useStudentPaymentMethodAddedSuspenseQuery } from '@lingoda/graphql';
import { isSubscriptionFFreeTrial } from '../utils/isSubscriptionFFreeTrial';

export const useIsFFreeTrial = () => {
    const { data } = useStudentPaymentMethodAddedSuspenseQuery({
        fetchPolicy: 'cache-and-network',
    });

    return isSubscriptionFFreeTrial(data?.subscriptions?.currentSubscription);
};
