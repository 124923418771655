import { httpDelete, httpGet, httpPost } from '@lingoda/http';
import { getUrl } from '@lingoda/urls';
import type { Purchase } from '@lingoda/graphql';
import type { ProductSchema, ProductVariantSchema, SubscriptionSchema } from './common';

export interface SubscriptionsResponse {
    data: SubscriptionSchema[];
    included?: {
        products: ProductSchema[];
        productVariants: ProductVariantSchema[];
    };
}

interface SubscriptionResponse {
    data: SubscriptionSchema;
}

export interface CancelReasonsResponse {
    data: {
        [key: string]: string;
    };
}

export interface SubmitCancelReasonsResponse {
    data: null | boolean;
}

export interface SubscriptionApiAttributes {
    productVariantId: number;
    agreements?: string[];
    coupon?: string;
}

export type CancelSubscriptionApiAttributes = {
    mood: string;
    learningProgress: string;
    likes: string;
    dislikes: string;
    reason: string;
};

export type ChangeSubscriptionApiAttributes = {
    purchase: Pick<Purchase, 'id'>;
    agreements?: string[];
};

export type SubmitCancelReasonAttributes = {
    studentCancelReason: string;
};

export const getAllSubscriptionsByStudent = (studentId: number): Promise<SubscriptionsResponse> =>
    httpGet(getUrl('api_students_subscriptions_list', { student_id: studentId }));

export const uncancelSubscription = (studentId: number): Promise<SubscriptionResponse> =>
    httpPost(getUrl('api_subscription_uncancel', { id: studentId }));

export const unpauseSubscription = (studentId: number): Promise<SubscriptionResponse> =>
    httpPost(getUrl('api_subscription_unpause', { id: studentId }));

export const changeSubscription = ({
    purchase,
    agreements,
}: ChangeSubscriptionApiAttributes): Promise<SubscriptionsResponse> =>
    httpPost(getUrl('api_subscription_change'), { purchaseId: purchase.id, agreements });

export const cancelSubscription = (
    id: number,
    payload?: CancelSubscriptionApiAttributes,
): Promise<SubscriptionResponse> => httpPost(getUrl('api_subscription_cancel', { id }), payload);

export const deleteNextSubscription = (): Promise<SubscriptionResponse> =>
    httpDelete(getUrl('api_subscription_delete_next'));
