import { type ReactNode } from 'react';
import { FormattedMessage, trans } from '@lingoda/i18n';
import { Alert, AlertTitle, Box, Link, Typography, colorsPalette, styled } from '@lingoda/ui';
import WarningIcon from '@lingoda/ui/icons/WarningIcon';
import { NEW_CHROME_LINK, NEW_EDGE_LINK } from '../../utils';

type BannerVariant = 'default' | 'outlined';

interface Props {
    severity: 'error' | 'warning';
    variant: BannerVariant;
    learnMoreLink?: string;
    elevation?: number;
    title: 'single-class' | 'multiple-classes' | 'multiple-classes-in-classroom';
    zIndex?: number;
}

export const UseLatestChromiumBanner = ({
    severity,
    learnMoreLink,
    title,
    variant,
    elevation,
    zIndex,
}: Props) => {
    return (
        <AlertStyled
            variant={variant === 'outlined' ? 'outlined' : 'standard'}
            severity={severity}
            elevation={elevation}
            icon={<WarningIcon />}
            bannerVariant={variant}
            fullWidth
            sx={{ zIndex }}
        >
            <AlertTitle sx={{ marginBottom: 0 }}>
                <Content>
                    <Typography variant="subtitle2">
                        {title === 'single-class' && (
                            <FormattedMessage
                                message={trans(
                                    'use-latest-chromium-to-join-your-class',
                                    {},
                                    'classroom-common+intl-icu',
                                )}
                                values={titleVariables}
                            />
                        )}
                        {title === 'multiple-classes' && (
                            <FormattedMessage
                                message={trans(
                                    'use-latest-chromium-to-join-your-classes',
                                    {},
                                    'classroom-common+intl-icu',
                                )}
                                values={titleVariables}
                            />
                        )}
                        {title === 'multiple-classes-in-classroom' && (
                            <FormattedMessage
                                message={trans(
                                    'use-latest-chromium-to-join-your-classes-in-classroom',
                                    {},
                                    'classroom-common+intl-icu',
                                )}
                                values={titleVariables}
                            />
                        )}
                    </Typography>
                    {learnMoreLink && (
                        <LinkStyled
                            variant="subtitle2"
                            to={learnMoreLink}
                            color="inherit"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {trans(
                                'use-latest-chromium.learn-more',
                                {},
                                'classroom-common+intl-icu',
                            )}
                        </LinkStyled>
                    )}
                </Content>
            </AlertTitle>
        </AlertStyled>
    );
};

const AlertStyled = styled(Alert, {
    shouldForwardProp: (prop) => prop !== 'bannerVariant',
})<{ bannerVariant: BannerVariant }>(({ bannerVariant, theme }) => ({
    width: '100%',
    padding: theme.spacing(1.5, 2.5),

    ...(bannerVariant === 'default' && {
        borderRadius: 0,
    }),
}));

const Content = styled(Box)(({ theme }) => ({
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(1),
}));

const LinkStyled = styled(Link)({
    color: colorsPalette.purpleSolid[100],
});

const titleVariables = {
    link1: (chunks: ReactNode[]) => (
        <Link key="link1" color="inherit" colorHover="inherit" target="_blank" to={NEW_CHROME_LINK}>
            {chunks}
        </Link>
    ),
    link2: (chunks: ReactNode[]) => (
        <Link key="link2" color="inherit" colorHover="inherit" target="_blank" to={NEW_EDGE_LINK}>
            {chunks}
        </Link>
    ),
};
