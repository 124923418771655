import { type Ref, forwardRef } from 'react';
import { SvgIcon, type SvgIconProps } from '../../layout/SvgIcon';
import { type PlayAudioIconAnimation, useAnimation } from './animation';

interface PlayAudioIconProps extends SvgIconProps {
    animationRef?: Ref<PlayAudioIconAnimation>;
}

export const PlayAudioIcon24 = forwardRef<SVGSVGElement, PlayAudioIconProps>(
    ({ sx: outerSx, animationRef, ...passProps }, ref) => {
        const { sx: animationSx, forkRef } = useAnimation({ ref, animationRef });

        const sx = { ...animationSx, ...outerSx };

        return (
            <SvgIcon
                viewBox="0 0 24 24"
                width={24}
                height={24}
                sx={sx}
                ref={forkRef}
                {...passProps}
            >
                <path d="M14.171 4.65803C13.9218 4.53346 13.6428 4.48077 13.3653 4.50585C13.0878 4.53093 12.8228 4.63279 12.6 4.80003L6 9.00003H3C2.60218 9.00003 2.22064 9.15806 1.93934 9.43937C1.65804 9.72067 1.5 10.1022 1.5 10.5V13.5C1.5 13.8979 1.65804 14.2794 1.93934 14.5607C2.22064 14.842 2.60218 15 3 15H6L12.6 19.2C12.8229 19.3672 13.0878 19.4689 13.3653 19.494C13.6427 19.519 13.9217 19.4662 14.1708 19.3417C14.42 19.2171 14.6295 19.0256 14.776 18.7886C14.9224 18.5517 15 18.2786 15 18V6.00003C15.0001 5.72142 14.9226 5.44829 14.7761 5.21126C14.6297 4.97423 14.4202 4.78266 14.171 4.65803Z" />
                <path
                    className="outer"
                    d="M21.0596 8.01773C21.4088 7.79493 21.8725 7.8974 22.0953 8.24659C22.8487 9.42751 23.249 10.7992 23.249 12.2C23.249 13.6008 22.8487 14.9725 22.0953 16.1534C21.8725 16.5026 21.4088 16.6051 21.0596 16.3823C20.7104 16.1595 20.6079 15.6958 20.8307 15.3466C21.4304 14.4067 21.749 13.3149 21.749 12.2C21.749 11.0851 21.4304 9.99333 20.8307 9.05341C20.6079 8.70421 20.7104 8.24053 21.0596 8.01773Z"
                />
                <path
                    className="inner"
                    d="M18.0891 9.45404C18.4106 9.19286 18.883 9.24175 19.1441 9.56325C19.7586 10.3195 20.0939 11.2642 20.0939 12.2387C20.0939 13.2131 19.7586 14.1578 19.1441 14.9141C18.883 15.2356 18.4106 15.2845 18.0891 15.0233C17.7676 14.7621 17.7187 14.2897 17.9799 13.9682C18.3771 13.4793 18.5939 12.8686 18.5939 12.2387C18.5939 11.6087 18.3771 10.998 17.9799 10.5091C17.7187 10.1876 17.7676 9.71522 18.0891 9.45404Z"
                />
            </SvgIcon>
        );
    },
);
PlayAudioIcon24.displayName = 'PlayAudioIcon24';
