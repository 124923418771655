import {
    Box,
    LingodaClassIcon,
    Paper,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    styled,
    tabA11yProps,
    tabPanelA11yProps,
} from '@lingoda/ui';
import { ClassType } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';
import { IndividualClassBookingForm } from '../individual/IndividualClassBookingForm';
import { type WidgetClassType } from '../types';
import { GroupClassBookingDesktop } from './GroupClassBookingDesktop';

interface Props {
    classType: WidgetClassType;
    onClassTypeChange: (value: WidgetClassType) => void;
    moduleId: number;
    lessonId: number;
}

export const BookingWidgetDesktop = ({
    classType,
    onClassTypeChange,
    moduleId,
    lessonId,
}: Props) => {
    const tabs = [
        {
            id: ClassType.Group,
            label: trans('group-class', {}, 'student-common'),
        },
        {
            id: ClassType.Individual,
            label: trans('individual-class', {}, 'student-common'),
        },
    ];

    const routes = {
        [ClassType.Group]: (
            <Box pt={1} role="tabpanel" {...tabPanelA11yProps(TABS_A11Y, ClassType.Group)}>
                <GroupClassBookingDesktop moduleId={moduleId} lessonId={lessonId} />
            </Box>
        ),
        [ClassType.Individual]: (
            <Box pt={3} role="tabpanel" {...tabPanelA11yProps(TABS_A11Y, ClassType.Individual)}>
                <IndividualClassBookingForm moduleId={moduleId} lessonId={lessonId} />
            </Box>
        ),
    };

    return (
        <WrapperBox data-cy="Booking widget" variant="outlined">
            <ToggleButtonGroup
                size="small"
                value={classType}
                onChange={(_, type) => onClassTypeChange(type as WidgetClassType)}
                exclusive
                fullWidth
                role="tablist"
            >
                {tabs.map(({ id, label }) => (
                    <ToggleButton
                        role="tab"
                        size="medium"
                        value={id}
                        key={id}
                        aria-selected={id === classType}
                        {...tabA11yProps(TABS_A11Y, id)}
                    >
                        <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                            <LingodaClassIcon type={id} size={16} />
                            <Typography
                                component="span"
                                variant="body1"
                                sx={{ wordBreak: 'break-word' }}
                            >
                                {label}
                            </Typography>
                        </Box>
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            {routes[classType]}
        </WrapperBox>
    );
};

const WrapperBox = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
}));

const TABS_A11Y = 'booking-type';
