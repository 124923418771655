import { useMemo } from 'react';
import { studentAppointmentsSelector } from '@lingoda/classes';
import {
    createDate,
    formatTimeRemaining,
    getUnixTime,
    isAfter,
    isBefore,
    isBetween,
    isSameDay,
    toDate,
} from '@lingoda/dates';
import { useSelector } from '@lingoda/hooks';

export const useStudentAppointment = () => {
    const appointments = useSelector(studentAppointmentsSelector);

    return useMemo(() => {
        const now = createDate();

        const ongoingAppointment = appointments.find(
            (appointment) =>
                appointment.status === 'active' &&
                isBetween(toDate(appointment.startDate), toDate(appointment.endDate), now),
        );

        const isInSession = !!ongoingAppointment;

        const futureAppointments = appointments.filter(
            (appointment) => appointment.status === 'active' && isAfter(appointment.startDate, now),
        );

        const pastAppointments = appointments.filter(
            (appointment) =>
                appointment.status === 'attended' && isBefore(appointment.endDate, now),
        );

        const nextAppointment =
            ongoingAppointment ||
            futureAppointments.sort(
                (a, b) => getUnixTime(toDate(a.startDate)) - getUnixTime(toDate(b.startDate)),
            )[0] ||
            null;

        const previousAppointment =
            pastAppointments.sort(
                (a, b) => getUnixTime(toDate(b.endDate)) - getUnixTime(toDate(a.endDate)),
            )[0] || null;

        const isNextClassToday = nextAppointment
            ? isSameDay(toDate(nextAppointment.startDate), now)
            : false;

        const timeRemaining = isNextClassToday
            ? formatTimeRemaining(toDate(nextAppointment.startDate), { now })
            : null;

        return {
            nextAppointment,
            previousAppointment,
            isInSession,
            isNextClassToday,
            timeRemaining,
        };
    }, [appointments]);
};
