import { ChecklistItemSlug } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';

type ChecklistTranslations = Partial<
    Record<
        ChecklistItemSlug,
        {
            title?: string;
            description?: string;
            cta?: string;
        }
    >
>;

export const progressStepsTranslations: ChecklistTranslations = {
    [ChecklistItemSlug.BookFirstClass]: {
        description: trans('checklist-progress-book-first-class', {}, 'student-onboarding'),
    },
    [ChecklistItemSlug.WarmupBeforeClass]: {
        description: trans('checklist-progress-warmup-before-class', {}, 'student-onboarding'),
    },
    [ChecklistItemSlug.AttendClass]: {
        description: trans('checklist-progress-attend-class', {}, 'student-onboarding'),
    },
    [ChecklistItemSlug.ReviewAfterClass]: {
        description: trans('checklist-progress-review-after-class', {}, 'student-onboarding'),
    },
    [ChecklistItemSlug.Practice]: {
        description: trans('checklist-progress-practice', {}, 'student-onboarding'),
    },
};

export const checklistStepsTranslations: ChecklistTranslations = {
    [ChecklistItemSlug.BookFirstClass]: {
        title: trans('checklist-book-first-class-title', {}, 'student-onboarding'),
    },
    [ChecklistItemSlug.WarmupBeforeClass]: {
        title: trans('checklist-warmup-before-class-title', {}, 'student-onboarding'),
    },
    [ChecklistItemSlug.AttendClass]: {
        title: trans('checklist-attend-class-title', {}, 'student-onboarding'),
    },
    [ChecklistItemSlug.ReviewAfterClass]: {
        title: trans('checklist-review-after-class-title', {}, 'student-onboarding'),
    },
    [ChecklistItemSlug.Practice]: {
        title: trans('checklist-practice-title', {}, 'student-onboarding'),
    },
};

export const checklistActionsTranslations: ChecklistTranslations = {
    [ChecklistItemSlug.BookFirstClass]: {
        title: trans('book-your-first-classes', {}, 'student-onboarding'),
        description: trans('choose-time-suits-you', {}, 'student-onboarding'),
        cta: trans('checklist-book-first-class-title', {}, 'student-onboarding'),
    },
    [ChecklistItemSlug.WarmupBeforeClass]: {
        title: trans('checklist-warmup-before-class-title', {}, 'student-onboarding'),
        description: trans('check-out-the-material-and-the-vocabulary', {}, 'student-onboarding'),
        cta: trans('warm-up', {}, 'student-onboarding'),
    },
    [ChecklistItemSlug.AttendClass]: {
        title: trans('checklist-attend-class-title', {}, 'student-onboarding'),
        description: trans('join-class-and-enjoy-learning', {}, 'student-onboarding'),
        cta: trans('join-class', {}, 'student-booking'),
    },
    [ChecklistItemSlug.ReviewAfterClass]: {
        title: trans('checklist-review-after-class-title', {}, 'student-onboarding'),
        description: trans('rate-the-class-and-view-feedback', {}, 'student-onboarding'),
        cta: trans('review-class', {}, 'student-onboarding'),
    },
    [ChecklistItemSlug.Practice]: {
        title: trans('checklist-practice-title', {}, 'student-onboarding'),
        description: trans('start-an-exercise-or-a-flashcard-deck', {}, 'student-onboarding'),
        cta: trans('start-practicing', {}, 'student-onboarding'),
    },
};
