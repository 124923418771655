import { useMemo } from 'react';
import uniqueId from 'lodash/uniqueId';
import { trans } from '@lingoda/i18n';
import { studentFlashcardsPath } from '@lingoda/router';
import { Box, ProgressBarWithLabel, Stack, Typography, useUiEvent } from '@lingoda/ui';
import { getFeatures } from '@lingoda/feature-flags';
import { type FlashCardsLearningUnitFragment } from '@lingoda/graphql';
import { FlashcardIcon24 } from '@lingoda/ui/icons/lingodaIcons';
import { actionClick } from '@lingoda/analytics';
import { ExerciseListItemLink } from './ExerciseListItemLink';

interface Props {
    learningUnit: FlashCardsLearningUnitFragment;
}

export const FlashcardsContent = ({ learningUnit }: Props) => {
    const addEvent = useUiEvent();
    const completedVocabularyItemsCount = useMemo(
        () => learningUnit.vocabularyItems.filter((item) => item.flashcardIsCompleted).length,
        [learningUnit.vocabularyItems],
    );
    const vocabularyItemsCount = learningUnit.vocabularyItems.length;
    const flashcardsProgressPercentage =
        (completedVocabularyItemsCount / vocabularyItemsCount) * 100;

    const a11y = useMemo(
        () => ({
            label: uniqueId('label-'),
        }),
        [],
    );

    const handleClick = () => {
        void addEvent(actionClick('GoToFlashcards'), {
            metadata: {
                chapter: learningUnit.chapter,
                chapterName: learningUnit.name,
                moduleName: learningUnit.module?.name,
                currentUrl: window.location.href,
                userFeatures: getFeatures(),
            },
        });
    };

    return (
        <ExerciseListItemLink
            padding={2}
            to={studentFlashcardsPath({ learningUnitId: learningUnit.id })}
            onClick={handleClick}
            aria-labelledby={a11y.label}
        >
            <Stack flexDirection="row" gap={2} alignItems="center">
                <FlashcardIcon24 />
                <Box flex={1}>
                    <Typography
                        id={a11y.label}
                        variant="subtitle1"
                        sx={{ minHeight: (theme) => theme.spacing(3) }}
                    >
                        {trans('flashcards', {}, 'student-common')}
                    </Typography>
                    <ProgressBarWithLabel value={flashcardsProgressPercentage} size="medium" />
                </Box>
            </Stack>
        </ExerciseListItemLink>
    );
};
