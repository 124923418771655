import {
    ToggleButtonGroup,
    type ToggleButtonGroupProps,
} from '@lingoda/ui/buttons/ToggleButtonGroup';
import FormControl from '@lingoda/ui/fields/FormControl';
import { CustomErrorMessage, type FieldAttributes, useField } from '../utils';

type ToggleButtonGroupFieldProps = InheritedProps & FieldAttributes<unknown>;

type InheritedProps = Omit<ToggleButtonGroupProps, 'value' | 'onChange'>;

export const ToggleButtonGroupField = ({
    name,
    required,
    ...passProps
}: ToggleButtonGroupFieldProps) => {
    const [input, meta, field] = useField(name);

    const handleChange: ToggleButtonGroupProps['onChange'] = (_, value) => {
        void field.setValue(value);
    };

    return (
        <FormControl
            error={meta.touched && !!meta.error}
            required={required}
            fullWidth={passProps.fullWidth}
        >
            <ToggleButtonGroup {...passProps} {...input} onChange={handleChange} />
            <CustomErrorMessage meta={meta} />
        </FormControl>
    );
};
