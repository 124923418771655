import { Box, BoxPerf, Button, Paper, TrackingContext, Typography, useUiEvent } from '@lingoda/ui';
import { ArrowRightIcon16, CloseIcon16 } from '@lingoda/ui/icons/lingodaIcons';
import { colorsPalette, styled } from '@lingoda/ui/theme';
import { trans } from '@lingoda/i18n';
import { useDispatch } from '@lingoda/hooks';
import { goTo, studentBookingPath } from '@lingoda/router';
import { updatePreferences } from '@lingoda/students';
import { useFilterOrientationClasses } from '@lingoda/booking';
import { actionClick } from '@lingoda/analytics';
import { useStudentSectionTrans } from './useStudentSectionTrans';
import progressLine from './progress-line.png';

export interface Props {
    isLong?: boolean;
}

const OrientationClassBannerComponent = ({ isLong = false }: Props) => {
    const overviewAndLearnTranslation = useStudentSectionTrans(
        'student-overview-program-and-learn',
        'student-onboarding',
    );

    const dispatch = useDispatch();
    const addEvent = useUiEvent();
    const [setOrientationFilter] = useFilterOrientationClasses();

    const handleCloseBanner = () =>
        dispatch(updatePreferences({ showOrientationClassBanner: false }));

    function handleClickFindTime() {
        void addEvent(actionClick('Find A Time'));
        goTo(studentBookingPath());
        setOrientationFilter();
    }

    return (
        <PaperTile
            role="button"
            variant="outlined"
            tabIndex={0}
            isLong={isLong}
            data-cy="Orientation Class Banner"
        >
            {isLong && <BackgroundLine />}
            <Box display="flex" className="mb-1" alignItems="center">
                <Typography
                    variant="subtitle2"
                    color={colorsPalette.whiteSolid[100]}
                    textTransform="uppercase"
                >
                    {trans('get-started', {}, 'student-onboarding')}
                </Typography>
                {isLong && (
                    <CloseButton
                        variant="text"
                        inverted
                        size="medium"
                        disableRipple
                        onClick={handleCloseBanner}
                    >
                        <CloseIcon16 fontSize="small" />
                    </CloseButton>
                )}
            </Box>
            <BoxPerf className="mb-1">
                <Typography variant="h3" color={colorsPalette.whiteSolid[100]}>
                    {trans('start-with-orientation-class', {}, 'student-onboarding')}
                </Typography>
            </BoxPerf>
            <BoxPerf>
                <Typography variant="body2" color={colorsPalette.whiteSolid[100]} maxWidth={524}>
                    {overviewAndLearnTranslation}
                </Typography>
            </BoxPerf>
            <Box className="mt-3" display="flex">
                <LinkButton
                    color="primary"
                    variant="contained"
                    inverted
                    onClick={handleClickFindTime}
                    isLong={isLong}
                >
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Typography fontWeight={600} variant="body2" mr={1}>
                            {trans('find-time', {}, 'student-onboarding')}
                        </Typography>{' '}
                        <ArrowRightIcon16 />
                    </Box>
                </LinkButton>
                {!isLong && (
                    <TransparentButton variant="text" inverted onClick={handleCloseBanner}>
                        {trans('dismiss', {}, 'student-onboarding')}
                    </TransparentButton>
                )}
            </Box>
        </PaperTile>
    );
};

const LinkButton = styled(Button)<Props>(({ theme, isLong }) => ({
    padding: theme.spacing(1),
    flexShrink: 0,
    marginLeft: isLong ? 'auto' : 0,
}));

const TransparentButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1),
}));

const PaperTile = styled(Paper)<Props>(({ theme, isLong }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 1,
    minHeight: isLong ? 209 : 300,
    color: colorsPalette.whiteSolid[100],
    background: theme.palette.gradients.primaryToPurple,
    border: 0,
    padding: theme.spacing(3),
    marginLeft: isLong ? theme.spacing(1) : 0,
    marginRight: isLong ? theme.spacing(1) : 0,
}));

const BackgroundLine = styled(BoxPerf)(() => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    right: 0,
    background: `url(${progressLine})`,
    backgroundSize: 'cover',
    backgroundPosition: 'right',
    zIndex: -1,
}));

const CloseButton = styled(Button)(({ theme }) => ({
    transform: `translateX(-${theme.spacing(4)}px)`,
    borderRadius: '50%',
    width: 24,
    height: 24,
    padding: 0,
    marginLeft: 'auto',
}));

const OrientationClassBannerWithContext = (props: Props) => (
    <TrackingContext section="Orientation Class Banner">
        <OrientationClassBannerComponent {...props} />
    </TrackingContext>
);

export const OrientationClassBanner = OrientationClassBannerWithContext;
