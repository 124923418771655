import { Box, Button, DangerousHtml, Img, PageContentFrame, Typography } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { contactPath, goTo } from '@lingoda/router';
import redflag from '../images/redflag.png';

interface Props {
    error: string;
}

export const PackagePurchaseErrorLayout = ({ error }: Props) => {
    const handleCTA = () => {
        goTo(contactPath());
    };

    return (
        <PageContentFrame>
            <Typography gutterBottom variant="h2" align="center" mt={5}>
                {trans('change-subscription-failed-header', {}, 'errors')}
            </Typography>
            <Box mb={3} textAlign="center">
                <Img src={redflag} width="60" />
            </Box>
            <Typography align="center" variant="body1" gutterBottom>
                <DangerousHtml>{error}</DangerousHtml>
            </Typography>
            <Typography align="center" variant="body1" mb={5}>
                {trans('contact-support-additional-help', {}, 'public-payment')}
            </Typography>
            <Box textAlign="center">
                <Button color="primary" variant="contained" onClick={handleCTA}>
                    {trans('btn-go-to-contact-support', {}, 'student-common')}
                </Button>
            </Box>
        </PageContentFrame>
    );
};
