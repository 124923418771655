import { trans } from '@lingoda/i18n';
import { Button, type ButtonProps, GradientButton, type GradientButtonProps } from '@lingoda/ui';

export const CheckoutButton = (props: GradientButtonProps) => (
    <GradientButton {...props} data-cy="proceed-to-checkout-btn">
        {trans('proceed-to-checkout', {}, 'student-store')}
    </GradientButton>
);

export const CheckoutTrialButton = (props: GradientButtonProps) => (
    <GradientButton {...props}>
        {trans('proceed-to-checkout-with-trial', {}, 'student-store')}
    </GradientButton>
);

export const CheckoutSkipTrialButton = (props: ButtonProps) => (
    <Button {...props} color="primary" variant="outlined">
        {trans('proceed-to-checkout-skipping-trial', {}, 'student-store')}
    </Button>
);
