import type { ClassId } from '@lingoda/classes';
import { createAction, makeActionTrackable } from '@lingoda/core';
import type { Date } from '@lingoda/dates';
import type { ClassType } from '@lingoda/graphql';
import type { LessonId } from '@lingoda/lessons';

type CommonParams = {
    type: ClassType;
    startDate: Date;
};

export type ExistingClassBookParams = CommonParams & {
    classSource: 'existing';
    id: ClassId;
    stats?: string;
    isForNewClassroom: boolean;
};

export type GeneratedClassBookParams = CommonParams & {
    classSource: 'generated';
    lessonId: LessonId;
    isForNewClassroom: boolean;
};

export type RequestedClassBookParams = CommonParams & {
    classSource: 'requested';
    topic?: string;
    lessonId?: LessonId;
    isRequestedByUser: true;
    // no isForNewClassroom: boolean; because at this point we don't know if it's a new classroom or not
};

export type BookClassActionParams = (
    | ExistingClassBookParams
    | GeneratedClassBookParams
    | RequestedClassBookParams
) & {
    overrideOneClickBooking?: boolean;
    availableSeats?: number;
};

export const bookClass = makeActionTrackable(
    createAction<BookClassActionParams>('booking/BOOK_CLASS'),
);
