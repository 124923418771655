import type { SectionCurriculum } from '@lingoda/graphql';
import { ClassType, Currency, PurchaseType, RecurringSpecialType } from '@lingoda/graphql';
import type { CurriculumId } from '@lingoda/sections';
import type { FiltersOptions, FiltersState, PurchasePackage } from '../../types';

export const filtersStateDefaults = {
    currency: Currency.Eur,
    classType: ClassType.Group,
    planType: PurchaseType.Monthly,
} as const;

export const filtersOptionsDefault: FiltersOptions = {
    classTypes: [],
    sectionCurriculums: [],
    planTypes: [],
};

export const getFilteredPackages = (
    packages: ReadonlyArray<PurchasePackage>,
    filtersState: FiltersState | null,
): ReadonlyArray<PurchasePackage> => {
    if (!packages || !filtersState) {
        return [];
    }

    const { curriculumSlug, planType, classType } = filtersState;

    return packages.filter((pkg) => {
        if (
            pkg.type !== planType ||
            (pkg.recurringSpecialType !== RecurringSpecialType.LingodaLite &&
                classType !== pkg.classType)
        ) {
            return false;
        }

        return !curriculumSlug || pkg.sectionCurriculum?.slug === curriculumSlug;
    });
};

export const extractPackageFilterValues = (packages: ReadonlyArray<PurchasePackage>) => {
    const options = {
        classTypes: new Set<ClassType>(),
        sectionCurriculums: new Set<SectionCurriculum>(),
        planTypes: new Set<PurchaseType>(),
    };

    const curriculumSlugs = new Set<string>();

    packages.forEach((pkg) => {
        options.classTypes.add(pkg.classType);
        options.planTypes.add(pkg.type);

        if (pkg.sectionCurriculum) {
            const { slug } = pkg.sectionCurriculum;
            if (!curriculumSlugs.has(slug)) {
                curriculumSlugs.add(slug);
                options.sectionCurriculums.add(pkg.sectionCurriculum);
            }
        }
    });

    const filtersOptions: FiltersOptions = {
        classTypes: Array.from(options.classTypes.values()),
        sectionCurriculums: Array.from(options.sectionCurriculums.values()),
        planTypes: Array.from(options.planTypes.values()),
    };

    return {
        filtersOptions,
        curriculumSlugsOptions: Array.from(curriculumSlugs.values()),
    };
};

export const getSectionCurriculumFallback = (
    options: SectionCurriculum[],
    curriculumId?: NonNullable<CurriculumId>,
): string | undefined =>
    options.find((item) => item.curriculum.id === curriculumId)?.slug || options[0]?.slug;

export const getRecommendedUpgradePackage = (
    packages: ReadonlyArray<PurchasePackage>,
    specialType?: string,
) => {
    if (specialType) {
        const specialPackage = packages.find((pkg) => pkg.recurringSpecialType === specialType);

        if (specialPackage) {
            return specialPackage;
        }
    }

    const currentPkgIndex = packages.findIndex((pkg) => pkg.isCurrent);
    if (currentPkgIndex === -1) {
        return packages[0];
    }
    if (currentPkgIndex === packages.length - 1) {
        return packages[currentPkgIndex];
    }

    // Since the list is sorted, the next package is the immediate upgrade for the current user subscription
    return packages[currentPkgIndex + 1];
};
