// @TODO: split this into small files by feature
import { lazy } from 'react';
import {
    RouteAccess,
    type RouteConfigExtended,
    TabValue,
    accountClosedPath,
    bookingPreviewPath,
    cancelCourseChangePath,
    cancelCoursePausePath,
    canceledCoursePath,
    changeLearningLanguageSuccessPath,
    changeSubscriptionFailedPath,
    confirmCancelPath,
    optionalArg,
    productOrderPath,
    productPurchasePath,
    refusedPaymentPath,
    studentAchievementsPath,
    studentBookingPath,
    studentChangedPaymentMethodPath,
    studentClassDetailsPath,
    studentClassesPath,
    studentCourseOverviewPath,
    studentCreditsAddedPath,
    studentExercisesPath,
    studentFlashcardsPath,
    studentGrammarItemPath,
    studentGrammarPath,
    studentHomePath,
    studentHomeworkPath,
    studentLemonadePath,
    studentLessonDetailsPath,
    studentPracticePath,
    studentPreClassPath,
    studentProfilePath,
    studentQuizPath,
    studentReactivateCoursePath,
    studentReactivatedPath,
    studentSettingsPath,
    studentStorePath,
    studentTeacherPath,
    studentVocabularyPath,
} from '@lingoda/router';
import { colorsPalette } from '@lingoda/ui';
import { quizRoutes } from '@lingoda/quiz';
import { isNewCoursePageEnabled } from '@lingoda/feature-flags';
import { challengesRoutes } from '@lingoda/student-challenges';
import { practiceSequenceRoutes } from '@lingoda/student-practice-sequence';

const MyClasses = lazy(() => import('@lingoda/student-frontend/entrypoint/pages/MyClasses'));
const Booking = lazy(() => import('../components/pages/Booking'));
const BookingPreview = lazy(() => import('@lingoda/student-frontend/preview/pages/BookingPreview'));
const ClassDetails = lazy(() => import('@lingoda/student-frontend/classes/pages/ClassDetails'));
const LessonDetails = lazy(() => import('@lingoda/student-frontend/classes/pages/LessonDetails'));
const Settings = lazy(() => import('../components/pages/Profile'));
const StudentProfile = lazy(
    () => import('@lingoda/student-frontend/profile/pages/StudentProfile/StudentProfile'),
);
const CourseOverview = lazy(() => import('../components/pages/CourseOverview'));
const PreClassChecklist = lazy(
    () => import('@lingoda/student-frontend/classes/pages/PreClassChecklist'),
);
const Teacher = lazy(() => import('@lingoda/student-frontend/teachers/pages/TeacherProfile'));
const AccountClosed = lazy(() => import('../components/pages/store/AccountClosed'));
const CanceledCourse = lazy(() => import('../components/pages/store/CanceledCourse'));
const RefusedPayment = lazy(() => import('../components/pages/store/RefusedPayment'));
const ReactivateCourse = lazy(() => import('../components/pages/store/ReactivateCourse'));
const Reactivated = lazy(() => import('../components/pages/store/Reactivated'));
const CreditsAdded = lazy(() => import('../components/pages/store/CreditsAdded'));
const ChangedPaymentMethod = lazy(() => import('../components/pages/store/ChangedPaymentMethod'));
const ConfirmCancel = lazy(() => import('../components/pages/store/ConfirmCancel'));
const CancelCourseChange = lazy(() => import('../components/pages/store/CancelCourseChange'));
const CancelCoursePause = lazy(() => import('../components/pages/store/CancelCoursePause'));
const Store = lazy(() => import('../components/pages/store/Store'));
const ChangeSubscriptionFailed = lazy(
    () => import('../components/pages/store/ChangeSubscriptionFailed'),
);
const ProductPurchase = lazy(() => import('../components/pages/store/ProductPurchase'));
const Practice = lazy(() => import('../components/pages/Practice/PracticePage'));
const Exercise = lazy(() => import('../components/pages/Exercise'));
const Exercises = lazy(() => import('../components/pages/Exercises'));
const Flashcards = lazy(
    () => import('@lingoda/student-frontend/practice/flashcards/FlashcardsPage'),
);
const LessonVocabulary = lazy(() => import('../components/pages/LessonVocabulary'));
const LessonGrammarList = lazy(() => import('../components/pages/LessonGrammar/LessonGrammarList'));
const LessonGrammarItem = lazy(() => import('../components/pages/LessonGrammar/LessonGrammarItem'));
const Today = lazy(() => import('@lingoda/student-frontend/entrypoint/pages/Today'));
const MyAchievements = lazy(
    () => import('@lingoda/student-frontend/milestones/pages/MyAchievements'),
);
const ChangeLearningLanguageSuccess = lazy(
    () => import('../components/pages/store/ChangeLearningLanguageSuccess'),
);
const LemonadePage = lazy(() => import('@lingoda/student-frontend/practice/lemonade/route'));

const student: RouteConfigExtended[] = [
    {
        exact: true,
        component: ProductPurchase,
        path: productOrderPath.raw,
        config: {
            access: RouteAccess.Public,
            hideFooter: true,
            hideNavigation: true,
            tab: TabValue.Store,
        },
        label: 'Purchase',
    },
    {
        exact: true,
        component: ProductPurchase,
        path: productPurchasePath.raw,
        config: {
            hideFooter: true,
            access: RouteAccess.Public,
            hideNavigation: true,
            tab: TabValue.Store,
        },
        label: 'Purchase',
    },
    {
        exact: true,
        component: Today,
        path: studentHomePath.raw,
        label: 'Home',
        config: {
            tab: TabValue.Home,
        },
    },
    {
        exact: true,
        component: MyAchievements,
        path: studentAchievementsPath.raw,
        label: 'MyAchievements',
        config: {
            tab: TabValue.Home,
        },
    },
    {
        exact: true,
        component: MyClasses,
        path: studentClassesPath.raw,
        label: 'MyClasses',
        config: {
            tab: TabValue.MyClasses,
            hideFooter: true,
            pageNotScrollable: true,
        },
    },
    {
        exact: true,
        component: Booking,
        path: studentBookingPath.raw,
        label: 'Booking',
        config: {
            tab: TabValue.BookClasses,
            hideFooter: true,
            pageNotScrollable: true,
        },
    },
    {
        exact: true,
        component: BookingPreview,
        path: bookingPreviewPath.raw,
        label: 'BookingPreview',
        config: {
            headerIsPortal: true,
            hideFooter: true,
            pageNotScrollable: true,
            tab: TabValue.BookClasses,
        },
    },
    {
        exact: true,
        component: ClassDetails,
        path: studentClassDetailsPath.raw,
        label: 'ClassDetails',
        config: {
            tab: TabValue.MyClasses,
            backgroundColor: colorsPalette.whiteSolid[100],
            hideMobileBottomNavbar: true,
            hideHeader: 'phablet',
        },
    },
    {
        exact: true,
        component: LessonDetails,
        path: studentLessonDetailsPath.raw,
        label: 'LessonDetails',
        config: {
            tab: TabValue.CourseOverview,
            backgroundColor: colorsPalette.whiteSolid[100],
            hideMobileBottomNavbar: true,
            hideHeader: 'phablet',
        },
    },
    {
        exact: true,
        component: StudentProfile,
        path: studentProfilePath.raw,
        label: 'Student Profile',
        config: {
            tab: TabValue.Settings,
            backgroundColor: colorsPalette.whiteSolid[100],
        },
    },
    {
        exact: true,
        component: Settings,
        path: optionalArg(studentSettingsPath.raw, 'page'),
        label: 'Profile', // TODO: change label to 'Settings' when StudentProfile page released
        config: {
            tab: TabValue.Settings,
        },
    },
    {
        exact: true,
        component: CourseOverview,
        path: optionalArg(studentCourseOverviewPath.raw, 'moduleName'),
        label: 'CourseOverview',
        config: {
            tab: TabValue.CourseOverview,
            hideFooter: () => isNewCoursePageEnabled(),
            pageNotScrollable: () => isNewCoursePageEnabled(),
        },
    },
    {
        exact: true,
        component: LessonVocabulary,
        path: studentVocabularyPath.raw,
        label: 'LessonVocabulary',
        config: {
            tab: TabValue.CourseOverview,
            hideMobileBottomNavbar: true,
        },
    },
    {
        exact: true,
        component: LessonGrammarList,
        path: studentGrammarPath.raw,
        label: 'LessonGrammarList',
        config: {
            tab: TabValue.CourseOverview,
            hideMobileBottomNavbar: true,
        },
    },
    {
        exact: true,
        component: LessonGrammarItem,
        path: studentGrammarItemPath.raw,
        label: 'LessonGrammarItem',
        config: {
            tab: TabValue.CourseOverview,
            hideMobileBottomNavbar: true,
        },
    },
    {
        exact: true,
        component: PreClassChecklist,
        path: studentPreClassPath.raw,
        label: 'PreClassChecklist',
        config: {
            tab: TabValue.MyClasses,
        },
    },
    {
        exact: true,
        component: Teacher,
        path: studentTeacherPath.raw,
        label: 'TeacherDetails',
        config: {
            tab: TabValue.MyClasses,
        },
    },
    {
        exact: true,
        component: AccountClosed,
        path: accountClosedPath.raw,
        label: 'AccountClosed',
        config: undefined,
    },
    {
        exact: true,
        component: CanceledCourse,
        path: canceledCoursePath.raw,
        label: 'CanceledCourse',
        config: undefined,
    },
    {
        exact: true,
        component: RefusedPayment,
        path: refusedPaymentPath.raw,
        label: 'RefusedPayment',
        config: {
            tab: TabValue.Store,
        },
    },
    {
        exact: true,
        component: ReactivateCourse,
        path: studentReactivateCoursePath.raw,
        label: 'ReactivateCourse',
        config: {
            tab: TabValue.Store,
        },
    },
    {
        exact: true,
        component: Reactivated,
        path: studentReactivatedPath.raw,
        label: 'Reactivated',
        config: undefined,
    },
    {
        exact: true,
        component: CreditsAdded,
        path: studentCreditsAddedPath.raw,
        label: 'CreditsAdded',
        config: {
            tab: TabValue.Store,
        },
    },
    {
        exact: true,
        component: ChangedPaymentMethod,
        path: studentChangedPaymentMethodPath.raw,
        label: 'ChangedPaymentMethod',
        config: undefined,
    },
    {
        exact: true,
        component: ConfirmCancel,
        path: confirmCancelPath.raw,
        label: 'ConfirmCancel',
        config: undefined,
    },
    {
        exact: true,
        component: CancelCourseChange,
        path: cancelCourseChangePath.raw,
        label: 'CancelCourseChange',
        config: undefined,
    },
    {
        exact: true,
        component: CancelCoursePause,
        path: cancelCoursePausePath.raw,
        label: 'CancelCoursePause',
        config: {
            tab: TabValue.Store,
        },
    },
    {
        exact: true,
        component: Store,
        path: studentStorePath.raw,
        label: 'Store',
        config: {
            tab: TabValue.Store,
        },
    },
    {
        exact: true,
        component: ChangeSubscriptionFailed,
        path: changeSubscriptionFailedPath.raw,
        label: 'ChangeSubscriptionFailed',
        config: undefined,
    },
    {
        exact: true,
        component: Practice,
        path: studentPracticePath.raw,
        label: 'Practice',
        config: {
            tab: TabValue.Practice,
        },
    },
    {
        exact: true,
        component: Exercise,
        path: studentQuizPath.raw,
        label: 'Quiz',
        config: {
            hideHeader: true,
            hideFooter: true,
            hideMobileBottomNavbar: true,
            tab: TabValue.Practice,
        },
    },
    {
        exact: true,
        component: Exercise,
        path: studentHomeworkPath.raw,
        label: 'Homework',
        config: {
            hideHeader: true,
            hideFooter: true,
            hideDixa: true,
            tab: TabValue.Practice,
            hideMobileBottomNavbar: true,
        },
    },
    {
        exact: true,
        component: Exercises,
        path: studentExercisesPath.raw,
        label: 'Exercises',
        config: {
            hideHeader: true,
            hideFooter: true,
            tab: TabValue.Practice,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: ChangeLearningLanguageSuccess,
        path: changeLearningLanguageSuccessPath.raw,
        label: 'ChangedLearningLanguage',
        config: undefined,
    },
    {
        exact: true,
        component: Flashcards,
        path: studentFlashcardsPath.raw,
        label: 'Flashcards',
        config: {
            hideHeader: true,
            hideFooter: true,
            hideMobileBottomNavbar: true,
            hideDixa: true,
            tab: TabValue.CourseOverview,
            hideNotifications: true,
            backgroundColor: colorsPalette.whiteSolid[100],
        },
    },
    {
        exact: true,
        component: LemonadePage,
        path: studentLemonadePath.raw,
        label: 'Lemonade',
        config: {
            hideHeader: true,
            hideFooter: true,
            hideDixa: true,
            hideMobileBottomNavbar: true,
            tab: TabValue.Practice,
            hideNotifications: true,
        },
    },
    ...quizRoutes,
    ...practiceSequenceRoutes,
    ...challengesRoutes,
];

export default student;
