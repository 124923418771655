import { httpGet, httpPost } from '@lingoda/http';
import { getUrl } from '@lingoda/urls';
import type {
    AppointmentSchema,
    ClassLeadTimeSchema,
    ClassSchema,
    LearningUnitSchema,
    LessonSchema,
    ModuleSchema,
    StudentSchema,
    TeacherSchema,
} from './common';

export interface IncludedResponseAttributes {
    learningUnits?: LearningUnitSchema[];
    lessons?: LessonSchema[];
    modules?: ModuleSchema[];
    teachers?: TeacherSchema[];
    students?: StudentSchema[];
}

export interface ClassesClassSchema extends ClassSchema {
    currentStudentAppointment?: AppointmentSchema;
    id: string;
    tempId?: string;
}

type CreateClassAttributes = {
    lessonId?: number;
    startDate: string;
    type: string;
    isRequestedByUser?: boolean;
    topic?: string;
    stats?: string;
};

interface ClassAppointmentResponse {
    data: ClassesClassSchema;
    included: IncludedResponseAttributes;
}

export const createClass = (
    studentId: number,
    classData: CreateClassAttributes,
): Promise<ClassAppointmentResponse> =>
    httpPost(getUrl('api_students_classes_create', { student_id: studentId }), classData);

export const getClassLeadTime = (): Promise<ClassLeadTimeSchema> =>
    httpGet(getUrl('api_start_date_parameter'));
