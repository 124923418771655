import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const CheckmarkIcon24 = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.4912 6.35329C19.8042 6.62457 19.838 7.09824 19.5668 7.41125L10.3755 18.0166L5.47261 13.1676C5.1781 12.8763 5.17548 12.4015 5.46675 12.1069C5.75802 11.8124 6.23289 11.8098 6.52739 12.1011L10.2912 15.8235L18.4332 6.42886C18.7045 6.11584 19.1782 6.08201 19.4912 6.35329Z"
            fill="currentColor"
        />
    </SvgIcon>
);

export const CheckmarkIcon16 = (props: SvgIconProps) => (
    <SvgIcon width="16" height="16" fontSize="small" viewBox="0 0 16 16" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9881 3.93057C13.3026 4.20014 13.339 4.67361 13.0694 4.98811L6.54236 12.603L2.96967 9.03034C2.67678 8.73745 2.67678 8.26258 2.96967 7.96968C3.26256 7.67679 3.73744 7.67679 4.03033 7.96968L6.45764 10.397L11.9306 4.01192C12.2001 3.69743 12.6736 3.661 12.9881 3.93057Z"
            fill="currentColor"
        />
    </SvgIcon>
);
