import { httpGet, httpPost } from '@lingoda/http';
import type { SectionName } from '@lingoda/sections';
import { buildUrl, getUrl } from '@lingoda/urls';
import { type RegisterUserParams } from './common';

export interface UserInfoSchema {
    email: string;
    firstName: string | null;
    lastName: string | null;
    nickname: string | null;
    profilePicture: string | null;
    realName: string | null;
    resourceOwnerName: string;
    username: string;
}

export type UserInfoResponse = HttpResponse<UserInfoSchema, never>;

type ConsentData = {
    marketing_communication?: boolean;
    privacy_policy: boolean;
};

export type UserRequestSchema = Partial<RegisterUserParams> & {
    email: string;
    timeFormat: string;
    consent: ConsentData;
};

interface BaseResourceOwner {
    name: string;
    type: 'facebook' | 'google' | 'apple';
}
export interface ResourceOwnersSchema extends BaseResourceOwner {
    loginUrl: string;
}

export interface ResourceOwnerDescriptionSchema extends BaseResourceOwner {
    disconnectUrl: string;
}

interface RegistrationResponse {
    redirectUrl?: string;
    user: {
        id: number;
    };
}

export interface ResourceState {
    section?: SectionName;
    curriculumId?: number;
    module?: number;
    timezone?: string;
    timeFormat?: string;
    weekStart?: number;
    device?: string;
    mobile?: boolean;
    deviceId?: string;
}

export const getUserInfo = (key: string): Promise<UserInfoResponse> =>
    httpGet(getUrl('hwi_oauth_connect_userinfo', { key }));

export const connectUser = (key: string, service: string): Promise<unknown> =>
    httpPost(getUrl('hwi_oauth_connect_login_service', { key, service }), {});

export const registerUser = (
    key: string,
    data: UserRequestSchema,
): Promise<HttpResponse<RegistrationResponse, never>> =>
    httpPost(getUrl('hwi_oauth_connect_registration', { key }), data);

export const getResourceOwners = (
    state?: ResourceState,
): Promise<HttpResponse<[ResourceOwnersSchema], never>> =>
    httpGet(
        buildUrl(getUrl('api_oauth_resource_owners'), {
            // stringify is a temporary solution until backend library (hwi/oauth-bundle) support associative arrays
            // @TODO remove after https://lingoda.atlassian.net/browse/LW-27267 is done
            // remove also in useRegisterQueryParams.ts
            query: { state: JSON.stringify(state) },
        }),
    );

export const disconnectResourceOwner = (
    type: BaseResourceOwner['type'],
): Promise<HttpResponse<[ResourceOwnersSchema], never>> =>
    httpPost(
        buildUrl(
            getUrl('hwi_oauth_disconnect_service', {
                service: type,
            }),
        ),
    );

export const getConnectedAccounts = (
    state?: ResourceState,
): Promise<HttpResponse<ResourceOwnerDescriptionSchema[], never>> =>
    httpGet(
        buildUrl(getUrl('api_oauth_connected_accounts'), {
            query: { state },
        }),
    );

export const finalizeDisconnect = (
    service: string,
    token: string,
    data: { plainPassword: string },
) =>
    httpPost(
        buildUrl(
            getUrl('hwi_oauth_finalize_disconnect_service', {
                token,
                service,
            }),
        ),
        data,
    );

export const getMobileResourceOwners = (deviceId: string) =>
    getResourceOwners({ device: 'handheld', mobile: true, deviceId });
