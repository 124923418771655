import { Translate } from '@lingoda/i18n';
import { Box, Grid, Typography } from '@lingoda/ui';
import { colorsPalette } from '@lingoda/ui/theme';
import { SupportUrlType, getSupportUrl } from '@lingoda/urls';
import VideoIcon from './videoIcon.svg?component';

export const ClassPlatform = ({ title }: { title: string }) => (
    <Grid container item xs={12} marginTop={3}>
        <Box width={({ spacing }) => spacing(6)}>
            <VideoIcon width={24} />
        </Box>
        <Box width={({ spacing }) => `calc(100% - ${spacing(6)})`}>
            <Typography variant="body1">{title}</Typography>
            <Box marginTop={1}>
                <Typography
                    htmlColor={colorsPalette.blackAlpha[70]}
                    sx={{ a: { color: 'inherit' } }}
                >
                    <Translate
                        id="recording-policy"
                        domain="student-booking"
                        params={{
                            faqLinkUrl: getSupportUrl(SupportUrlType.Recording),
                        }}
                    />
                </Typography>
            </Box>
        </Box>
    </Grid>
);
