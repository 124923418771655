import { Box, styled } from '@lingoda/ui';

const badgeHeight = 3;

export const TopBadge = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(-badgeHeight / 2),
    left: '50%',
    transform: 'translateX(-50%)',
    background: 'linear-gradient(103.93deg, rgb(255, 51, 204) 1.05%, rgb(255, 151, 29) 129.04%)',
    color: 'white',
    borderRadius: theme.spacing(badgeHeight / 2),
    padding: theme.spacing((badgeHeight - 3) / 2, 2),
    minHeight: theme.spacing(badgeHeight),
}));
