import { useEffect, useState } from 'react';
import { Block } from '@lingoda/ui';
import { unit } from '@lingoda/ui/theme/common';
import { BookingView } from '@lingoda/booking';
import { MIN_WIDTH_TILE } from '../constants';
import { ClassGridSkeleton } from './ClassGridSkeleton';
import { ClassListSkeleton } from './ClassListSkeleton';

interface Props {
    bookingView: BookingView;
}

export const ClassesPlaceholder = ({ bookingView }: Props) => {
    const [gridRef, setGridRef] = useState<HTMLDivElement | null>();
    const width = gridRef?.offsetWidth;

    const ClassesLayoutComponent =
        bookingView === BookingView.List ? ClassListSkeleton : ClassGridSkeleton;

    useEffect(() => {
        setTimeout(() => window.scrollTo(0, 0), 0);
    }, []);

    return (
        <Block ref={setGridRef}>
            <ClassesLayoutComponent
                count={width ? Math.floor(width / (MIN_WIDTH_TILE + unit * 2)) : 0}
            />
        </Block>
    );
};
