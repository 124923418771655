import { lazy } from 'react';
import {
    RouteAccess,
    type RouteConfigExtended,
    TabValue,
    addRouterListener,
    changeLearningLanguageStorePath,
    creditsConversionPath,
    studentCreditsCheckoutPath,
    studentM2MCheckoutPath,
    studentPaymentFailedPath,
    studentPaymentPendingPath,
    studentSprintCheckoutPath,
    studentTeamsCheckoutPath,
    subscriptionsStorePath,
} from '@lingoda/router';

const Store = lazy(() => import('./pages/Store'));
const Checkout = lazy(() => import('./pages/Checkout'));
const CreditsConversion = lazy(() => import('./pages/CreditsConversion'));
const PaymentFailedPage = lazy(() => import('./pages/PaymentFailed'));
const PaymentPendingPage = lazy(() => import('./pages/PaymentPending'));

// notifications rely on this rather than matchRoute as they are not react components
// see linguando/assets/src/store/middleware/notifications/sprintRules.ts
const PaymentFailed = addRouterListener('paymentFailedPath')(PaymentFailedPage);
const PaymentPending = addRouterListener('paymentPendingPath')(PaymentPendingPage);

const storeRoutes: RouteConfigExtended[] = [
    {
        exact: true,
        component: Store,
        path: [subscriptionsStorePath.raw, changeLearningLanguageStorePath.raw],
        label: 'Store',
        config: {
            hideFooter: true,
            tab: TabValue.Store,
            hideDixa: true,
        },
    },
    {
        exact: true,
        component: Checkout,
        path: [
            studentM2MCheckoutPath.raw,
            studentCreditsCheckoutPath.raw,
            studentSprintCheckoutPath.raw,
            studentTeamsCheckoutPath.raw,
        ],
        label: 'Checkout',
        config: {
            hideFooter: true,
            hideNavigation: true,
            disableLogoLink: true,
            tab: TabValue.Store,
            hideMobileBottomNavbar: true,
        },
    },
    {
        exact: true,
        component: PaymentFailed,
        path: studentPaymentFailedPath.raw,
        label: 'PaymentFailed',
        config: {
            hideFooter: true,
            access: RouteAccess.Public,
            tab: undefined,
            hideNavigation: true,
            navigationElevation: 1,
        },
    },
    {
        exact: true,
        label: 'CreditsConversion',
        path: creditsConversionPath.raw,
        component: CreditsConversion,
        config: {
            tab: TabValue.Store,
        },
    },
    {
        exact: true,
        component: PaymentPending,
        path: studentPaymentPendingPath.raw,
        label: 'PaymentPending',
        config: {
            hideFooter: true,
            hideNavigation: true,
            tab: TabValue.Store,
            disableLogoLink: true,
            hideMobileBottomNavbar: true,
        },
    },
];

export default storeRoutes;
