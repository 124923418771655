import MuiTooltip, {
    type TooltipProps as MuiTooltipProps,
    tooltipClasses,
} from '@mui/material/Tooltip';
import { type ElementType } from 'react';
import { colorsPalette, styled } from '../../theme';
import { Box, type BoxProps } from '../Box';

export interface TooltipProps extends MuiTooltipProps {
    component?: ElementType;
    display?: BoxProps['display'];
    tooltipTheme?: 'light' | 'dark';
}

export const Tooltip = ({
    disableInteractive = true,
    component,
    display = 'inline',
    ...props
}: TooltipProps) => (
    <StyledMuiTooltip {...props} disableInteractive={disableInteractive}>
        <Box component={component} display={display}>
            {props.children}
        </Box>
    </StyledMuiTooltip>
);

const StyledMuiTooltip = styled(
    ({ className, ...props }: TooltipProps) => (
        <MuiTooltip {...props} classes={{ popper: className }} />
    ),
    { shouldForwardProp: (propName) => propName !== 'tooltipTheme' },
)(({ tooltipTheme }) => ({
    [`& a`]: {
        color: colorsPalette.whiteSolid[100],
        [`&:hover`]: {
            color: colorsPalette.whiteSolid[100],
        },
    },
    [`& .${tooltipClasses.tooltip}`]:
        tooltipTheme === 'light'
            ? {
                  backgroundColor: colorsPalette.whiteSolid[100],
                  color: colorsPalette.blackSolid[100],
              }
            : {},
}));
