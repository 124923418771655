import { useMemo } from 'react';
import type { Achievement, ChecklistItem } from '@lingoda/graphql';
import { Box, Img, ProgressBar, Stack, Typography, useResolution } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { getIsClaimable } from '../../../../utils';
import ProgressBadge from '../../../../static/icons/progress-badge.svg';
import { progressStepsTranslations } from '../translations';

interface ChecklistProgressProps {
    checklist: readonly ChecklistItem[];
    achievement: Achievement;
}

export const ChecklistProgress = ({ checklist, achievement }: ChecklistProgressProps) => {
    const isPhone = useResolution('phone');
    const isClaimable = getIsClaimable(achievement);

    const badgeWidth = isClaimable && !isPhone ? 97 : 72;

    const activeStepIndex = checklist.findIndex((item) => !item.isCompleted);
    const activeStep = checklist[activeStepIndex] ?? checklist[0];

    const activeStepDescription =
        progressStepsTranslations[activeStep?.slug]?.description ?? activeStep?.description;

    const progressBarValues = useMemo(() => {
        const stepIncrement = 100 / (checklist.length - 1);

        return Array.from({ length: checklist.length }, (_, i) =>
            i === 0 ? 5 : i * stepIncrement,
        );
    }, [checklist]);
    const progressValue = progressBarValues[activeStepIndex] ?? 100;

    return (
        <Box p={3} display="flex">
            <Box mr={isClaimable && !isPhone ? 3 : 2}>
                <Img src={ProgressBadge} width={badgeWidth} />
            </Box>
            <Stack spacing={1} flexGrow={1}>
                <Typography variant="h2">
                    {trans('checklist-progress-title', {}, 'student-onboarding')}
                </Typography>
                <Typography variant="body2">{activeStepDescription}</Typography>
                <Box display="flex" alignItems="center">
                    <ProgressBar value={progressValue} rounded fullWidth size="medium" />
                    <Typography ml={1} variant="body2">
                        {activeStepIndex}/{checklist.length}
                    </Typography>
                </Box>
            </Stack>
        </Box>
    );
};
