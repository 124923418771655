import { actionClick } from '@lingoda/analytics';
import { AppointmentStatus, ClassType } from '@lingoda/graphql';
import { createDate, format } from '@lingoda/dates';
import { goTo, resolvePath, studentClassDetailsPath } from '@lingoda/router';
import { useUiEvent } from '@lingoda/ui';
import { isClassBooked } from '@lingoda/classes';
import { getBookClassEvent, useBooking } from '@lingoda/booking';
import { ClassListItemStatus } from '@lingoda/booking-ui';
import { useShowCalendarConnectionDialog } from '@lingoda/calendar-connection/hooks';
import { type ClassItem } from '../types';
import { ClassAvatarsGroup } from './ClassAvatarsGroup';
import { GroupClassItemLayout } from './GroupClassItemLayout';

interface GroupClassItemProps {
    classItem: ClassItem;
    lessonId: number;
    moduleId: number;
    role?: React.AriaRole;
}

export const GroupClassItem = ({ classItem, lessonId, moduleId, role }: GroupClassItemProps) => {
    const { uid: classId, isExisting, students, teacher, isForNewClassroom } = classItem;
    const startDate = createDate(classItem.startDate);
    const endDate = createDate(classItem.endDate);
    const title = formatDate(startDate);
    const { shouldShowCalendarConnectDialog, showCalendarConnectDialog } =
        useShowCalendarConnectionDialog();
    const bodyText = `${formatTime(startDate)} - ${formatTime(endDate)} (${formatTimezone(
        startDate,
    )})`;
    const isBooked = isClassBooked(classItem);
    const linkTo = isExisting
        ? resolvePath(studentClassDetailsPath({ class_id: classId }))
        : undefined;

    const addEvent = useUiEvent();
    const [bookDisabled, bookClass] = useBooking();

    const handleBookClick = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        void addEvent(actionClick('Book class'), getBookClassEvent());

        const type = ClassType.Group;
        const existingOrGeneratedClassToBook = isExisting
            ? ({
                  id: classId,
                  startDate,
                  type,
                  lessonId,
                  moduleId,
                  classSource: 'existing',
                  isForNewClassroom,
              } as const)
            : ({
                  startDate,
                  type,
                  lessonId,
                  moduleId,
                  classSource: 'generated',
                  isForNewClassroom,
              } as const);
        bookClass(existingOrGeneratedClassToBook)
            .then(({ classId }) => {
                if (shouldShowCalendarConnectDialog) {
                    showCalendarConnectDialog();
                }
                goTo(studentClassDetailsPath({ class_id: classId }), { replace: true });
            })
            .catch(() => undefined);
    };

    return (
        <GroupClassItemLayout
            title={title}
            bodyText={bodyText}
            bookDisabled={bookDisabled}
            onBookClick={handleBookClick}
            linkTo={linkTo}
            bookVisible={!isBooked}
            role={role}
            isClickable
        >
            <ClassAvatarsGroup teacher={teacher} students={students} />
            {isBooked && <ClassListItemStatus isPast={false} status={AppointmentStatus.Active} />}
        </GroupClassItemLayout>
    );
};

const formatDate = format('cccc, MMM d');
const formatTime = format('p');
const formatTimezone = format('O');
