import { Box, Paper, type PaperProps, styled } from '@lingoda/ui';
import type { AchievementFragment } from '@lingoda/graphql';
import { AchievementItem } from './AchievementItem';
import { RecapAchievementItem } from './RecapAchievementItem';
import { TopAchievementsHeader } from './TopAchievementsHeader';

export interface TopAchievementsLayoutProps {
    achievements: ReadonlyArray<AchievementFragment>;
    loading: boolean;
    isYearlyRecapVisible: boolean;
    paperProps?: Partial<PaperProps>;
    showTitle?: boolean;
}

export const TopAchievementsLayout = ({
    achievements,
    loading,
    isYearlyRecapVisible,
    paperProps,
    showTitle,
}: TopAchievementsLayoutProps) => {
    return (
        <Paper sx={{ py: 1.5 }} {...paperProps}>
            {showTitle && (
                <BoxStyled>
                    <TopAchievementsHeader />
                </BoxStyled>
            )}
            <Box component="ul" sx={{ listStyle: 'none', padding: 0, margin: 0 }}>
                {isYearlyRecapVisible && <RecapAchievementItem component="li" />}
                {achievements.map((achievement) => (
                    <AchievementItem
                        key={achievement.id}
                        achievement={achievement}
                        loading={loading}
                        component="li"
                    />
                ))}
            </Box>
        </Paper>
    );
};

const BoxStyled = styled(Box)(({ theme: { spacing } }) => ({
    padding: spacing(1.5, 1, 1.5, 3),
}));
