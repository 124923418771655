import { Box, type BoxProps } from '@lingoda/ui';

type DotDividerProps = BoxProps;

const DotDivider = (props: DotDividerProps) => (
    <Box display="inline-flex" {...props}>
        •
    </Box>
);

export default DotDivider;
