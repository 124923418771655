import { type AriaAttributes, useMemo, useRef } from 'react';
import uniqueId from 'lodash/uniqueId';
import { useToggleState } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';
import { styled } from '../theme';
import { Menu } from '../layout/Menu';
import { MenuItem } from '../layout/MenuItem';
import { ChevronIcon16 } from '../icons/ChevronIcon';
import { Button } from './Button';
import ButtonGroup from './ButtonGroup';
import type { ButtonGroupProps } from './ButtonGroup';

interface Props extends Omit<ButtonGroupProps, 'variant'> {
    options: Option[];
    variant?: Exclude<ButtonGroupProps['variant'], 'text'>;
}

const SplitButton = ({ options: [mainOption, ...options], children, variant, ...other }: Props) => {
    const anchorRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, open, close] = useToggleState();
    const visibleOptions = options.filter((option) => !option.hidden);
    const isDisabled = visibleOptions.every((option) => option.disabled);

    const toggleSize = other.size === 'small' ? 'small' : 'medium';
    const toggleStyle = toggleBtnStyles[toggleSize];

    const a11y = useA11y();

    const createClickHandler = (callback: Option['action']) => {
        return () => {
            close();
            callback?.();
        };
    };

    return (
        <>
            <ButtonGroup ref={anchorRef} {...other} id={a11y.groupId}>
                <Button
                    variant={variant}
                    onClick={mainOption.action}
                    {...getMainOptionPassProps(mainOption)}
                >
                    {children ?? mainOption.label}
                </Button>

                {visibleOptions.length > 0 && (
                    <Button
                        size="small"
                        onClick={open}
                        sx={{ width: toggleStyle.width }}
                        disabled={isDisabled}
                        variant="outlined"
                        fullWidth={false}
                        aria-label={trans('show-more', {}, 'student-common')}
                        aria-controls={isOpen ? a11y.menuId : undefined}
                        aria-expanded={isOpen ? 'true' : undefined}
                        aria-haspopup="menu"
                    >
                        <ChevronIcon16 fontSize={toggleStyle.iconSize} />
                    </Button>
                )}
            </ButtonGroup>
            <MenuStyled
                open={isOpen}
                anchorEl={anchorRef.current}
                onClose={close}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                MenuListProps={{ id: a11y.menuId, 'aria-labelledby': a11y.groupId }}
            >
                {options.map((option, i) => (
                    <MenuItemStyled
                        key={i}
                        onClick={option.action && createClickHandler(option.action)}
                        {...getMenuOptionPassProps(option)}
                    >
                        {option.label}
                    </MenuItemStyled>
                ))}
            </MenuStyled>
        </>
    );
};

interface Option extends OptionHTMLAttributes {
    label: string;
    loading?: boolean;
    disabled?: boolean;
    action?: () => void;
    startIcon?: React.ReactNode;
    hidden?: boolean;
}

interface OptionHTMLAttributes extends AriaAttributes {
    id?: string;
}

const getMainOptionPassProps = (option: Option) => {
    const { label, action, hidden, ...passProps } = option;

    return passProps;
};

const getMenuOptionPassProps = (option: Option) => {
    const { label, action, hidden, startIcon, loading, ...passProps } = option;

    return passProps;
};

const MenuStyled = styled(Menu)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        '& .MuiMenu-list': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
    },
}));

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        whiteSpace: 'normal',
        textAlign: 'center',
    },
}));

const toggleBtnStyles = {
    small: {
        width: '40',
        iconSize: 'small' as const,
    },
    medium: {
        width: '50',
        iconSize: 'medium' as const,
    },
};

const useA11y = () => {
    return useMemo(() => {
        const id = uniqueId('split-button-');

        return {
            menuId: `${id}-menu`,
            groupId: `${id}-group`,
        };
    }, []);
};

export default SplitButton;
