import { Translate, trans } from '@lingoda/i18n';
import { DialogInfo, Typography } from '@lingoda/ui';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export const TrialInfoDialog = ({ isOpen, onClose }: Props) => (
    <DialogInfo
        isOpen={isOpen}
        onClose={onClose}
        buttonLabel={trans('btn-acknowledge', {}, 'public-common')}
        title={trans('trial-dialog-title', {}, 'student-store')}
    >
        <Typography variant="body1">
            <Translate id="trial-dialog-content" domain="student-store" />
        </Typography>
    </DialogInfo>
);
