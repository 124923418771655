import { type PropsWithChildren } from 'react';
import { colorsPalette } from '../../theme';
import { Box } from '../Box';
import { Stack } from '../Stack';
import { Typography } from '../Typography';
import { ProgressBar, type ProgressBarProps } from './ProgressBar';

export interface ProgressBarWithLabelProps extends Omit<ProgressBarProps, 'sx'> {
    /**
     * @deprecated Use or create dedicated customization props instead. Kept for now to not break existing code.
     */
    sxBar?: ProgressBarProps['sx'];
    width?: string | number;
    barMaxWidth?: string | number;
    rightLabelAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
    value: number;
}

export const ProgressBarWithLabel = ({
    children,
    sxBar,
    width,
    barMaxWidth,
    rightLabelAlign,
    ...props
}: PropsWithChildren<ProgressBarWithLabelProps>) => (
    <Stack direction="row" alignItems="center" spacing={1} width={width}>
        <ProgressBar sx={{ ...sxBar, maxWidth: barMaxWidth }} {...props} />
        {children || (
            <Box minWidth={35}>
                <Typography
                    variant="subtitle2"
                    htmlColor={colorsPalette.blackAlpha[70]}
                    textAlign={rightLabelAlign}
                >
                    {Math.round(props.value)}%
                </Typography>
            </Box>
        )}
    </Stack>
);
