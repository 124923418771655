import { colorsPalette, dvh } from '../../theme';
import type { Theme } from '../../theme';

export type DatePickerDotColor = 'primary' | 'info';

export const DAY_SCHEDULED_CLASS = 'rdrDayNumber--scheduled';
export const DAY_NO_SCHEDULED_CLASS = 'rdrDayNumber--notScheduled'; // NOTE: don't remove. It's not used for styling, but used in E2E tests
export const DAY_SELECTED = 'rdrDayNumber--selected';
export const DAY_DISABLED = 'rdrDayNumber--disabled';

const CELL_SIZE = 40;
const RADIUS_PX = `${CELL_SIZE / 2}px`;
const DOT_COLORS = {
    common: colorsPalette.blackAlpha[16],
    primary: colorsPalette.primarySolid[100],
    info: colorsPalette.infoSolid[100],
    selected: colorsPalette.whiteSolid[100],
};
const RANGE_DOT_COLORS = {
    primary: colorsPalette.primarySolid[100],
    info: colorsPalette.negativeCustom.dark,
};

interface DesktopOptions {
    showHoverStyles: boolean;
}

export const datePickerDesktop = ({ showHoverStyles }: DesktopOptions) => ({
    '& .rdrMonth': {
        width: '100%',
        padding: 0,
    },
    '& .rdrMonthName': {
        display: 'none',
    },

    ...(showHoverStyles && {
        // Hover state:
        '& .rdrDayHovered .rdrDayNumber': {
            backgroundColor: colorsPalette.blackAlpha[4],
        },
        '& .rdrDay:not(.rdrDayHovered) .rdrStartEdge ~ .rdrDayNumber, & .rdrDay:not(.rdrDayHovered) .rdrEndEdge ~ .rdrDayNumber':
            {
                color: colorsPalette.whiteSolid[100],
                backgroundColor: colorsPalette.blackSolid[100],
            },
        '& .rdrDay.rdrDayHovered .rdrStartEdge ~ .rdrDayNumber, & .rdrDay.rdrDayHovered .rdrEndEdge ~ .rdrDayNumber':
            {
                color: colorsPalette.whiteSolid[100],
                backgroundColor: colorsPalette.blackAlpha[70],
            },

        // Selected days:
        [`& .rdrDay.rdrDayHovered .${DAY_SELECTED}`]: {
            backgroundColor: colorsPalette.blackAlpha[70],
            color: colorsPalette.whiteSolid[100],
        },

        // Scheduled days:
        [`& .rdrDayHovered .${DAY_SCHEDULED_CLASS}`]: {
            color: colorsPalette.blackSolid[100],
            backgroundColor: colorsPalette.blackAlpha[4],
        },
    }),
});

export const datePickerMobile = (theme: Theme) => ({
    '& .rdrInfiniteMonths': {
        maxHeight: dvh('45dvh'),
        overflowX: 'hidden',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
    },
    '& .rdrInfiniteMonths::-webkit-scrollbar': {
        display: 'none',
    },
    '& .rdrMonth': {
        marginBottom: 0,
        padding: 0,
        width: '100%',
        // Overwriting the library's style height prop
        height: 'auto !important',
    },
    '& .rdrMonthName': {
        ...theme.typography.subtitle1,
        color: colorsPalette.blackSolid[100],
        margin: theme.spacing(2, 0, 1),
        padding: theme.spacing(1),
    },

    // Avoid repeating the first month twice
    '& .rdrMonth:first-of-type .rdrMonthName': {
        display: 'none',
    },
});

interface BaseOptions {
    dotColor: DatePickerDotColor;
    scheduledDayBg?: string;
    selectedDayBg?: string;
}

export const datePickerBase = (
    theme: Theme,
    { dotColor, scheduledDayBg = colorsPalette.infoSolid[12], selectedDayBg }: BaseOptions,
    loading?: boolean,
) => ({
    '& .rdrCalendarWrapper': {
        display: 'flex',
        background: 'none',
    },
    '& .rdrDay': {
        height: CELL_SIZE + 2,
        flexBasis: 'calc(100% / 7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 1,
        color: colorsPalette.blackSolid[100],
        '-webkit-tap-highlight-color': 'transparent',
    },
    '& .rdrDayNumber': {
        outline: 0,
        position: 'static',
        borderRadius: RADIUS_PX,
        ...theme.typography.body2,
        lineHeight: 'unset',
        width: '100%',
        height: '100%',
    },

    // Styles for range selection
    '& .rdrDayEndOfWeek .rdrInRange, & .rdrDayEndOfMonth .rdrInRange, & .rdrDayStartOfMonth .rdrInRange, & .rdrDayStartOfWeek .rdrInRange':
        {
            borderRadius: 0,
        },
    '& .rdrStartEdge, & .rdrDayStartPreview': {
        top: 1,
        bottom: 1,
        left: 1,
        right: 0,
        borderRadius: `${RADIUS_PX} 0 0 ${RADIUS_PX}`,
    },
    '& .rdrEndEdge, & .rdrDayEndPreview': {
        top: 1,
        bottom: 1,
        left: 0,
        right: 1,
        borderRadius: `0 ${RADIUS_PX} ${RADIUS_PX} 0`,
    },
    '& .rdrDayInPreview': {
        top: 1,
        bottom: 1,
        left: 0,
        right: 0,
        borderRadius: 0,
    },
    '& .rdrInRange': {
        top: 1,
        bottom: 1,
        left: 0,
        right: 0,
        borderRadius: 0,
    },
    '& .rdrStartEdge, & .rdrEndEdge, & .rdrInRange': {
        backgroundColor: colorsPalette.blackAlpha[8],
        zIndex: 0,
    },
    '& .rdrDayStartPreview, & .rdrDayEndPreview, & .rdrDayInPreview': {
        backgroundColor: colorsPalette.blackAlpha[4],
        zIndex: 1,
    },
    '& .rdrDayStartPreview.rdrDayEndPreview, & .rdrStartEdge.rdrEndEdge': {
        borderRadius: RADIUS_PX,
        left: 1,
        right: 1,
    },
    '& .rdrDayInPreview, & .rdrDayStartPreview, & .rdrDayEndPreview': {
        border: 'none',
    },

    // Section - months and weeks
    '& .rdrWeekDays': {
        padding: 0,
    },
    '& .rdrWeekDays .rdrWeekDay': {
        ...theme.typography.body3,
        color: colorsPalette.blackAlpha[70],
        height: CELL_SIZE + 2,
        flexBasis: 'calc(100%  / 7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'capitalize',
    },
    '& .rdrDayDisabled, & .rdrDayToday.rdrDayDisabled': {
        backgroundColor: 'transparent',
        color: colorsPalette.blackAlpha[20],
        border: 'none',
        borderRadius: 0,
    },

    // Days from months before and after the current one
    '& .rdrDayPassive': {
        visibility: 'hidden',
        pointerEvents: 'none',
    },
    ...(loading && {
        '& .rdrDay:after': {
            ...dayDotStyles(theme, DOT_COLORS.common),
        },
    }),
    ...(!loading && {
        // Today:
        [`& .rdrDay.rdrDayToday .rdrDayNumber`]: {
            border: `1px solid ${colorsPalette.blackAlpha[20]}`,
        },

        // Selected days:
        [`& .rdrDay .${DAY_SELECTED}`]: {
            ...dayCircleStyles(),
            backgroundColor: selectedDayBg || colorsPalette.blackSolid[100],
            color: colorsPalette.whiteSolid[100],
            border: 'none',
        },

        // Scheduled days:
        [`& .rdrDay .${DAY_SCHEDULED_CLASS}`]: {
            ...dayCircleStyles(),
        },
        [`& .rdrDay:not(.rdrDayHovered) .${DAY_SCHEDULED_CLASS}:not(.${DAY_SELECTED})`]: {
            backgroundColor: scheduledDayBg,
            color: scheduledDayBg !== 'transparent' ? colorsPalette.blackSolid[100] : undefined,
        },
        [`& .rdrDay:not(.rdrDayPassive) .rdrDayNumber .${DAY_SCHEDULED_CLASS}:after`]: {
            ...dayDotStyles(theme, DOT_COLORS[dotColor]),
        },
        [`& .rdrDay:not(.rdrDayPassive) .rdrDayNumber .${DAY_SCHEDULED_CLASS}.${DAY_SELECTED}:after`]:
            {
                ...dayDotStyles(theme, DOT_COLORS.selected),
            },
        [`& .rdrDay .rdrStartEdge ~ .rdrDayNumber .${DAY_SCHEDULED_CLASS},
          & .rdrDay .rdrEndEdge ~ .rdrDayNumber .${DAY_SCHEDULED_CLASS},
          & .rdrDay .rdrDayStartPreview ~ .rdrDayNumber .${DAY_SCHEDULED_CLASS},
          & .rdrDay .rdrDayEndPreview ~ .rdrDayNumber .${DAY_SCHEDULED_CLASS}`]: {
            backgroundColor: 'transparent',
            color: 'inherit',
        },
        [`& .rdrDay .rdrDayInPreview ~ .rdrDayNumber .${DAY_SCHEDULED_CLASS},
          & .rdrDay .rdrInRange ~ .rdrDayNumber .${DAY_SCHEDULED_CLASS}`]: {
            backgroundColor: 'transparent',
        },
        [`& .rdrDay:not(.rdrDayPassive).rdrDayHovered .${DAY_SCHEDULED_CLASS}:after,
          & .rdrDay .rdrInRange ~ .rdrDayNumber .${DAY_SCHEDULED_CLASS}:after,
          & .rdrDay .rdrDayInPreview ~ .rdrDayNumber .${DAY_SCHEDULED_CLASS}:after`]: {
            borderColor: RANGE_DOT_COLORS[dotColor],
        },

        [`& .${DAY_DISABLED}`]: {
            opacity: 0.5,
        },
    }),

    // Hiding elements which we are not using
    '& .rdrSelected': {
        display: 'none',
    },

    // Clearing hover states on inner elements we are not using
    '& .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber::after': {
        border: 'none',
    },
});

const dayDotStyles = (theme: Theme, color: string) => ({
    content: '" "',
    position: 'absolute',
    height: '3px',
    width: '3px',
    borderRadius: '50%',
    bottom: '15%',
    border: `2px solid ${color}`,
    [theme.breakpoints.down('sm')]: {
        bottom: '20%',
    },
});

const dayCircleStyles = () => ({
    width: '100%',
    height: '100%',
    borderRadius: RADIUS_PX,
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});
