import { Currency } from '@lingoda/graphql';
import { getDefaultCurrency } from './getDefaultCurrency';

export const getSupportedCurrency = (currency?: string): Currency => {
    if (!currency || !Object.values(Currency).includes(currency as Currency)) {
        return getDefaultCurrency();
    }

    return currency as Currency;
};
