import { useEffect } from 'react';
import { useBooking } from '@lingoda/booking';
import { isClassUnbooked } from '@lingoda/classes';
import { getClassHasEnded } from '@lingoda/classes-helpers';
import { createDate } from '@lingoda/dates';
import { type StudentClassQuery } from '@lingoda/graphql';
import { goTo, studentClassDetailsPath, useLocation } from '@lingoda/router';
import { getQueryParams } from '@lingoda/urls';
import type { GeneratedClassData } from '../types';

type Props = {
    classData?: StudentClassQuery['class'] | GeneratedClassData;
    refetch: () => void;
    skip: boolean;
};

export const useInitBooking = ({ classData, skip, refetch }: Props) => {
    const { search } = useLocation();
    const { initBooking } = getQueryParams<{ initBooking?: string }>(search);
    const [, bookClass] = useBooking();

    useEffect(() => {
        if (initBooking !== 'true' || skip || !classData) {
            return;
        }

        const { type, startDate, endDate, isExisting, isForNewClassroom } = classData;
        const commonParams = { type, startDate: createDate(startDate), isForNewClassroom };
        const bookingParams = isExisting
            ? ({ id: classData.uid, classSource: 'existing', ...commonParams } as const)
            : ({
                  lessonId: classData.lesson?.id as number,
                  classSource: 'generated',
                  ...commonParams,
              } as const);

        const isBooked = isExisting && !isClassUnbooked(classData);
        const hasEnded = getClassHasEnded(createDate(endDate), createDate());

        if (!isBooked && !hasEnded) {
            void bookClass(bookingParams)
                .then(({ classId }) => {
                    refetch();
                    goTo(studentClassDetailsPath({ class_id: classId }), { replace: true });
                })
                .catch(() => undefined);
        }
    }, [bookClass, classData, initBooking, refetch, skip]);
};
