import { type ReactNode } from 'react';
import { Typography } from '@lingoda/ui';
import { useToggleState } from '@lingoda/hooks';
import { styled } from '@lingoda/ui/theme';
import { trans } from '@lingoda/i18n';
import { TrialInfoDialog } from './TrialInfoDialog';

interface TrialDetailsProps {
    children: ReactNode;
}

export const TrialDetails = ({ children }: TrialDetailsProps) => {
    const [isDialogOpen, showDialog, closeDialog] = useToggleState();

    return (
        <Typography variant="body2">
            {children}{' '}
            <LearnMoreCta onClick={showDialog}>
                {trans('learn-more-cta', {}, 'public-common')}
            </LearnMoreCta>
            {isDialogOpen && <TrialInfoDialog isOpen onClose={closeDialog} />}
        </Typography>
    );
};

const LearnMoreCta = styled('button')({
    textDecoration: 'underline',
    cursor: 'pointer',

    // reset default button styles and inherit font styles
    display: 'inline',
    border: 'none',
    background: 'none',
    padding: 0,
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    color: 'inherit',
});
