import { Box, Img, Typography, colorsPalette, styled } from '@lingoda/ui';
import StepIcon from '../../../../static/icons/step-icon.svg';
import StepIconCompleted from '../../../../static/icons/step-icon-completed.svg';

type ChecklistStepItem = {
    stepNumber: number;
    isCompleted: boolean;
    isActive: boolean;
    title: string;
};

interface Props {
    step: ChecklistStepItem;
}

export const ChecklistStepItem = ({ step }: Props) => {
    const activeStepTypography = step.isActive ? 'subtitle1' : 'body2';

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" height={32}>
                <Box display="flex" alignItems="center" justifyContent="center" width={32} mr={2}>
                    <StyledStepNumber completed={step.isCompleted} active={step.isActive}>
                        <Typography variant={activeStepTypography}>{step.stepNumber}</Typography>
                    </StyledStepNumber>
                </Box>

                <StyledChecklistStepTitle
                    variant={activeStepTypography}
                    completed={step.isCompleted}
                >
                    {step.title}
                </StyledChecklistStepTitle>
            </Box>
            <Img src={step.isCompleted ? StepIconCompleted : StepIcon} />
        </Box>
    );
};

const StyledStepNumber = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'completed' && prop !== 'active',
})<{ completed: boolean; active: boolean }>(({ theme, completed, active }) => ({
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: '50%',
    backgroundColor: colorsPalette.primarySolid[8],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colorsPalette.primarySolid[70],
    ...(active && {
        height: theme.spacing(4),
        width: theme.spacing(4),
        backgroundColor: colorsPalette.primarySolid[20],
        color: colorsPalette.primarySolid[100],
    }),
    ...(completed && {
        backgroundColor: colorsPalette.blackSolid[8],
        color: colorsPalette.blackSolid[70],
    }),
}));

const StyledChecklistStepTitle = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'completed',
})<{ completed: boolean }>(({ theme, completed }) => ({
    lineHeight: theme.spacing(4),
    ...(completed && {
        textDecoration: 'line-through',
    }),
}));
