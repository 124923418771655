import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const CheckCircleIcon24 = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 12C1 14.9174 2.15893 17.7153 4.22183 19.7782C6.28473 21.8411 9.08262 23 12 23C14.9174 23 17.7153 21.8411 19.7782 19.7782C21.8411 17.7153 23 14.9174 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1C9.08262 1 6.28473 2.15893 4.22183 4.22183C2.15893 6.28473 1 9.08262 1 12ZM16.5606 9.49827C16.8357 9.18869 16.8079 8.71463 16.4983 8.43944C16.1887 8.16426 15.7146 8.19214 15.4394 8.50173L10.625 13.918L8.5206 11.8887C8.22243 11.6012 7.74764 11.6098 7.46012 11.908C7.1726 12.2061 7.18123 12.6809 7.4794 12.9685L10.7083 16.0821L16.5606 9.49827Z"
            fill="currentColor"
        />
    </SvgIcon>
);

export const CheckCircleIcon16 = (props: SvgIconProps) => (
    <SvgIcon width="16px" height="16px" viewBox="0 0 16 16" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM11.5303 6.53033C11.8232 6.23744 11.8232 5.76256 11.5303 5.46967C11.2374 5.17678 10.7626 5.17678 10.4697 5.46967L6.96077 8.97857L5.48809 7.71627C5.1736 7.44671 4.70012 7.48313 4.43056 7.79762C4.16099 8.11211 4.19741 8.58559 4.5119 8.85516L7.03923 11.0214L11.5303 6.53033Z"
            fill="currentColor"
        />
    </SvgIcon>
);
