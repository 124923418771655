import { memo } from 'react';
import { RadioButtonUnchecked } from '@lingoda/ui/icons';
import { CalendarIcon24, CheckmarkIcon24, ClockIcon24, Lock } from '@lingoda/ui/icons/lingodaIcons';
import { colorsPalette } from '@lingoda/ui/theme';
import { LessonStatus } from '@lingoda/learning-units';

interface LessonStatusIconProps {
    lessonStatus: LessonStatus;
    titleAccess?: string;
}

const LessonStatusIcon = ({ lessonStatus, titleAccess }: LessonStatusIconProps) => {
    switch (lessonStatus) {
        case LessonStatus.Locked:
            return <Lock htmlColor={colorsPalette.blackAlpha[70]} titleAccess={titleAccess} />;
        case LessonStatus.Completed:
            return (
                <CheckmarkIcon24
                    htmlColor={colorsPalette.positiveCustom.dark}
                    titleAccess={titleAccess}
                />
            );
        case LessonStatus.CompletedImproved:
            return (
                <CheckmarkIcon24
                    htmlColor={colorsPalette.positiveCustom.dark}
                    titleAccess={titleAccess}
                />
            );
        case LessonStatus.Skip:
            return (
                <CheckmarkIcon24
                    htmlColor={colorsPalette.blackAlpha[70]}
                    titleAccess={titleAccess}
                />
            );
        case LessonStatus.Booked:
            return (
                <CalendarIcon24
                    htmlColor={colorsPalette.positiveCustom.dark}
                    titleAccess={titleAccess}
                />
            );
        case LessonStatus.Pending:
            return (
                <ClockIcon24
                    htmlColor={colorsPalette.positiveCustom.dark}
                    titleAccess={titleAccess}
                />
            );
        default:
            return (
                <RadioButtonUnchecked
                    htmlColor={colorsPalette.blackSolid[20]}
                    titleAccess={titleAccess}
                />
            );
    }
};

export default memo(LessonStatusIcon);
