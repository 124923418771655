import { useToggleState } from '@lingoda/hooks';
import { Dialog as MuiDialog, type DialogProps as MuiDialogProps } from '../../../layout/Dialog';
import { useResolution } from '../../../hooks';
import { dvh, dvw, styled, theme } from '../../../theme';
import { Fade, Slide } from '../../../transitions';

export interface Props extends InheritedProps {
    isOpen?: boolean;
    onClose?: () => void;
    scrollable?: boolean;
    withMobileSticky?: boolean;
    blurredBackground?: boolean;
    disableCloseOnBackdropClick?: boolean;
}

type InheritedProps = Omit<
    MuiDialogProps,
    'open' | 'onClose' | 'onChange' | 'scroll' | 'TransitionComponent' | 'TransitionProps'
>;

type MuiDialogStyledProps = Pick<Props, 'withMobileSticky' | 'blurredBackground'>;

const MuiDialogStyled = styled(MuiDialog, {
    shouldForwardProp: (prop) =>
        !['withMobileSticky', 'blurredBackground'].includes(prop as string),
})<MuiDialogStyledProps>(({ theme, withMobileSticky, maxWidth, blurredBackground }) => ({
    ...(blurredBackground && {
        backdropFilter: 'blur(10px)',
    }),
    ...(withMobileSticky && {
        '& .MuiDialog-container': {
            [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-end',
            },
        },
        '& .MuiDialog-paper.MuiPaper-root': {
            [theme.breakpoints.up('sm')]: maxWidth !== 'xs' && {
                minWidth: 520,
            },

            [theme.breakpoints.down('sm')]: {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                margin: 0,
                maxHeight: dvh('90dvh'),
                width: dvw('100dvw'),
            },
        },
    }),
}));

export const Dialog = ({
    children,
    fullWidth = false,
    isOpen = false,
    onClose,
    scrollable = true,
    maxWidth,
    withMobileSticky = true,
    'aria-labelledby': ariaLabelledBy,
    blurredBackground = false,
    components,
    disableCloseOnBackdropClick,
    ...props
}: Props) => {
    const isSmallScreen = useResolution('phone');
    const [isTransitionEnded, onEntered] = useToggleState();

    const isSlideTransition = !isTransitionEnded && isSmallScreen && withMobileSticky;
    const enteringTransitionDuration = theme.transitions.duration.enteringScreen;
    const transitionDuration = isTransitionEnded
        ? 0
        : isSmallScreen
          ? enteringTransitionDuration * 2
          : enteringTransitionDuration;

    return (
        <MuiDialogStyled
            {...props}
            TransitionProps={{ onEntered }}
            onClose={(_event, reason) => {
                if (reason === 'backdropClick' && disableCloseOnBackdropClick) {
                    return;
                }
                onClose?.();
            }}
            open={isOpen}
            TransitionComponent={isSlideTransition ? Slide : Fade}
            transitionDuration={transitionDuration}
            fullWidth={fullWidth}
            scroll={scrollable ? 'paper' : 'body'}
            maxWidth={maxWidth}
            withMobileSticky={withMobileSticky}
            aria-labelledby={ariaLabelledBy}
            blurredBackground={blurredBackground}
            components={components}
        >
            {children}
        </MuiDialogStyled>
    );
};
