import { useCallback, useMemo } from 'react';
import { startOfDay } from '@lingoda/dates';
import { useResolution } from '../../hooks';
import { CalendarContent } from './Calendar';
import { CalendarContainer } from './CalendarContainer';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { type DatePickerDotColor } from './styles';

export { type DatePickerDotColor };

export interface DatePickerProps {
    minDate?: Date;
    maxDate?: Date;
    loading?: boolean;
    dotColor?: DatePickerDotColor;
    scheduledDays?: Date[];
    scheduledDayTooltip?: string;
    scheduledDayBg?: string;
    selectedDayBg?: string;
    getIsCellDisabled?: (date: Date, { isSelected }: { isSelected?: boolean }) => boolean;
    onMonthChange?: (visibleMonth: Date) => void;
    withScroll?: boolean;
    testId?: string;
    showHoverStyles?: boolean;
    dates: Date[];
    onChange: (values: Date[]) => void;
    maxDatesSelected?: number;
    type?: 'single' | 'multiple';
    defaultFocusDate?: Date;
}

export const DatePicker = (props: DatePickerProps) => {
    const {
        minDate,
        maxDate,
        loading,
        dotColor = 'info',
        scheduledDays = [],
        scheduledDayBg,
        selectedDayBg,
        scheduledDayTooltip,
        onMonthChange,
        testId,
        withScroll: withScrollCustom,
        showHoverStyles: showHoverStylesCustom,
        onChange,
        dates,
        type = 'single',
        maxDatesSelected,
        getIsCellDisabled,
        defaultFocusDate,
    } = props;

    const isMobile = useResolution('phone');
    const withScroll = withScrollCustom ?? isMobile;
    const showHoverStyles = showHoverStylesCustom ?? !isMobile;

    const handleDatesSelected = useCallback(
        (valuesInLocalTz: Date[]) => {
            // don't need to convert to current TZ because what we're seeing in calendar, we should use these days without shifting
            onChange(valuesInLocalTz.map(startOfDay));
        },
        [onChange],
    );

    const handleMonthSelect = useCallback(
        (visibleMonth: Date) => {
            onMonthChange?.(visibleMonth);
        },
        [onMonthChange],
    );

    const customProps = useMemo(
        () => ({
            minDate,
            maxDate,
            onShownDateChange: handleMonthSelect,
            months: withScroll ? 2 : undefined,
        }),
        [handleMonthSelect, withScroll, maxDate, minDate],
    );

    return (
        <CalendarContainer
            loading={loading}
            dotColor={dotColor}
            scheduledDayBg={scheduledDayBg}
            selectedDayBg={selectedDayBg}
            showHoverStyles={showHoverStyles}
            isMobile={isMobile}
            data-cy={testId}
        >
            <CalendarContent
                withScroll={withScroll}
                type={type}
                dates={dates}
                onSelection={handleDatesSelected}
                maxDatesSelected={maxDatesSelected}
                customProps={customProps}
                scheduledDays={scheduledDays}
                scheduledDayTooltip={scheduledDayTooltip}
                getIsCellDisabled={getIsCellDisabled}
                defaultFocusDate={defaultFocusDate}
            />
        </CalendarContainer>
    );
};
