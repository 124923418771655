import { Box, Card, Divider, Loader, Stack } from '@lingoda/ui';
import { useIsLearningTeams } from '@lingoda/learning-teams';
import { useOnboardingAchievementQuery, useOnboardingChecklistQuery } from '@lingoda/graphql';
import { AchievementSlug } from '../../models';
import { getIsClaimable } from '../../utils/getIsClaimable';
import { ChecklistProgress, ChecklistStepAction, ChecklistSteps } from './components';
import { OnboardingCompleted } from './components/OnboardingCompleted/OnboardingCompleted';

export const OnboardingChecklist = () => {
    const slug = useIsLearningTeams()
        ? AchievementSlug.LT_ONBOARDING_CHECKLIST
        : AchievementSlug.ONBOARDING_CHECKLIST;

    const { data: onboardAchievementData, loading: onboardAchievementLoading } =
        useOnboardingAchievementQuery({
            variables: { slug },
        });

    const { data: checklistData, loading: isChecklistLoading } = useOnboardingChecklistQuery({
        variables: { slug },
    });
    const checklist = checklistData?.checklist || [];

    const achievement = onboardAchievementData?.studentAchievement;
    if (!achievement || achievement.claimed) {
        return null;
    }

    const isClaimable = getIsClaimable(achievement);

    if (onboardAchievementLoading) {
        return <Loader />;
    }

    if (isClaimable) {
        return <OnboardingCompleted achievement={achievement} />;
    }

    return (
        <Card>
            <Box>
                <ChecklistProgress checklist={checklist} achievement={achievement} />
                <Divider />
                {!isChecklistLoading && (
                    <Stack spacing={3} p={3}>
                        <ChecklistStepAction checklist={checklist} />
                        <ChecklistSteps checklist={checklist} />
                    </Stack>
                )}
            </Box>
        </Card>
    );
};
