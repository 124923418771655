import { lazy } from 'react';
import {
    type RouteConfigExtended,
    postCheckoutBookClassPath,
    postCheckoutCongratulationsPath,
    postCheckoutCreditsTypeSelectionPath,
    postCheckoutPath,
    postCheckoutProficiencyFindYourLevelPath,
    postCheckoutProficiencyLevelPath,
    postCheckoutProficiencyPath,
    postCheckoutProficiencySubLevelPath,
    postCheckoutProficiencySummaryPath,
    postCheckoutWelcomePath,
} from '@lingoda/router';

const Proficiency = lazy(() => import('./pages/Proficiency'));
const FindYourLevel = lazy(() => import('./pages/Proficiency/FindYourLevel'));
const Level = lazy(() => import('./pages/Proficiency/Level'));
const SubLevel = lazy(() => import('./pages/Proficiency/SubLevel'));
const Summary = lazy(() => import('./pages/Proficiency/Summary'));
const BookClass = lazy(() => import('./pages/BookClass'));
const Congratulations = lazy(() => import('./pages/Congratulations'));
const PostCheckout = lazy(() => import('./pages/PostCheckout'));
const Welcome = lazy(() => import('./pages/Welcome'));
const CreditsTypeSelection = lazy(() => import('./pages/CreditsTypeSelection'));

export const postCheckoutRoutes: RouteConfigExtended[] = [
    {
        component: PostCheckout,
        path: postCheckoutPath.raw,
        label: 'PostCheckout',
        config: {
            hideFooter: true,
            hideMobileBottomNavbar: true,
            hideHeader: true,
            tab: undefined,
        },
        routes: [
            {
                component: Proficiency,
                label: 'SelectProficiency',
                path: postCheckoutProficiencyPath.raw,
                config: {
                    hideFooter: true,
                    hideMobileBottomNavbar: true,
                    hideHeader: true,
                },
                routes: [
                    {
                        component: FindYourLevel,
                        path: postCheckoutProficiencyFindYourLevelPath.raw,
                        config: {
                            hideFooter: true,
                            hideMobileBottomNavbar: true,
                            hideHeader: true,
                        },
                    },
                    {
                        exact: true,
                        component: Level,
                        path: postCheckoutProficiencyLevelPath.raw,
                        config: {
                            hideFooter: true,
                            hideMobileBottomNavbar: true,
                            hideHeader: true,
                        },
                    },
                    {
                        exact: true,
                        component: SubLevel,
                        path: postCheckoutProficiencySubLevelPath.raw,
                        config: {
                            hideFooter: true,
                            hideMobileBottomNavbar: true,
                            hideHeader: true,
                        },
                    },
                    {
                        exact: true,
                        component: Summary,
                        path: postCheckoutProficiencySummaryPath.raw,
                        config: {
                            hideFooter: true,
                            hideMobileBottomNavbar: true,
                            hideHeader: true,
                        },
                    },
                ],
            },
            {
                component: BookClass,
                label: 'BookClass',
                path: postCheckoutBookClassPath.raw,
                config: {
                    hideFooter: true,
                    hideMobileBottomNavbar: true,
                    hideHeader: true,
                },
            },
            {
                component: CreditsTypeSelection,
                label: 'CreditsTypeSelection',
                path: postCheckoutCreditsTypeSelectionPath.raw,
                config: {
                    hideFooter: true,
                    hideMobileBottomNavbar: true,
                    hideHeader: true,
                },
            },
            {
                component: Congratulations,
                label: 'CongratulationsPage',
                path: postCheckoutCongratulationsPath.raw,
                config: {
                    hideFooter: true,
                    hideMobileBottomNavbar: true,
                    hideHeader: true,
                },
            },
            {
                component: Welcome,
                label: 'WelcomePage',
                path: postCheckoutWelcomePath.raw,
                config: {
                    hideFooter: true,
                    tab: undefined,
                    hideNavigation: true,
                    navigationElevation: 1,
                },
            },
        ],
    },
];
