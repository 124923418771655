import { cloneElement } from 'react';
import { Hidden, Stack, Typography } from '@lingoda/ui';
import { colorsPalette } from '@lingoda/ui/theme';

const FilterLabel = ({ id, children }: { id: string; children: string }) => (
    <Hidden only="xs">
        <label id={id}>
            <Typography variant="body3" component="span" htmlColor={colorsPalette.blackAlpha[70]}>
                {children}
            </Typography>
        </label>
    </Hidden>
);

export const FilterGroup = ({
    id,
    label,
    children,
}: {
    id: string;
    label?: string;
    children: React.ReactElement;
}) => (
    <Stack spacing={1}>
        {label && <FilterLabel id={id}>{label}</FilterLabel>}
        {cloneElement(children, {
            labelId: id,
            sx: { height: '2.85rem' },
        })}
    </Stack>
);
