import { type Ref } from 'react';
import IconButton, { type IconButtonProps } from '@lingoda/ui/buttons/IconButton';
import { PlayAudioIcon24, type PlayAudioIconAnimation } from '@lingoda/ui/icons/PlayAudioIcon';
import { Box, colorsPalette, styled } from '@lingoda/ui';
import CircularProgress from '@lingoda/ui/feedback/CircularProgress';

export interface ButtonLayoutProps extends IconButtonProps {
    loading?: boolean;
    animationRef?: Ref<PlayAudioIconAnimation>;
}

export const ButtonLayout = (props: ButtonLayoutProps) => {
    const { loading, animationRef, ...passProps } = props;

    return (
        <IconButtonStyled {...passProps}>
            {props.loading ? <Loading /> : <PlayAudioIcon24 animationRef={animationRef} />}
        </IconButtonStyled>
    );
};

const IconButtonStyled = styled(IconButton)(() => ({
    color: colorsPalette.blackAlpha[70],

    ':hover': {
        ...buttonHoverStyles,
    },
})) as typeof IconButton;

export const buttonHoverStyles = {
    color: colorsPalette.primarySolid[100],
};

const Loading = () => {
    return (
        <Box display="inline-flex" p={0.5}>
            <CircularProgress color="inherit" size={16} />
        </Box>
    );
};
