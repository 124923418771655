import { useMemo } from 'react';
import { UserCredits } from '@lingoda/credits';
import { Box, Loader, Paper, Stack, Tooltip, Typography } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import type { UserCreditsQuery } from '@lingoda/graphql';
import { Location, useUserCreditsQuery } from '@lingoda/graphql';
import { Info } from '@lingoda/ui/icons';
import { colorsPalette } from '@lingoda/ui/theme';
import { ContentBlockTypography, findContentBlock } from '@lingoda/content-blocks';

const MyClassCreditsContainer = ({ children }: React.PropsWithChildren) => (
    <Paper elevation={2} data-cy="class-credit-container">
        <Box p={3}>
            <Typography variant="h2" gutterBottom>
                {trans('my-class-credits', {}, 'student-common')}
            </Typography>
            {children}
        </Box>
    </Paper>
);

export const ClassCredits = () => {
    const { loading, data } = useUserCreditsQuery({ fetchPolicy: 'cache-and-network' });

    return (
        <MyClassCreditsContainer>
            {loading || !data ? (
                <Loader
                    minHeight={180}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                />
            ) : (
                <Content creditsInfo={data.studentCreditsInfo} />
            )}
        </MyClassCreditsContainer>
    );
};

interface ContentProps {
    creditsInfo: UserCreditsQuery['studentCreditsInfo'];
}

const Content = ({
    creditsInfo: { groupCredits, privateCredits, contentBlocks },
}: ContentProps) => {
    const content = useMemo(
        () => ({
            renew: findContentBlock(contentBlocks, Location.CreditsRenew),
            expire: findContentBlock(contentBlocks, Location.CreditsExpire),
        }),
        [contentBlocks],
    );

    return (
        <Stack gap={3}>
            <UserCredits groupCredits={groupCredits} privateCredits={privateCredits} showAll />
            <Stack gap={0.5}>
                <ContentBlockTypography {...content.renew} variant="body1" />

                <Box display="flex" alignItems="flex-end">
                    <Box component="span" color={colorsPalette.blackAlpha[70]}>
                        <ContentBlockTypography
                            {...content.expire}
                            showLabel
                            display="inline"
                            variant="body2"
                        />

                        <Tooltip
                            component="span"
                            title={trans(
                                'credits-active-subscription-warning',
                                {},
                                'student-store',
                            )}
                        >
                            <Info fontSize="small" />
                        </Tooltip>
                    </Box>
                </Box>
            </Stack>
        </Stack>
    );
};
