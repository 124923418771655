import { memo, useCallback, useMemo, useState } from 'react';
import { Block, Box, Hidden, Loader, Stack, useInfiniteScroll, useUiEvent } from '@lingoda/ui';
import { useSelector } from '@lingoda/hooks';
import { createDate } from '@lingoda/dates';
import { type BookableClassFragment } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';
import { actionClick } from '@lingoda/analytics';
import {
    type BookingFilter,
    BookingView,
    isAbleToBookSelector,
    processSearchClasses,
} from '@lingoda/booking';
import {
    BookingSearchFrameContainer,
    ClassesListHeader,
    ClassesPlaceholder,
    DayBox,
    DayLabel,
    NoClassesFound,
    SectionTitle,
} from '@lingoda/booking-ui';
import {
    UseLatestChromiumBanner,
    getIsBrowserSupportingAllClassroomFeatures,
} from '@lingoda/browser-support';
import { ClassesInClassroomAlert } from '@lingoda/student-banner';
import { ClassesGrid } from '../ClassesGrid';
import { ClassesList } from '../ClassesList';
import { type BookableClassElementProps } from '../BookableClassElement';
import { DayNavigation } from '../DayNavigation';
import { getFetchMoreDate } from './utils';

interface AvailableClassesProps {
    isEmbedded?: boolean;
    bookingFilter: BookingFilter;
    bookingView: BookingView;
    stickyFilterHeight?: number;
    bgColor?: string;
    classes: ReadonlyArray<BookableClassFragment>;
    fetchMore: (startDate: Date) => Promise<ReadonlyArray<BookableClassFragment>>;
    onBooked?: BookableClassElementProps['onBooked'];
    onFilterChange: (newFilter: Partial<BookingFilter>) => void;
    loading: boolean;
    nextAvailableDate: Date | undefined;
    showClassroomPromoBanner?: boolean;
}

const AvailableClassesComponent = ({
    isEmbedded,
    bookingFilter,
    bookingView,
    stickyFilterHeight,
    bgColor,
    classes,
    fetchMore,
    onBooked,
    onFilterChange,
    loading,
    nextAvailableDate,
    showClassroomPromoBanner,
}: AvailableClassesProps) => {
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [blockRef, attachBlockRef] = useState<HTMLDivElement | null>(null);
    const addEvent = useUiEvent();
    const isAbleToBook = useSelector(isAbleToBookSelector);

    const lastClass = classes[classes.length - 1];
    const ClassesLayoutComponent = bookingView === BookingView.List ? ClassesList : ClassesGrid;
    const classesList = useMemo(
        () => processSearchClasses(classes, bookingFilter.dates),
        [bookingFilter.dates, classes],
    );

    const handleShowMore = useCallback(async () => {
        if (!hasMoreItems || !lastClass) {
            return;
        }

        const startDate = getFetchMoreDate(createDate(lastClass.startDate));

        const moreClasses = await fetchMore(startDate);

        if (!moreClasses.length) {
            setHasMoreItems(false);
        }
    }, [hasMoreItems, lastClass, fetchMore]);

    const handleNextAvailableDate = useCallback(() => {
        void addEvent(actionClick('EarliestAvailableDate'));
        nextAvailableDate && onFilterChange({ dates: [nextAvailableDate] });
    }, [nextAvailableDate, onFilterChange, addEvent]);

    const fetching = useInfiniteScroll({
        callback: handleShowMore,
        element: blockRef,
    });

    const noClassesFound = classes.length === 0;
    const onlyOneDaySelected = bookingFilter.dates.length === 1;
    const someDaysSelected = bookingFilter.dates.length > 0;

    return (
        <BookingSearchFrameContainer data-cy="Available classes">
            {showClassroomPromoBanner && (
                <Box mt={3}>
                    <ClassesInClassroomAlert markAsDisplayedOnMount>
                        {!getIsBrowserSupportingAllClassroomFeatures() && (
                            <UseLatestChromiumBanner
                                title="multiple-classes"
                                severity="warning"
                                variant="outlined"
                            />
                        )}
                    </ClassesInClassroomAlert>
                </Box>
            )}
            {!isEmbedded && (someDaysSelected || noClassesFound) && (
                <Hidden smDown>
                    <Box mt={4}>
                        <SectionTitle title={trans('available-classes', {}, 'student-common')} />
                    </Box>
                </Hidden>
            )}
            {loading ? (
                <ClassesPlaceholder bookingView={bookingView} />
            ) : (
                <>
                    {noClassesFound && !onlyOneDaySelected ? (
                        <Box mt={3}>
                            <NoClassesFound
                                nextAvailableDate={nextAvailableDate}
                                onNextAvailableDateClick={handleNextAvailableDate}
                            />
                        </Box>
                    ) : (
                        <>
                            {Object.entries(classesList).map(([day, slots]) => (
                                <Stack key={day} data-cy="Classes list day">
                                    <ClassesListHeader
                                        stickyFilterHeight={stickyFilterHeight}
                                        bgColor={bgColor}
                                    >
                                        <DayBox>
                                            <DayLabel label={day} />
                                            {onlyOneDaySelected && (
                                                <DayNavigation
                                                    bookingFilter={bookingFilter}
                                                    onFilterChange={onFilterChange}
                                                />
                                            )}
                                        </DayBox>
                                    </ClassesListHeader>

                                    {slots.length == 0 ? (
                                        <NoClassesFound
                                            nextAvailableDate={
                                                onlyOneDaySelected ? nextAvailableDate : undefined
                                            }
                                            onNextAvailableDateClick={handleNextAvailableDate}
                                        />
                                    ) : (
                                        <ClassesLayoutComponent
                                            isAbleToBook={isAbleToBook}
                                            slots={slots}
                                            isEmbedded={isEmbedded}
                                            onBooked={onBooked}
                                            bookingFilter={bookingFilter}
                                        />
                                    )}
                                </Stack>
                            ))}
                            {!someDaysSelected && (
                                <Box my={4} height={40}>
                                    {fetching && <Loader minHeight={40} height={40} />}
                                </Box>
                            )}
                        </>
                    )}
                    {hasMoreItems && !someDaysSelected && !fetching && (
                        <Block ref={attachBlockRef} />
                    )}
                </>
            )}
        </BookingSearchFrameContainer>
    );
};

export const AvailableClasses = memo(AvailableClassesComponent);
