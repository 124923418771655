import { Box, Divider, useResolution } from '@lingoda/ui';
import { Grid } from '@lingoda/ui/layout/GridNext';
import { isMixedCreditsEnabled as isMixedCreditsFeatureEnabled } from '@lingoda/feature-flags';
import { CurrencyFilter } from './CurrencyFilter';
import { ClassTypeFilter } from './ClassTypeFilter';
import { PlanTypeFilter } from './PlanTypeFilter';
import { CurriculumFilter } from './CurriculumFilter';
import type { FiltersOptions, FiltersState, UpdateFilterFn } from '../../types';

interface Props {
    options: FiltersOptions;
    filtersState: FiltersState | null;
    onFilterUpdate: UpdateFilterFn;
    isLearningLanguageChange?: boolean;
    hasMixedCredits: boolean;
}

export const Filters = ({
    options,
    filtersState,
    onFilterUpdate,
    isLearningLanguageChange,
    hasMixedCredits,
}: Props) => {
    const isTablet = useResolution('tablet');
    const isMobile = useResolution('phone');

    if (!filtersState) {
        return null;
    }

    const { curriculumSlug, classType, currency, planType } = filtersState;
    const showCurriculumFilter =
        options.sectionCurriculums.length > 1 && !isTablet && !isLearningLanguageChange;

    const isMixedCreditsEnabled = isMixedCreditsFeatureEnabled();
    const hideClassTypeFilters = isMixedCreditsEnabled && hasMixedCredits;

    return (
        <Grid container spacing={3} justifyContent="flex-end" alignItems="flex-end">
            {showCurriculumFilter && (
                <Grid xs={12} sm order={0}>
                    <CurriculumFilter
                        options={options.sectionCurriculums}
                        selectedSlug={curriculumSlug}
                        onFilterUpdate={onFilterUpdate}
                        fullWidth={isMobile}
                    />
                    {isMobile && (
                        <Box mt={4} mb={1}>
                            <Divider />
                        </Box>
                    )}
                </Grid>
            )}

            {options.classTypes.length > 1 && !hideClassTypeFilters && (
                <Grid xs sm="auto" order={1}>
                    <ClassTypeFilter
                        options={options.classTypes}
                        classType={classType}
                        onFilterUpdate={onFilterUpdate}
                        fullWidth={isMobile}
                    />
                </Grid>
            )}

            {options.planTypes.length > 1 && (
                <Grid xs={12} sm="auto" order={{ sm: 2, md: 1 }}>
                    <PlanTypeFilter
                        options={options.planTypes}
                        planType={planType}
                        onFilterUpdate={onFilterUpdate}
                        fullWidth={isMobile}
                    />
                </Grid>
            )}

            <Grid order={2}>
                <CurrencyFilter currency={currency} onFilterUpdate={onFilterUpdate} />
            </Grid>
        </Grid>
    );
};
