import { type AcceptedLang } from '@lingoda/env';

type HotJarEvent = `${'dismiss_rollover_'}${AcceptedLang}`; // to add more handled events here and provide type safety

export const addHotJarEvent = (eventName: HotJarEvent) => {
    if (typeof hj !== 'function') {
        return; // we accept that HotJar is not loaded as we load it only on production
    }
    hj('event', eventName);
};
