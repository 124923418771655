import { createDate, isBefore } from '@lingoda/dates';
import { useStudentPaymentMethodAddedQuery } from '@lingoda/graphql';

export const useIsPaymentMethodRequired = () => {
    const { data } = useStudentPaymentMethodAddedQuery({
        fetchPolicy: 'cache-and-network',
    });

    if (!data?.subscriptions?.currentSubscription) {
        return false;
    }

    const { expiresAt, paymentMethodAdded } = data.subscriptions.currentSubscription;
    const isSubscriptionActive = expiresAt && isBefore(createDate(), createDate(expiresAt));

    // TRUE if user part of frictionless free trial but no payment method added yet
    return isSubscriptionActive && paymentMethodAdded === false;
};
