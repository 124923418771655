import { injectAsyncReducer } from '@lingoda/core';
import { STORE_KEY } from './constants';
import reducers from './reducers';

export * from './actions';
export * from './models';
export * from './selectors';
export * from './logic/getDefaultCurrency';
export * from './logic/getSupportedCurrency';
export * from './utils';

injectAsyncReducer(STORE_KEY, reducers);
