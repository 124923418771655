export enum DialogComponent {
    GenericInfoMessageDialog = 'GenericInfoMessageDialog',
    BookingBlockedByPause = 'BookingBlockedByPause',
    BookingBlockedBySubsExpiry = 'BookingBlockedBySubsExpiry',
    CancelClass = 'CancelClass',
    ClassReview = 'ClassReview',
    ConfirmBooking = 'ConfirmBooking',
    DeletePaymentMethod = 'DeletePaymentMethod',
    FlashCardTipsDialog = 'FlashCardTipsDialog',
    MobileBookingDialog = 'MobileBookingDialog',
    MobileClassFilterDialog = 'MobileClassFilterDialog',
    MobileCreditsPurchaseDialog = 'MobileCreditsPurchaseDialog',
    MobileCurriculumFilterDialog = 'MobileCurriculumFilterDialog',
    MobileFlashCardDialog = 'MobileFlashCardDialog',
    MobileModuleSelectDialog = 'MobileModuleSelectDialog',
    MobilePreClassChecklistDialog = 'MobilePreClassChecklistDialog',
    MobilePushNotificationReminderPicker = 'MobilePushNotificationReminderPicker',
    MobileVocabularyDialog = 'MobileVocabularyDialog',
    NoCredits = 'NoCredits',
    PrivateClass = 'PrivateClass',
    PurchaseOnWebsiteDialog = 'PurchaseOnWebsiteDialog',
    ResumeCourse = 'ResumeCourse',
    RolloverSuccessDialog = 'RolloverSuccessDialog',
    SubscribeUnlockDialog = 'SubscribeUnlockDialog',
    UpdatePaymentMethod = 'UpdatePaymentMethod',
    SelfLearningLimitAccess = 'SelfLearningLimitAccess',
    SetGoalWeeklyClasses = 'SetGoalWeeklyClasses',
    SetGoalWeeklyWords = 'SetGoalWeeklyWords',
    GoalAchievedCelebration = 'GoalAchievedCelebration',
    Achievement = 'Achievement',
    AppLanguageDialog = 'AppLanguageDialog',
    OnboardingChecklist = 'OnboardingChecklist',
    YearlyRecapDialog = 'YearlyRecapDialog',
    ConfirmCalendarDisconnectDialog = 'ConfirmCalendarDisconnectDialog',
    ConnectCalendarDialog = 'ConnectCalendarDialog',
    PostFFreeTrialDialog = 'PostFFreeTrialDialog',
    FFreeTrialWelcomeDialog = 'FFreeTrialWelcomeDialog',
    FFreeTrialInformDialog = 'FFreeTrialInformDialog',
    AddPaymentMethod = 'AddPaymentMethod',
    LearningGoalsDialog = 'LearningGoalsDialog',
    DeleteLearningGoalOrNeedsConfirmationDialog = 'DeleteLearningGoalOrNeedsConfirmationDialog',
    AddStudentLanguageSpokenDialog = 'AddStudentLanguageSpokenDialog',
    DeleteStudentLanguageSpokenDialog = 'DeleteStudentLanguageSpokenDialog',
    EditStudentLanguageSpokenDialog = 'EditStudentLanguageSpokenDialog',
    LearningNeedsDialog = 'LearningNeedsDialog',
}

export const STORE_KEY = 'dialogs';
