import { type PropsWithChildren } from 'react';
import { Box } from '../Box';
import { Grid } from '../GridNext';

export const PageContentFrame = ({ children }: PropsWithChildren<unknown>) => (
    <Grid container justifyContent="center">
        <Grid xs={12} sm={10}>
            <Box m={1}>{children}</Box>
        </Grid>
    </Grid>
);
