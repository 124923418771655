import { memo, useCallback } from 'react';
import {
    FormControl,
    InputLabel,
    type InputLabelProps,
    Select,
    type SelectProps,
} from '@lingoda/ui/fields';
import { type Currency } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';

interface OwnProps {
    labelProps?: InputLabelProps;
    currency: Currency;
    currencies: ReadonlyArray<Currency>;
    onChange: (currency: Currency) => void;
}

type Props = Omit<SelectProps, 'onChange' | 'options' | 'getOptionLabel' | 'getOptionValue'> &
    OwnProps;

const CurrencyField = ({ labelProps, ...props }: Props) => {
    const { fullWidth, required, currency, currencies, onChange } = props;

    const handleChange = useCallback<Required<SelectProps>['onChange']>(
        (event) => {
            onChange(event.target.value as Currency);
        },
        [onChange],
    );

    return (
        <FormControl fullWidth={fullWidth} required={required} variant="outlined">
            <InputLabel id={`${props.name}_label`} {...labelProps}>
                {trans('currency', {}, 'student-store')}
            </InputLabel>
            <Select
                sx={{ textAlign: 'left' }}
                color="secondary"
                {...props}
                labelId={`${props.name}_label`}
                label={trans('currency', {}, 'student-store')}
                value={currency}
                onChange={handleChange}
                options={currencies}
                getOptionValue={(currency) => currency}
                getOptionLabel={(currency) => currency}
            />
        </FormControl>
    );
};

export default memo(CurrencyField);
