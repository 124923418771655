import { config } from '@lingoda/config';
import type { GoogleWindow } from '../utils';

declare const window: GoogleWindow;

export const actualizeMatomoData = (visitorCode: string, features: Record<string, string>) => {
    const formattedLocalStorageValue = Object.entries(features)
        .map(([key, variation]) => `${key}_${variation}`)
        .join(',');
    const _paq = (window._paq = window._paq ?? []);

    _paq.push(['setCustomDimension', 4, formattedLocalStorageValue]);
    _paq.push(['setCustomDimension', 5, visitorCode]);
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);

    const url = config.matomoUrl;
    const trackerUrl = `${'https:' === document.location.protocol ? 'https' : 'http'}:${url}`;
    _paq.push(['setTrackerUrl', trackerUrl + 'matomo.php']);
    _paq.push(['setSiteId', '1']);
    const d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript';
    g.async = true;
    g.defer = true;
    g.src = trackerUrl + 'matomo.js';
    s.parentNode?.insertBefore(g, s);
};
