import { forwardRef, useMemo } from 'react';
import { Box, Link, Typography } from '@lingoda/ui';
import { styled } from '@lingoda/ui/theme';
import { subscriptionsStorePath } from '@lingoda/router';
import { useUserSubscriptionsQuery } from '@lingoda/graphql';
import { createDate, diffInUnits, isBefore } from '@lingoda/dates';
import { useSelector } from '@lingoda/hooks';
import { studentCreditsSelector } from '@lingoda/students';
import { FormattedMessage, trans } from '@lingoda/i18n';
import { useIsFFreeTrialOrWasInTrial } from '../hooks';

const FFreeTrialCountdownNotificationBarBase = forwardRef<HTMLDivElement>((_, ref) => {
    const userSubscriptions = useUserSubscriptionsQuery();
    const currentSubscription = userSubscriptions.data?.subscriptions?.currentSubscription;
    const { total: credits } = useSelector(studentCreditsSelector);

    const timeLeft = useMemo(() => {
        if (!currentSubscription || !currentSubscription.expiresAt) {
            return null;
        }

        const now = createDate();
        const expiresAt = createDate(currentSubscription.expiresAt);

        if (isBefore(expiresAt, now)) {
            return null;
        }

        const daysLeft = diffInUnits('days', expiresAt, now);
        if (daysLeft >= 1) {
            return trans('days', { days: daysLeft }, 'student-f-free-trial+intl-icu');
        }

        return trans(
            'hours',
            { hours: diffInUnits('hours', expiresAt, now) },
            'student-f-free-trial+intl-icu',
        );
    }, [currentSubscription]);

    const title =
        timeLeft !== null ? (
            <FormattedMessage
                message={trans(
                    'free-trial-countdown-notification',
                    {
                        timeLeft,
                        credits,
                    },
                    'student-f-free-trial+intl-icu',
                )}
                values={{
                    b: (chunks) => <strong>{chunks}</strong>,
                }}
            />
        ) : (
            trans('free-trial-ended', {}, 'student-f-free-trial+intl-icu')
        );

    return (
        <BgWrapper p={2} textAlign="center" ref={ref}>
            <Typography variant="body1">
                {title}
                {(credits === 0 || timeLeft === null) && (
                    <>
                        {' '}
                        <Link color={'inherit'} to={subscriptionsStorePath()}>
                            {trans('explore-plans', {}, 'student-f-free-trial+intl-icu')}
                        </Link>
                    </>
                )}
            </Typography>
        </BgWrapper>
    );
});

FFreeTrialCountdownNotificationBarBase.displayName = 'FFreeTrialCountdownNotificationBarBase';

export const FFreeTrialCountdownNotificationBar = forwardRef<HTMLDivElement>((_, ref) => {
    const isFFreeTrialOrWasInTrial = useIsFFreeTrialOrWasInTrial();

    return isFFreeTrialOrWasInTrial ? <FFreeTrialCountdownNotificationBarBase ref={ref} /> : null;
});

FFreeTrialCountdownNotificationBar.displayName = 'FFreeTrialCountdownNotificationBar';

const BgWrapper = styled(Box)(({ theme }) => ({
    background: theme.palette.gradients.goldLingobites,
}));
