import { getStorageValue, removeStorageValue, setStorageValue } from '@lingoda/utils';
import { createDate, differenceInHours, getTime } from '@lingoda/dates';

export const FFTRIAL_DIALOG_LOCALSTORAGE_KEY = 'fftrial-dialog';
export const DELAY_HOURS = 24;
export const isItTimeToShowPostFFreeTrialDialog = () => {
    const lastShown = getStorageValue<string>(FFTRIAL_DIALOG_LOCALSTORAGE_KEY);
    if (!lastShown) {
        return true;
    }

    const now = createDate();
    const lastShownDate = createDate(lastShown);

    return differenceInHours(now, lastShownDate) >= DELAY_HOURS;
};

export const cleanUpPostFFreeTrialDialogCloseDate = () => {
    removeStorageValue(FFTRIAL_DIALOG_LOCALSTORAGE_KEY);
};

export const savePostFFreeTrialDialogCloseDate = () => {
    setStorageValue(FFTRIAL_DIALOG_LOCALSTORAGE_KEY, getTime(createDate()));
};
