import { lazy } from 'react';
import type { RouteConfigExtended, RouteConfigOption } from '@lingoda/router';
import {
    RouteAccess,
    addRouterListener,
    directRegisterSectionPath,
    m2mRegisterSectionPath,
    m2mSignupSectionPath,
    optionalArg,
    registerSectionSprintPath,
    sprintSignupPath,
    teamsRegisterSectionPath,
    teamsSignupPath,
} from '@lingoda/router';
import { getLang } from '@lingoda/env';
import { sectionNames } from '@lingoda/sections';
import { sectionsTranslationMapping } from '@lingoda/config';
import { colorsPalette } from '@lingoda/ui';
import SprintSignup from '@lingoda/student-frontend/sprint/pages/SprintSignup';
import TeamsSignup from '@lingoda/student-frontend/teams/pages/TeamsSignup';
import M2MSignup from '@lingoda/student-frontend/auth/pages/M2MSignup';
import addSectionListener from '../../../hoc/addSectionListener';

const RegisterSection = lazy(() => import('../components/pages/Register'));
const RegisterSprintPage = lazy(() => import('../components/pages/RegisterSprint'));

const DirectRegisterSection = addSectionListener(
    addRouterListener('directRegisterSectionPath')(RegisterSection),
);

const M2MRegisterSection = addSectionListener(
    addRouterListener('m2mRegisterSectionPath')(RegisterSection),
);

const TeamsRegisterSection = addSectionListener(
    addRouterListener('teamsRegisterSectionPath')(RegisterSection),
);

const SprintRegisterSection = addSectionListener(
    addRouterListener('registerSectionSprintPath')(RegisterSprintPage),
);

const RouteSprintSignup = addSectionListener(addRouterListener('springSignupPath')(SprintSignup));

const RouteM2MSignup = addSectionListener(addRouterListener('m2mSignupSectionPath')(M2MSignup));

const RouteTeamsSignup = addSectionListener(addRouterListener('teamsSignupPath')(TeamsSignup));

const wrapSectionPath = (items: RouteConfigExtended[]) => {
    const lang = getLang();

    const translatedSection = sectionNames.map(
        (section) => sectionsTranslationMapping[section][lang],
    );

    return items.map((route) => ({
        ...route,
        path: (route.path as string).replace(
            ':section',
            `:section(${translatedSection.join('|')})`,
        ),
    }));
};

const COMMON_CONFIG: RouteConfigOption = {
    contentBg: colorsPalette.primarySolid['12'],
    access: RouteAccess.Public,
    hideHeader: true,
    hideFooter: true,
    theme: 'light' as const,
    tab: undefined,
    hideNotifications: true,
};

const COMMON_ROUTE_PROPS = {
    exact: true,
    label: 'RegisterSection',
};

const registrationRoutes: RouteConfigExtended[] = [
    {
        ...COMMON_ROUTE_PROPS,
        config: { ...COMMON_CONFIG },
        path: optionalArg(directRegisterSectionPath.raw, 'curriculum'),
        component: DirectRegisterSection,
    },
    {
        ...COMMON_ROUTE_PROPS,
        config: { ...COMMON_CONFIG },
        path: m2mRegisterSectionPath.raw,
        component: M2MRegisterSection,
    },
    {
        ...COMMON_ROUTE_PROPS,
        config: { ...COMMON_CONFIG },
        path: m2mSignupSectionPath.raw,
        component: RouteM2MSignup,
        label: 'M2MSRegisterLoader',
    },
    {
        ...COMMON_ROUTE_PROPS,
        config: { ...COMMON_CONFIG },
        path: optionalArg(registerSectionSprintPath.raw, 'curriculum'),
        component: SprintRegisterSection,
    },
    {
        ...COMMON_ROUTE_PROPS,
        config: { ...COMMON_CONFIG },
        path: teamsRegisterSectionPath.raw,
        component: TeamsRegisterSection,
    },
    {
        exact: true,
        label: 'SprintSignupLoader',
        config: {
            ...COMMON_CONFIG,
        },
        path: optionalArg(sprintSignupPath.raw, 'curriculum'),
        component: RouteSprintSignup,
    },
    {
        exact: true,
        label: 'TeamsSignupLoader',
        config: {
            ...COMMON_CONFIG,
        },
        path: teamsSignupPath.raw,
        component: RouteTeamsSignup,
    },
];

export default wrapSectionPath(registrationRoutes);
