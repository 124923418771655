import {
    type Path,
    Redirect,
    freeTrialPurchasePath,
    m2mRegisterSectionPath,
    packagePurchasePath,
    socialRegisterConfirmPath,
    useLocation,
    useParams,
} from '@lingoda/router';
import { type SectionCurriculumSlug, transformSectionName } from '@lingoda/sections';
import { getQueryParams } from '@lingoda/urls';
import type { AcceptedLang } from '@lingoda/env';
import type { Currency } from '@lingoda/graphql';
import { assert } from '@lingoda/utils';
import { getSocialRegisterSessionInfo } from '@lingoda/student-auth';
import { getSupportedCurrency } from '@lingoda/currency';

const M2MSignup = () => {
    const { search } = useLocation();
    const { section, curriculum, locale } = useParams<{
        section: SectionName;
        locale: AcceptedLang;
        curriculum: SectionCurriculumSlug;
    }>();
    const { currency, trial, packageId, freeTrial } = getQueryParams<{
        currency: Currency;
        packageId: string;
        trial: string;
        freeTrial: string;
    }>(search);

    assert(packageId, 'Missing packageId in URL from Wordpress');
    const validatedCurrency = getSupportedCurrency(currency as Currency);
    let path: Path;
    const redirect =
        freeTrial === 'true'
            ? freeTrialPurchasePath({
                  packageId: packageId.toString(),
              })
            : packagePurchasePath({
                  packageId: packageId.toString(),
                  currency: validatedCurrency,
                  trial: trial === 'true',
                  section: section.toString(),
              });

    const params = { locale, section: transformSectionName(section), curriculum, redirect };

    const socialRegisterSessionInfo = getSocialRegisterSessionInfo();
    if (socialRegisterSessionInfo) {
        path = socialRegisterConfirmPath(params);
    } else {
        path = m2mRegisterSectionPath(params);
    }

    return <Redirect to={path} />;
};

export default M2MSignup;
