import {
    Button,
    Img,
    Paper,
    Stack,
    Typography,
    colorsPalette,
    styled,
    useResolution,
} from '@lingoda/ui';
import type { Achievement } from '@lingoda/graphql';
import { useOnboardingChecklistQuery } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';
import { useDispatch } from '@lingoda/hooks';
import { DialogComponent, showDialog } from '@lingoda/dialogs';
import { goTo, studentAchievementsPath } from '@lingoda/router';
import ProgressBadge from '../../../../static/icons/progress-badge.svg';
import { useClaimReward, useTrackClaimRewardOnboarding } from '../../../../hooks';
import { AchievementSlug } from '../../../../models';
import stars from './stars.svg';

interface OnboardingCompleted {
    achievement: Achievement;
}

export const OnboardingCompleted = ({ achievement }: OnboardingCompleted) => {
    const isPhone = useResolution('phone');
    const badgeWidth = !isPhone ? 97 : 72;
    const { claimId, slug } = achievement;
    const dispatch = useDispatch();
    const { trackClaimRewardOnboarding } = useTrackClaimRewardOnboarding();

    const { data } = useOnboardingChecklistQuery({
        variables: { slug },
    });
    const checklist = data?.checklist || [];
    const [claimReward] = useClaimReward({
        onCompleted: ({ claimAttendanceAchievement }) => {
            dispatch(
                showDialog(DialogComponent.Achievement, {
                    achievement: claimAttendanceAchievement,
                    checklist,
                    animateClaiming: true,
                    onSeeAllClick: () => goTo(studentAchievementsPath()),
                }),
            );
        },
    });

    const handleOnClaim = async () => {
        if (claimId) {
            if (slug === AchievementSlug.ONBOARDING_CHECKLIST) {
                trackClaimRewardOnboarding();
            }
            await claimReward(claimId);
        }
    };

    return (
        <Paper>
            <ContainerStack>
                <Stack>
                    <Img src={ProgressBadge} width={badgeWidth} />
                </Stack>
                <Stack display="flex" flexDirection="column" alignItems="flex-start">
                    <Typography variant="h3" sx={{ color: colorsPalette.whiteSolid[100] }}>
                        {trans('congratulations-title', {}, 'get-started')}
                    </Typography>
                    <Typography
                        mt={1}
                        variant="body2"
                        sx={{ color: colorsPalette.whiteSolid[100] }}
                    >
                        {trans('onboarding-completed-description', {}, 'get-started')}
                    </Typography>
                    <Button
                        sx={{ marginTop: 2 }}
                        variant="contained"
                        color="grayscale"
                        onClick={handleOnClaim}
                    >
                        {trans('btn-claim-reward', {}, 'student-achievements')}
                    </Button>
                </Stack>
            </ContainerStack>
        </Paper>
    );
};

const ContainerStack = styled(Stack)(({ theme }) => ({
    background: `
        url(${stars}) no-repeat center center,
        linear-gradient(90deg, #5B76FF -46.17%, #A04DDB 136.05%)
    `,
    backgroundSize: 'cover',
    padding: theme.spacing(3),
    display: 'flex',
    borderRadius: theme.spacing(1),
    flexDirection: 'row',
    gap: theme.spacing(3),
}));
