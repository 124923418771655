import MuiAutocomplete from '@mui/material/Autocomplete';
import TextField from '../TextField';
import { Box } from '../../layout/Box';
import { CheckmarkIcon24, ChevronIcon16 } from '../../icons/lingodaIcons';
import type { TextFieldProps } from '../TextField';
import type { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';
import type { ReactNode } from 'react';

export { autocompleteClasses } from '@mui/material/Autocomplete';
export type { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';

export interface AutocompleteProps<T>
    extends MuiAutocompleteProps<T, boolean, boolean, boolean>,
        Pick<TextFieldProps, 'label' | 'placeholder'> {
    key?: string;
    getOptionIcon?: (value: T) => ReactNode;
    hideCheckMark?: boolean;
}

export const Autocomplete = <T,>({
    label,
    placeholder,
    getOptionIcon,
    getOptionLabel,
    hideCheckMark,
    ...rest
}: Omit<AutocompleteProps<T>, 'renderInput' | 'renderOption'>) => {
    const renderInput: AutocompleteProps<T>['renderInput'] = (params) => (
        <TextField label={label} placeholder={placeholder} {...params} />
    );

    const renderOption: AutocompleteProps<T>['renderOption'] = (props, value, { selected }) => (
        <li {...props} key={(props as { key?: string }).key}>
            <Box flex={1}>
                {getOptionIcon?.(value)} {getOptionLabel?.(value) || getFallbackLabel(value)}
            </Box>
            {selected && !hideCheckMark && <CheckmarkIcon24 color="primary" />}
        </li>
    );

    return (
        <MuiAutocomplete
            popupIcon={<ChevronIcon16 fontSize="small" />}
            renderInput={renderInput}
            renderOption={renderOption}
            getOptionLabel={getOptionLabel}
            disableClearable
            {...rest}
        />
    );
};

const getFallbackLabel = (value: unknown) =>
    typeof value === 'string' || typeof value === 'number' ? value : '';
