import { useCallback, useEffect, useRef, useState } from 'react';

export const useAudio = (): UseAudioReturn => {
    const [audio, setAudio] = useState<HTMLAudioElement>();

    const [loadState, setLoadState] = useState<LoadState>(LoadState.Idle);
    const [playState, setPlayState] = useState<PlayState>(PlayState.Idle);

    useEffect(() => {
        if (!audio) {
            return;
        }

        setLoadState(getCurrentAudioLoadState(audio));
        setPlayState(getCurrentAudioPlayState(audio));

        const handleLoadStart = () => {
            setLoadState(LoadState.Loading);
        };

        const handleCanPlayThrough = () => {
            setLoadState(LoadState.Loaded);
        };

        const handleError = () => {
            setLoadState(LoadState.Error);
        };

        // `load()` was called
        const handleEmptied = () => {
            setLoadState(LoadState.Idle);
            setPlayState(PlayState.Idle);
        };

        const handlePause = () => {
            setPlayState(PlayState.Paused);
        };

        const handlePlay = () => {
            setPlayState(PlayState.Playing);
        };

        const handlePlaying = () => {
            setPlayState(PlayState.Playing);
        };

        const handleEnded = () => {
            setPlayState(PlayState.Finished);
        };

        audio.addEventListener('loadstart', handleLoadStart);
        audio.addEventListener('canplaythrough', handleCanPlayThrough);
        audio.addEventListener('error', handleError);
        audio.addEventListener('emptied', handleEmptied);
        audio.addEventListener('pause', handlePause);
        audio.addEventListener('play', handlePlay);
        audio.addEventListener('playing', handlePlaying);
        audio.addEventListener('ended', handleEnded);

        return () => {
            audio.removeEventListener('loadstart', handleLoadStart);
            audio.removeEventListener('canplaythrough', handleCanPlayThrough);
            audio.removeEventListener('error', handleError);
            audio.removeEventListener('emptied', handleEmptied);
            audio.removeEventListener('pause', handlePause);
            audio.removeEventListener('play', handlePlay);
            audio.removeEventListener('playing', handlePlaying);
            audio.removeEventListener('ended', handleEnded);

            if (isAudioPlaying(audio)) {
                audio.pause();
            }

            setLoadState(LoadState.Idle);
            setPlayState(PlayState.Idle);
        };
    }, [audio]);

    const audioRef = useRef(audio);
    useEffect(() => {
        audioRef.current = audio;
    }, [audio]);

    const play = useCallback((track: string | HTMLAudioElement) => {
        const currentAudio = audioRef.current;

        const checkSameAudioRequested = (track: string | HTMLAudioElement) => {
            if (typeof track === 'string') {
                return currentAudio && currentAudio.currentSrc === track;
            }

            return currentAudio === track;
        };

        if (currentAudio && checkSameAudioRequested(track)) {
            const isPlaying = isAudioPlaying(currentAudio);

            if (isPlaying) {
                currentAudio.currentTime = 0;

                return Promise.resolve();
            }

            return currentAudio.play();
        }

        if (currentAudio && isAudioPlaying(currentAudio)) {
            currentAudio.pause();
        }

        const newAudio = track instanceof HTMLAudioElement ? track : createAudio(track);

        setAudio(newAudio);
        newAudio.currentTime = 0;

        return newAudio.play();
    }, []);

    const pause = useCallback(() => {
        const audio = audioRef.current;

        if (audio && isAudioPlaying(audio)) {
            audio.pause();
        }
    }, []);

    const stop = useCallback(() => {
        const audio = audioRef.current;

        if (audio && isAudioPlaying(audio)) {
            audio.pause();
        }

        setAudio(undefined);
    }, []);

    const preload = useCallback((trackUrl: string) => {
        const newAudio = new Audio();
        newAudio.src = trackUrl;
        newAudio.load();
    }, []);

    const checkIsPlaying = useCallback(
        (trackSrc: string) => {
            if (audio?.currentSrc.includes(trackSrc) && playState === PlayState.Playing) {
                return true;
            }

            return false;
        },
        [audio?.currentSrc, playState],
    );

    return {
        play,
        pause,
        stop,
        preload,
        checkIsPlaying,
        currentSrc: audio?.currentSrc,
        loadState,
        playState,
    };
};

export interface UseAudioReturn {
    play: (track: string | HTMLAudioElement) => Promise<void>;
    pause: () => void;
    stop: () => void;
    preload: (trackUrl: string) => void;
    checkIsPlaying: (trackSrc: string) => boolean;
    currentSrc?: string;
    playState: PlayState;
    loadState: LoadState;
}

export enum LoadState {
    Idle = 1,
    Loading,
    Error,
    Loaded,
}

export enum PlayState {
    Idle = 1,
    Playing,
    Paused,
    Finished,
}

const createAudio = (src: string) => {
    const newAudio = new Audio();
    newAudio.src = src;
    newAudio.load();

    return newAudio;
};

const getCurrentAudioLoadState = (audio: HTMLAudioElement) => {
    if (audio.error) {
        return LoadState.Error;
    }

    if (audio.networkState === HTMLMediaElement.NETWORK_LOADING) {
        return LoadState.Loading;
    }

    if (audio.readyState === HTMLMediaElement.HAVE_ENOUGH_DATA) {
        return LoadState.Loaded;
    }

    return LoadState.Idle;
};

const getCurrentAudioPlayState = (audio: HTMLAudioElement) => {
    if (audio.paused) {
        if (audio.currentTime === 0) {
            return PlayState.Idle;
        } else {
            return PlayState.Paused;
        }
    }

    if (audio.ended) {
        return PlayState.Finished;
    }

    return PlayState.Playing;
};

const isAudioPlaying = (audio: HTMLAudioElement) => {
    return audio.currentTime && !audio.paused;
};
