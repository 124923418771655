import { Box } from '@lingoda/ui';
import { styled } from '@lingoda/ui/theme';

const BaseBanner = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    borderRadius: theme.shape.borderRadiusPaper,
    padding: theme.spacing(3),
    backgroundRepeat: 'no-repeat',
    backgroundOrigin: 'border-box',
    overflow: 'hidden',
    position: 'relative',
}));

export default BaseBanner;
