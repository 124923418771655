import { lazy } from 'react';
import {
    RouteAccess,
    type RouteConfigExtended,
    TabValue,
    packagePurchasePath,
    sprintPackagePurchasePath,
    studentTeamsPackagePurchasePath,
} from '@lingoda/router';

const PackagePurchasePage = lazy(() => import('./pages/PackagePurchasePage'));

export const packagePurchaseRoutes: RouteConfigExtended[] = [
    {
        exact: true,
        component: PackagePurchasePage,
        path: packagePurchasePath.raw,
        config: {
            hideFooter: true,
            access: RouteAccess.Public,
            hideNavigation: true,
            tab: TabValue.Store,
        },
        label: 'Purchase',
    },
    {
        exact: true,
        component: PackagePurchasePage,
        path: studentTeamsPackagePurchasePath.raw,
        config: {
            hideFooter: true,
            access: RouteAccess.Public,
            hideNavigation: true,
            tab: TabValue.Store,
        },
        label: 'TeamsPurchase',
    },
    {
        exact: true,
        component: PackagePurchasePage,
        path: sprintPackagePurchasePath.raw,
        config: {
            hideFooter: true,
            access: RouteAccess.Public,
            hideNavigation: true,
            tab: TabValue.Store,
        },
        label: 'SprintPurchase',
    },
];
