import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from '@lingoda/hooks';
import { Translate, trans } from '@lingoda/i18n';
import { updatePreferences } from '@lingoda/students';
import { Alert, type AlertProps, Typography } from '@lingoda/ui';
import { LingobitesIcon } from '@lingoda/quiz-ui';
import { useMarkWidgetAsDisplayed } from '../../hooks/useMarkWidgetAsDisplayed';
import { AnnouncementId } from '../../model/announcement';
import { mapAnnouncementIdToWidgetClass } from '../../helpers';
import { useAnnouncementBanner } from '../../hooks';
import Icon from './Icon.svg?component';

export interface Props {
    id: AnnouncementId;
    sx?: AlertProps['sx'];
}

export const AnnouncementBanner = ({ id, sx }: Props) => {
    const dispatch = useDispatch();
    const isVisible = useAnnouncementBanner(id);

    const { markAsDisplayed } = useMarkWidgetAsDisplayed();

    const handleClose = useCallback(() => {
        dispatch(updatePreferences({ dismissedWidgets: { [id]: true } }));
    }, [id, dispatch]);

    const description = useMemo(() => {
        return getDescription(id);
    }, [id]);

    useEffect(() => {
        const widgetClass = mapAnnouncementIdToWidgetClass(id);
        if (isVisible && widgetClass) {
            markAsDisplayed(widgetClass);
        }
    }, [isVisible, id, markAsDisplayed]);

    if (!isVisible) {
        return null;
    }

    const icon = getIcon(id);

    return (
        <Alert
            icon={icon}
            onClose={handleClose}
            severity="promotion"
            title={titles[id]}
            variant="outlined"
            sx={{ ...alertStyles, ...sx }}
        >
            {description}
        </Alert>
    );
};

const titles: Record<AnnouncementId, string> = {
    [AnnouncementId.lingobitesBanner]: trans('lingobites-announcement-title', {}, 'student-home'),
    [AnnouncementId.practiceMaterialsUpdateBanner]: trans(
        'practice-update-announcement.title',
        {},
        'student-common+intl-icu',
    ),
};

const getDescription = (id: AnnouncementId) => {
    switch (id) {
        case AnnouncementId.lingobitesBanner:
            return (
                <Typography>
                    <Translate
                        domain="student-home"
                        id="lingobites-announcement-description"
                        params={{
                            link: trans('lingobites-announcement-link-text', {}, 'student-home'),
                        }}
                    />
                </Typography>
            );
        case AnnouncementId.practiceMaterialsUpdateBanner:
            return trans('practice-update-announcement.description', {}, 'student-common+intl-icu');
        default:
            return null;
    }
};

const getIcon = (id: AnnouncementId) => {
    switch (id) {
        case AnnouncementId.lingobitesBanner: {
            return <LingobitesIcon width={48} height={48} score={0} disabled={false} />;
        }
        case AnnouncementId.practiceMaterialsUpdateBanner: {
            return <LingobitesIcon width={48} height={48} score={0} disabled={false} />;
        }
        default:
            return <Icon height={24} width={24} />;
    }
};

const alertStyles = {
    ['& .MuiAlert-icon']: {
        flexShrink: 0,
    },
} satisfies AlertProps['sx'];
