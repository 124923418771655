import { createSelector } from 'reselect';
import type { Date } from '@lingoda/dates';
import { createRecordFactory } from '@lingoda/utils';
import { PurchaseType } from '@lingoda/graphql';
import { SubscriptionFactory, SubscriptionStatus } from './models';
import { subscriptionsListSelector, subscriptionsStateSelector } from './selectors';
import type { Subscription } from './models';

export interface ExpandedSubscription extends Subscription {
    readonly stateDate: Date;
}

const ExpandedSubscriptionFactory = createRecordFactory<ExpandedSubscription>({
    ...SubscriptionFactory({}),
    stateDate: undefined,
});

const getStateDate = (subscription: Subscription) => {
    switch (subscription?.status) {
        case SubscriptionStatus.Active:
            return subscription.nextChargeAt;
        case SubscriptionStatus.Inactive:
            return subscription.nextChargeAt;
        case SubscriptionStatus.Paused:
            return subscription.resumesAt;
        case SubscriptionStatus.Cancelled:
            return subscription.expiresAt;
    }
};

export const expandedSubscriptionsListSelector = createSelector(
    subscriptionsListSelector,
    (subscriptions) =>
        subscriptions.map((subscription) =>
            ExpandedSubscriptionFactory({
                ...subscription,
                stateDate: getStateDate(subscription),
            }),
        ),
);

const currentStatuses = [
    SubscriptionStatus.Active,
    SubscriptionStatus.Cancelled,
    SubscriptionStatus.Paused,
];

export const currentSubscriptionSelector = createSelector(
    expandedSubscriptionsListSelector,
    (subscriptions) =>
        subscriptions.find(
            (item) => !!item.id && item.status && currentStatuses.includes(item.status),
        ),
);

export const lastInactiveSubscriptionSelector = createSelector(
    expandedSubscriptionsListSelector,
    (subscriptions) => {
        return [...subscriptions]
            .reverse()
            .find((item) => !!item.id && item.status === SubscriptionStatus.Inactive);
    },
);

export const futureSubscriptionSelector = createSelector(
    expandedSubscriptionsListSelector,
    (subscriptions) => subscriptions.find((item) => item.status === SubscriptionStatus.Next),
);

export const hasRecurringActiveOrFutureSubscriptionSelector = createSelector(
    currentSubscriptionSelector,
    futureSubscriptionSelector,
    (subscription, futureSubscription) => {
        if (futureSubscription) {
            return true;
        }

        return !!subscription?.isRecurring && subscription.status !== SubscriptionStatus.Cancelled;
    },
);

export const canRolloverCurrentSubscriptionSelector = createSelector(
    currentSubscriptionSelector,
    futureSubscriptionSelector,
    (currentSubscription, futureSubscription) =>
        currentSubscription?.isInTrial &&
        futureSubscription &&
        currentSubscription.purchaseType !== PurchaseType.Sprint,
);

export const isSprintSubscriptionSelector = createSelector(
    currentSubscriptionSelector,
    (subscription) => subscription?.purchaseType === PurchaseType.Sprint,
);

export const isSubscriptionWillExpireSelector = createSelector(
    currentSubscriptionSelector,
    futureSubscriptionSelector,
    (current, future) =>
        current && !current.isExpired && current.status === SubscriptionStatus.Cancelled && !future,
);

export const expandedSubscriptionByIdSelectorFactory = (id: number) =>
    createSelector(subscriptionsStateSelector, (subscriptionsState) => {
        const subscription = subscriptionsState.items[id];

        if (!subscription) {
            return;
        }

        return ExpandedSubscriptionFactory({
            ...subscription,
            stateDate: getStateDate(subscription),
        });
    });
