import { type MouseEvent, type SyntheticEvent, useMemo } from 'react';
import { Location } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Chip,
    Grid,
    Stack,
    useMediaQuery,
} from '@lingoda/ui';
import ExpandMore from '@lingoda/ui/icons/ExpandMore';
import { colorsPalette, styled } from '@lingoda/ui/theme';
import {
    ContentBlockTypography,
    findAllContentBlocks,
    findContentBlock,
} from '@lingoda/content-blocks';
import { type PackageActionProps, type PackageItemProps } from '../types';
import { CheckoutButton, CheckoutSkipTrialButton, CheckoutTrialButton } from './CheckoutButtons';
import { TrialDetails } from './TrialDetails';
import DotDivider from './DotDivider';
import { DetailsContentBlockTypography } from './DetailsContentBlockTypography';
import { TopBadge } from './TopBadge';

const AccordionWrapper = styled(Accordion, {
    shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected: boolean }>(({ isSelected }) => ({
    position: 'relative',
    border: '1px solid',
    borderColor: isSelected ? colorsPalette.primarySolid[100] : colorsPalette.blackAlpha[20],
    color: colorsPalette.blackAlpha[70],
    '&.Mui-disabled': {
        backgroundColor: colorsPalette.blackAlpha[4],
        borderColor: colorsPalette.blackAlpha[8],
    },
}));

const AccordionSummaryStyled = styled(AccordionSummary)(({ theme }) => ({
    padding: theme.spacing(3),
    '&.Mui-disabled': {
        opacity: 1,
    },
    '.MuiAccordionSummary-content': {
        margin: 0,
    },
    '.MuiAccordionSummary-expandIconWrapper': {
        alignSelf: 'flex-start',
    },
}));

export const TabletPackageItem = ({
    pkg,
    isSelected,
    onPackageSelect,
    onCheckout,
    isTrialAvailable,
}: PackageItemProps & PackageActionProps) => {
    const isDisabled = pkg.isCurrent;
    const isWideTablet = useMediaQuery('(min-width:800px)');

    const contentBlocks = useMemo(
        () => ({
            title: findContentBlock(pkg.contentBlocks, Location.PackageTitle),
            paymentDetails: findContentBlock(pkg.contentBlocks, Location.PackagePaymentDetails),
            details: findAllContentBlocks(pkg.contentBlocks, [
                Location.PackageDetail,
                Location.PackageDetailOld,
            ]),
            summary: findAllContentBlocks(pkg.contentBlocks, Location.SummaryContent),
            trialDetails: findContentBlock(pkg.contentBlocks, Location.SummaryTrialDetails),
            topBadge: findContentBlock(pkg.contentBlocks, Location.TopBadge),
        }),
        [pkg.contentBlocks],
    );

    const handleClickProceed = (e: MouseEvent, isTrial = false) => {
        onPackageSelect(pkg.id);
        onCheckout({ trial: isTrial });
        e.stopPropagation();
    };

    const handleOnSelect = (_: SyntheticEvent<unknown>, expanded: boolean) => {
        if (expanded) {
            onPackageSelect(pkg.id);
        } else {
            onPackageSelect(null);
        }
    };

    return (
        <Box>
            <AccordionWrapper
                expanded={isSelected}
                isSelected={isSelected && !isDisabled}
                disabled={isDisabled}
                onChange={isDisabled ? undefined : handleOnSelect}
                disableGutters
            >
                <AccordionSummaryStyled expandIcon={!isDisabled && <ExpandMore />}>
                    {contentBlocks.topBadge && (
                        <TopBadge>
                            <ContentBlockTypography
                                {...contentBlocks.topBadge}
                                variant="subtitle2"
                            />
                        </TopBadge>
                    )}
                    <Stack
                        direction="row"
                        spacing={2}
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <Stack spacing={1}>
                            {contentBlocks.title && (
                                <ContentBlockTypography
                                    {...contentBlocks.title}
                                    variant="h3"
                                    textColor={
                                        isDisabled ? 'inherit' : colorsPalette.blackSolid[100]
                                    }
                                />
                            )}

                            {(contentBlocks.details || contentBlocks.paymentDetails) && (
                                <Stack spacing={1} direction="row" divider={<DotDivider />}>
                                    {contentBlocks.paymentDetails && (
                                        <DetailsContentBlockTypography
                                            {...contentBlocks.paymentDetails}
                                            variant="body1"
                                            textColor={
                                                isDisabled
                                                    ? 'inherit'
                                                    : colorsPalette.blackSolid[100]
                                            }
                                            strongH1={false}
                                        />
                                    )}

                                    {contentBlocks.details && (
                                        <Stack spacing={1} direction="row">
                                            {contentBlocks.details.map((item, index) => (
                                                <DetailsContentBlockTypography
                                                    key={index}
                                                    {...item}
                                                    variant="body1"
                                                    textColor={
                                                        isDisabled
                                                            ? 'inherit'
                                                            : colorsPalette.blackSolid[100]
                                                    }
                                                    strongH1={false}
                                                />
                                            ))}
                                        </Stack>
                                    )}
                                </Stack>
                            )}
                        </Stack>

                        {pkg.isCurrent && (
                            <Box>
                                <Chip
                                    color="info"
                                    label={trans('current-plan-tag', {}, 'student-store')}
                                />
                            </Box>
                        )}
                    </Stack>
                </AccordionSummaryStyled>
                <AccordionDetails sx={{ px: 3, pb: 3 }}>
                    <Stack spacing={5}>
                        {contentBlocks.summary && (
                            <Grid container columnSpacing={2} rowSpacing={3}>
                                {contentBlocks.summary.map((item, index) => (
                                    <Grid key={index} item xs={6}>
                                        <ContentBlockTypography
                                            {...item}
                                            variant="body1"
                                            textColor={
                                                isDisabled
                                                    ? 'inherit'
                                                    : colorsPalette.blackSolid[100]
                                            }
                                            iconColor={
                                                isDisabled
                                                    ? 'inherit'
                                                    : colorsPalette.primarySolid[100]
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}

                        <Box textAlign="right">
                            {isTrialAvailable ? (
                                <Stack spacing={2}>
                                    <Stack
                                        direction={isWideTablet ? 'row-reverse' : 'column'}
                                        spacing={2}
                                    >
                                        <CheckoutTrialButton
                                            onClick={(e) => handleClickProceed(e, true)}
                                            sx={{ minWidth: '16rem' }}
                                        />
                                        <CheckoutSkipTrialButton
                                            onClick={handleClickProceed}
                                            sx={{ minWidth: '16rem' }}
                                        />
                                    </Stack>

                                    {contentBlocks.trialDetails && (
                                        <Box
                                            textAlign="right"
                                            color={colorsPalette.blackSolid[100]}
                                        >
                                            <TrialDetails>
                                                {contentBlocks.trialDetails.text}
                                            </TrialDetails>
                                        </Box>
                                    )}
                                </Stack>
                            ) : (
                                <CheckoutButton onClick={handleClickProceed} />
                            )}
                        </Box>
                    </Stack>
                </AccordionDetails>
            </AccordionWrapper>
        </Box>
    );
};
