import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

const WarningIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 8.25003C12.4142 8.25003 12.75 8.58582 12.75 9.00003V14C12.75 14.4142 12.4142 14.75 12 14.75C11.5858 14.75 11.25 14.4142 11.25 14V9.00003C11.25 8.58582 11.5858 8.25003 12 8.25003Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4444 16.1686C11.6089 16.0587 11.8022 16 12 16C12.2652 16 12.5196 16.1054 12.7071 16.2929C12.8946 16.4805 13 16.7348 13 17C13 17.1978 12.9414 17.3912 12.8315 17.5556C12.7216 17.7201 12.5654 17.8482 12.3827 17.9239C12.2 17.9996 11.9989 18.0194 11.8049 17.9808C11.6109 17.9422 11.4328 17.847 11.2929 17.7071C11.153 17.5673 11.0578 17.3891 11.0192 17.1951C10.9806 17.0011 11.0004 16.8001 11.0761 16.6174C11.1518 16.4346 11.28 16.2784 11.4444 16.1686Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.4666 2.23172C11.1315 1.02286 12.8685 1.02286 13.5334 2.23171L22.8421 19.1567C23.4836 20.323 22.6398 21.75 21.3087 21.75H2.69128C1.36022 21.75 0.516435 20.323 1.1579 19.1567L10.4666 2.23172ZM12.2191 2.95459C12.1241 2.7819 11.8759 2.7819 11.781 2.95459L2.47222 19.8796C2.38058 20.0462 2.50112 20.25 2.69128 20.25H21.3087C21.4989 20.25 21.6194 20.0462 21.5278 19.8796L12.2191 2.95459Z"
            fill="currentColor"
        />
    </SvgIcon>
);

export default WarningIcon;
