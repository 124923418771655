import {
    type SectionCurriculum,
    type SectionCurriculumSlug,
    getTranslatedSection,
} from '@lingoda/sections';
import { ToggleButton, ToggleButtonGroup, Typography } from '@lingoda/ui';
import { FilterGroup } from './FilterGroup';
import type { ToggleButtonGroupProps } from '@mui/material';
import type { FilterProps } from '../../types';

interface CurriculumFilterProps extends FilterProps, ToggleButtonGroupProps {
    options: SectionCurriculum[];
    selectedSlug?: SectionCurriculumSlug;
}

export const CurriculumFilter = ({
    options,
    fullWidth,
    selectedSlug,
    onFilterUpdate,
}: CurriculumFilterProps) => {
    if (options.length === 0) {
        return null;
    }

    const handleChange: ToggleButtonGroupProps['onChange'] = (_, value: SectionCurriculumSlug) =>
        onFilterUpdate('curriculumSlug', value);

    return (
        <FilterGroup id="curriculum-filter">
            <ToggleButtonGroup
                value={selectedSlug}
                onChange={handleChange}
                exclusive
                fullWidth={fullWidth}
            >
                {options.map((sectionCurriculum: SectionCurriculum) => (
                    <ToggleButton key={sectionCurriculum.slug} value={sectionCurriculum.slug}>
                        <Typography noWrap textTransform="capitalize">
                            {getTranslatedSection(sectionCurriculum.slug)}
                        </Typography>
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </FilterGroup>
    );
};
