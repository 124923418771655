import { trans } from '@lingoda/i18n';
import type { Currency } from '@lingoda/graphql';
import { useConfigurationQuery } from '@lingoda/graphql';
import { Select } from '@lingoda/ui';
import type { SelectProps } from '@lingoda/ui';
import { FilterGroup } from './FilterGroup';
import type { FilterProps } from '../../types';

interface CurrencyFilterProps extends FilterProps {
    currency: Currency;
}

export const CurrencyFilter = ({ currency, onFilterUpdate }: CurrencyFilterProps) => {
    const handleChange: SelectProps['onChange'] = (event) =>
        onFilterUpdate('currency', event.target.value as Currency);

    const { data, error, loading } = useConfigurationQuery();

    if (loading || error || !data) {
        return null;
    }

    return (
        <FilterGroup id="currency-filter" label={trans('currency', {}, 'student-store')}>
            <Select
                value={currency}
                onChange={handleChange}
                options={data.config.currencies}
                getOptionValue={(currency) => currency}
                getOptionLabel={(currency) => currency}
            />
        </FilterGroup>
    );
};
