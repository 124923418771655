import { useUserSubscriptionsQuery } from '@lingoda/graphql';
import { isSubscriptionFFreeTrial } from '../utils/isSubscriptionFFreeTrial';

export const useIsFFreeTrialOrWasInTrial = () => {
    const userSubscriptions = useUserSubscriptionsQuery();
    const currentSubscription = userSubscriptions.data?.subscriptions?.currentSubscription;
    const latestSubscription = userSubscriptions.data?.subscriptions?.latestSubscription;

    return (
        isSubscriptionFFreeTrial(currentSubscription) ||
        (!currentSubscription && isSubscriptionFFreeTrial(latestSubscription))
    );
};
