import { type KeyboardEventHandler, type MouseEventHandler, useRef } from 'react';
import { Box, Typography, type TypographyProps, styled } from '@lingoda/ui';
import { type PlayAudioIconAnimation } from '@lingoda/ui/icons/PlayAudioIcon';
import { useDebouncedState } from '@lingoda/hooks';
import { useAudioTrackContext } from '../context/Audio';
import { ButtonLayout, buttonHoverStyles } from './IconButtonAudio/ButtonLayout';

export interface TypographyAudioProps {
    title: string;
    audioUrl: string;
    controlPosition?: 'start' | 'end';
    variant?: TypographyProps['variant'];
    onClick?: () => void;
    sx?: TypographyProps['sx'];
}

export const TypographyAudio = ({
    variant,
    controlPosition = 'end',
    audioUrl,
    title,
    onClick,
    sx,
}: TypographyAudioProps) => {
    const { play, isLoading } = useAudioTrackContext(audioUrl);

    // Improve UX: do not show loading, when it's fast or audio brought from cache
    const isLoadingDebounced = useDebouncedState(isLoading, 800);

    const handleClick: MouseEventHandler = (event) => {
        event.stopPropagation();
        iconAnimation.current?.play();
        play().catch(console.error);
        onClick?.();
    };

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (event.code === 'Space') {
            event.stopPropagation();
        }
    };

    const iconAnimation = useRef<PlayAudioIconAnimation>(null);

    const control = (
        <ButtonAudioStyled
            controlPosition={controlPosition}
            onKeyDown={handleKeyDown}
            animationRef={iconAnimation}
            loading={isLoadingDebounced}
        />
    );

    return (
        <TypographyStyled variant={variant} onClick={isLoading ? undefined : handleClick} sx={sx}>
            {controlPosition === 'start' && control}
            <Title controlPosition={controlPosition}>{title}</Title>
            {controlPosition === 'end' && control}
        </TypographyStyled>
    );
};

const TypographyStyled = styled(Typography)(() => ({
    cursor: 'pointer',

    ':hover': {
        '.MuiIconButton-root': {
            ...buttonHoverStyles,
        },
    },
}));

const Title = styled(Box, {
    shouldForwardProp: (name) => name !== 'position',
})<{
    controlPosition: 'start' | 'end';
}>(({ theme, controlPosition }) => {
    return {
        ...(controlPosition === 'end' && { marginRight: theme.spacing(0.5) }),
    };
});
Title.defaultProps = {
    component: 'span',
};

const ButtonAudioStyled = styled(ButtonLayout, {
    shouldForwardProp: (name) => name !== 'position',
})<{
    controlPosition: 'start' | 'end';
}>(({ theme, controlPosition }) => ({
    marginTop: theme.spacing(-0.25),
    padding: theme.spacing(0.5),
    ...(controlPosition === 'start' && { marginRight: theme.spacing(0.5) }),
}));
